import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import SectionTitle from "Components/Ui/SectionTitle/SectionTitle";
import MainCardTrade from "./Components/MainCardTrade/MainCardTrade";
import { DataCard } from "./Components/Data/DataCards";
import "./MainCards.css";
const MainCards = () => {
  return (
    <div className="main-cards padding-top">
      {/* ============== START CONTAINER ============ */}
      <ContainerMedia>
        <SectionTitle title={"لماذا تختار تداول لإدارة أموالك"} />
        {/* ============= START ALL MAIN CARDS =========== */}
        <div className="all-main-cards" data-aos="fade-up">
          {/* ============ START ROW ========== */}
          <div className="row g-3">
            {DataCard.map((item) => {
              return (
                <>
                  {/* ============== START COL ========== */}
                  <div key={item.id} className="col-12 col-md-6">
                    <MainCardTrade
                      iconCard={item.icon}
                      titleCard={item.title}
                      textCard={item.text}
                    />
                  </div>
                  {/* ============== END COL ========== */}
                </>
              );
            })}
          </div>
          {/* ============ END ROW ========== */}
        </div>
        {/* ============= END ALL MAIN CARDS =========== */}
      </ContainerMedia>
      {/* ============== END CONTAINER ============ */}
    </div>
  );
};

export default MainCards;
