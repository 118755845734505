import { format } from "date-fns";
import { ar } from "date-fns/locale";
  // THIS FUNCTION FOR FORMATE TIME
 export const FormatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = format(date, "EEEE", { locale: ar });
    const time = format(date, "h:mm a", { locale: ar });
    const formattedTime = time.replace("AM", "ص").replace("PM", "م");
    return `${day}, ${formattedTime}`;
  };
