import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import EmailIcon from "Assets/images/FooterIcon/EmailIcon";
import PhoneIcon from "Assets/images/FooterIcon/PhoneIcon";
import WhatsappIcon from "Assets/images/FooterIcon/WhatsappIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faPinterestP,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";

import "./Footer.css";
import LogoInfo from "Components/LogoInfo/LogoInfo";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <ContainerMedia>
          {/* ============== START ALL FOOTER ============== */}
          <div className="all-footer">
            {/* =============== START ROW ================ */}
            <div className="row g-3">
              {/* ================= START COL ================ */}
              <div className="col-12">
                {/* ================ START FOOTER INFO ============== */}
                <div className="footer-info" data-aos="fade-up">
                  {/* ============ START LOGO FOOTER ============== */}
                  <div className="logo-footer">
                    <LogoInfo />
                  </div>
                  {/* ============ END LOGO FOOTER ============== */}
                  {/* ============ START CONTACT INFO =========== */}
                  <div className="all-contact-info">
                    <h2 className="title">يسعدنا التواصل معك</h2>

                    {/* ============ START ROW ========= */}
                    <div className="all-info-details-contact d-flex justify-content-between align-items-center flex-wrap gap-4">
                      <div className="main-content-details d-flex flex-column gap-3">
                        {/* ============= START CONTACT DETAILS =========== */}
                        <div className="contact-details d-flex align-items-center gap-3  flex-wrap">
                          {/* ============= START CONTACT ONE ============ */}
                          <a
                            target="_blank"
                            href="mailto:tadawul@gmail.com"
                            className="contact-one d-flex align-items-center gap-2"
                            rel="noreferrer"
                          >
                            <div className="icon-one">
                              <EmailIcon />
                            </div>
                           info@injaaz.net
                          </a>
                          {/* ============= END CONTACT ONE ============ */}
                          {/* ============= START CONTACT ONE ============ */}
                          <a
                            target="_blank"
                            href="tel:+966543757426"
                            className="contact-one d-flex align-items-center gap-2"
                            rel="noreferrer"
                          >
                            <div className="icon-one">
                              <PhoneIcon />
                            </div>
                            966543757426
                          </a>
                          {/* ============= END CONTACT ONE ============ */}
                          {/* ============= START CONTACT ONE ============ */}
                          <a
                            target="_blank"
                            href="https://wa.me/966543757426"
                            className="contact-one d-flex align-items-center gap-2"
                            rel="noreferrer"
                          >
                            <div className="icon-one">
                              <WhatsappIcon />
                            </div>
                            966543757426
                          </a>
                          {/* ============= END CONTACT ONE ============ */}
                        </div>
                        {/* ============= END CONTACT DETAILS =========== */}
                        <Link to="/terms" className="link-terms">
                          الشروط والاحكام
                        </Link>
                        <p className="text">Al Fattan Currency House
                        Tower 2, DIFC
                        P.O. Box 121364
                        Dubai, United Arab Emirates</p>
                      </div>

                      {/* ================ START INFO LEFT CONTACT =========== */}
                      <div className="left-info-contact d-flex justify-content-end align-items-end flex-column">
                        {/* ============= START SOCIAL ICONS ============== */}
                        <div className="social-icons">
                          <ul className="list-icons-social d-flex align-items-center gap-3 p-0 m-0">
                            <li>
                              <a
                                target="_blank"
                                href="https://www.facebook.com/"
                                className="icon-social-one"
                                rel="noopener noreferrer"
                              >
                                <FontAwesomeIcon icon={faFacebookF} />
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href="https://www.instagram.com/"
                                className="icon-social-one"
                                rel="noopener noreferrer"
                              >
                                <FontAwesomeIcon icon={faInstagram} />
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href="https://www.twitter.com/"
                                className="icon-social-one"
                                rel="noopener noreferrer"
                              >
                                <FontAwesomeIcon icon={faTwitter} />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.pinterest.com/"
                                className="icon-social-one"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FontAwesomeIcon icon={faPinterestP} />
                              </a>
                            </li>
                          </ul>
                        </div>
                        {/* ============= END SOCIAL ICONS ============== */}
                        <p className="text-footer">جميع الحقوق محفوظة@2024</p>
                      </div>
                      {/* ================ END INFO LEFT CONTACT =========== */}
                    </div>
                    {/* ============ END ROW ========= */}
                  </div>
                  {/* ============ END CONTACT INFO =========== */}
                </div>
                {/* ================ END FOOTER INFO ============== */}
              </div>
              {/* ================= END COL ================ */}
            </div>
            {/* =============== END ROW ================ */}
          </div>
          {/* ============== END ALL FOOTER ============== */}
        </ContainerMedia>
      </div>
    </footer>
  );
};

export default Footer;
