
const EyeIcon = () => {
  return (
    <>
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.6849 9.59516C11.6849 11.0802 10.4849 12.2802 8.99994 12.2802C7.51494 12.2802 6.31494 11.0802 6.31494 9.59516C6.31494 8.11016 7.51494 6.91016 8.99994 6.91016C10.4849 6.91016 11.6849 8.11016 11.6849 9.59516Z"
          stroke="#121212"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.99988 15.7953C11.6474 15.7953 14.1149 14.2353 15.8324 11.5353C16.5074 10.4778 16.5074 8.70031 15.8324 7.64281C14.1149 4.94281 11.6474 3.38281 8.99988 3.38281C6.35238 3.38281 3.88488 4.94281 2.16738 7.64281C1.49238 8.70031 1.49238 10.4778 2.16738 11.5353C3.88488 14.2353 6.35238 15.7953 8.99988 15.7953Z"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default EyeIcon;
