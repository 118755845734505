import "./SectionTitle.css";
const SectionTitle = ({ title }) => {
  return (
    <div className="section-title" data-aos="fade-down">
      <h2 className="title">{title}</h2>
    </div>
  );
};

export default SectionTitle;
