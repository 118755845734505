import { useEffect } from "react";
import "./ButtonWhatsAppFixed.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const ButtonWhatsAppFixed = () => {
  useEffect(() => {
    const handleScroll = () => {
      const button = document.querySelector('.button-whatsapp-scroll');
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        button.classList.add('scrolled-to-bottom');
      } else {
        button.classList.remove('scrolled-to-bottom');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="button-whatsapp-scroll">
      <a
        target="_blank"
        href="https://wa.me/966543757426"
        className="btn-main"
        rel="noreferrer"
      >
        <span className="text-whats">تواصل واتساب</span> <div className="icon-whats"><FontAwesomeIcon icon={faWhatsapp}/></div>
      </a>
    </div> 
  );
};

export default ButtonWhatsAppFixed;
