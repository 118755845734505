import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import CardBoxBlog from "Components/Ui/CardBoxBlog/CardBoxBlog";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SectionTitle from "Components/Ui/SectionTitle/SectionTitle";
import "./BlogsCards.css";
import useFetchBlogs from "Pages/Blogs/Components/GetCardsBlogs/GetCardsBlogs";
import UseImageLoading from "Components/Ui/UseImageLoading/UseImageLoading";
import ErrorFetch from "Components/Ui/LoaderErrorFetch/ErrorFetch/ErrorFetch";
import LoaderFetch from "Components/Ui/LoaderErrorFetch/LoaderFetch/LoaderFetch";
import { formatDistanceToNow } from "date-fns";
import { ar } from "date-fns/locale";
import NotFoundDataPage from "Components/Ui/NotFoundDataPage/NotFoundDataPage";
const BlogsCards = () => {
  // NAVIGATE TO THE PAGE DETAILS USING ID OF CARD
  const navigate = useNavigate();
  const location = useLocation();
  const handleCardClick = (id) => {
    navigate(`/detailsPageBlog/${id}`);
  };

  // FETCHH DATA NEWS CARDS
  const { data, error, isLoading } = useFetchBlogs("allBlogsCards");

  // LOADING WHEN GET IMAGE
  const { handleImageLoad } = UseImageLoading(data, isLoading);

  // ERROR
  if (error) return <ErrorFetch />;
  // LOADING
  if (isLoading) return <LoaderFetch />;

  return (
    <div className="boxs-cards-content padding-top">
      {/* ============== START CONTAINER ================= */}
      <ContainerMedia>
        <SectionTitle title={"أحدث المدونات"} />
        {data?.data.data.length > 0 ? (
          <>
            {" "}
            {/* ============== START ALL CARDS BLOG ============ */}
            <div className="all-cards-boxs">
              {/* ============== START ROW ============== */}
              <div className="row g-3">
                {data?.data.data.slice(0, 1).map((item) => (
                  <div
                    onClick={() => handleCardClick(item.id)}
                    key={item.id}
                    className="col-12 col-md-6"
                    data-aos="fade-left"
                  >
                    <CardBoxBlog
                      image={item.image}
                      onImageLoad={() => handleImageLoad(item.id)}
                      badgeText={item.category.title}
                      title={item.title}
                      imagePerson={item.admin.image}
                      nameUser={item.admin.name}
                      timeAdd={formatDistanceToNow(
                        new Date(item.admin.created_at),
                        {
                          addSuffix: true,
                          locale: ar
                        }
                      )}
                      isTrueTextContent={true}
                      textContent={item.short_content}
                    />
                  </div>
                ))}

                <div className="col-12 col-md-6">
                  <div className="row g-3">
                    {data?.data.data.slice(1, 3).map((item) => (
                      <div
                        onClick={() => handleCardClick(item.id)}
                        key={item.id}
                        className="col-12"
                        data-aos="fade-right"
                      >
                        <CardBoxBlog
                          image={item.image}
                          onImageLoad={() => handleImageLoad(item.id)}
                          badgeText={item.category.title}
                          title={item.title.split(" ").slice(0, 20).join(" ")}
                          imagePerson={item.admin.image}
                          nameUser={item.admin.name}
                          timeAdd={formatDistanceToNow(
                            new Date(item.admin.created_at),
                            {
                              addSuffix: true,
                              locale: ar
                            }
                          )}
                          isTrueTextContent={false}
                          textContent={false}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* ============== END ROW ============== */}
              <Link
                to="/blogsPage"
                className="btn-main btn-main-full btn-transparent mt-4 m-auto"
              >
                الجميع
              </Link>
            </div>
            {/* ============== END ALL CARDS BLOG ============ */}
          </>
        ) : location.pathname === "/" ? (
          <NotFoundDataPage />
        ) : (
          ""
        )}
      </ContainerMedia>
      {/* ============== END CONTAINER ================= */}
    </div>
  );
};

export default BlogsCards;
