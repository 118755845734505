import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import { UserAuthContext } from "Context/UserAuthProvider/UserAuthProvider";
import axios from "axios";
import BASE_URL from "config";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

const LoginForm = ({
  buttonLogin,
  hideModalForm,
  buttonShowForgotPassword
}) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("بريد إلكتروني غير صالح")
      .required("البريد الإلكتروني مطلوب"),
    password: Yup.string()
      .min(8, "كلمة المرور قصيرة جداً")
      .max(30, "كلمة المرور طويلة جداً")
      .required("كلمة المرور مطلوبة")
  });

  const initialValues = {
    email: "",
    password: ""
  };

  let { setUserIsLoginToken } = useContext(UserAuthContext);

  const navigate = useNavigate();
  const [loaderSubmit, setLoaderSubmit] = useState(false);
  const [disabledValue, setDisabledValue] = useState(false);
  const removeDisabled = () => {
    setDisabledValue(false);
  };

  const allSubmitButtonFalse = () => {
    setLoaderSubmit(false);
    removeDisabled();
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoaderSubmit(true);
      setDisabledValue(true);
      let res = await axios.post(`${BASE_URL}/login`, values);

      if (res.data && res.data.access_token) {
        toast.success("تم التسجيل بنجاح.");
        navigate("/");
        localStorage.setItem("token", res.data.access_token);
        hideModalForm();
        setUserIsLoginToken(res.data.access_token);
        resetForm();
        allSubmitButtonFalse();
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
        allSubmitButtonFalse();
      } else if (error.request) {
        toast.error("حدث خطأ أثناء تسجيل الدخول");
        allSubmitButtonFalse();
      } else {
        toast.error("حدث خطأ أثناء تسجيل الدخول");
        allSubmitButtonFalse();
      }
    }
  };

  // SHOW PASSWORD INPUT
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <>
      {/* ============== START ALL FORM AUTH ================== */}
      <div className="all-form-auth login-form-auth">
        {/* ========== START FORM FIELD ========= */}
        <FormField
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <InputFiled
            label="البريد الإلكتروني"
            name="email"
            type="email"
            placeholder="البريد الإلكتروني"
            success
          />

          <div className="input-pass position-relative">
            <InputFiled
              label="كلمة المرور"
              name="password"
              placeholder="كلمة المرور"
              success
              value={password}
              onChange={handlePasswordChange}
              type={showPassword ? "text" : "password"}
            />

            <button
              className="icon-eye-button"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </button>
          </div>

          <div
            className="link-password-forgot"
            onClick={buttonShowForgotPassword}
          >
            نسيت كلمة السر ؟
          </div>

          <button
            disabled={disabledValue ? "disabled" : ""}
            type="submit"
            className={`btn-main btn-height btn-submit w-100 mt-3 ${
              disabledValue ? "bttDisabled" : ""
            }`}
          >
            {loaderSubmit ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "تسجيل دخول"
            )}
          </button>
        </FormField>
        {/* ========== END FORM FIELD ========= */}
        <div className="bottom-info-not-accout gap-2 d-flex justify-content-center   align-items-center ">
          ليس لديك حساب ؟{" "}
          <div
            onClick={buttonLogin}
            className="cursor-pointer-event text-decoration-underline link-a"
          >
            مستخدم جديد
          </div>
        </div>
      </div>
      {/* ============== END ALL FORM AUTH ================== */}
    </>
  );
};

export default LoginForm;
