import "./InfoDetailsPerson.css"

const InfoDetailsPerson = ({imagePerson,nameUser,timeAdd}) => {
  return (
    <>
            {/* ============= START INFO DETAILS PERSON ======== */}
            <div className="info-details-person d-flex align-items-center gap-2">
            <div className="image-person">
              <img src={imagePerson}  alt="img" />
            </div>
            <div className="info-user">
              <h2 className="name-user">{nameUser}</h2>
              <p className="time-add">{timeAdd}</p>
            </div>
          </div>
          {/* ============= END INFO DETAILS PERSON ======== */}
    </>
  )
}

export default InfoDetailsPerson