import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import BASE_URL from "../../../../config";
const useFetchBlogs = (queryKey) => {
  const getAllBlogs = () => axios.get(`${BASE_URL}/allblogs`);
  return useQuery({
    queryKey,
    queryFn: getAllBlogs
  });
};

export default useFetchBlogs;
