import CustomModal from "Components/CustomModal/CustomModal";
import imageApp from "../../../Assets/images/success/02.gif";
import AppsInfo from "../AppsInfo/AppsInfo";

const ModalDownloadApp = ({ showModalDownloadApp, hideModalDownloadApp }) => {
  return (
    <CustomModal
      show={showModalDownloadApp}
      onHide={hideModalDownloadApp}
      title={"حمل التطبيق"}
      newClass={"modal-download"}
    >
      {/* ================== START ALL DOWNLOAD APP ============== */}
      <div className="all-download-app d-flex flex-column w-100 justify-content-center align-items-center gap-3 text-center">
        {/* ================ START GIF IMAGE ============= */}
        <div className="gif-image">
          <img
            src={imageApp}
            alt="imageApp"
            className="object-fit-cover"
            width={"193px"}
            height={"193px"}
          />
        </div>
        {/* ================ END GIF IMAGE ============= */}
        <AppsInfo />
      </div>
      {/* ================== END ALL DOWNLOAD APP ============== */}
    </CustomModal>
  );
};

export default ModalDownloadApp;
