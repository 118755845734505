import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import BreadcrumbsPage from "Components/Ui/BreadcrumbsPage/BreadcrumbsPage";
import HeaderNewsCards from "./Components/HeaderNewsCards";
import AllCardsNews from "./Components/AllCardsNews";

const NewsPage = () => {
  return (
    <>
      <HelmetInfo titlePage={"الأخبار"} />
      <header>
        <BreadcrumbsPage
          newClassBread={"breadcrumb-page-route"}
          routeTitleTwoBread={"/newsPage"}
          titleTwoBread={"الأخبار"}
          isTruePageRoute={false}
          textBreadActive={false}
        />
      </header>
      <main>
        <HeaderNewsCards />
        <AllCardsNews />
      </main>
    </>
  );
};

export default NewsPage;
