import { Dropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import UserIcon from "Assets/Icons/UserIcon";
import LogOutIcon from "Assets/Icons/LogOutIcon";
import "./UserDropMenu.css";
import LogOutFunction from "./LogOutFunction";
import { useUserImage } from "Context/UserDetailsProvider/UserImageContextProvider";
import imageUserNot from "../../../Assets/images/user/03.webp";
import { useContext, useEffect } from "react";
import { UserAuthContext } from "Context/UserAuthProvider/UserAuthProvider";
import axios from "axios";
import BASE_URL from "config";
const UserDropMenu = () => {
  // LOGOUT FUNCTION
  const logOut = LogOutFunction();

  // IMAGE USER
  const { userImage, setUserImage } = useUserImage();
  let { userIsLoginToken, setUserIsLoginToken } = useContext(UserAuthContext);

  useEffect(() => {
    const fetchUserImage = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/profile`, {
          headers: {
            Authorization: `Bearer ${userIsLoginToken}`
          }
        });
        setUserImage(response.data.data.image);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setUserIsLoginToken(null);
          localStorage.removeItem("token");
        }
        throw error;
      }
    };

    // FETCH IMAGE USER WHEN WINDOW OPEN
    fetchUserImage();
  }, []);

  return (
    <>
      <div className="dropmenu-user">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic-loading" className="drop-user">
            <div className="image-user-login d-flex align-items-center justify-content-center">
              <img
                src={userImage || imageUserNot}
                alt="User"
                width={45}
                height={45}
              />
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>
              <NavLink to="/userAccount" className="link-drop-item">
                {" "}
                <UserIcon /> معلومات الحساب
              </NavLink>
            </Dropdown.Item>

            <Dropdown.Item>
              <div onClick={logOut} className="link-drop-item">
                {" "}
                <LogOutIcon /> تسجيل الخروج
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default UserDropMenu;
