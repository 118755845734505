import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import BreadcrumbsPage from "Components/Ui/BreadcrumbsPage/BreadcrumbsPage";
import HeaderBlogsPage from "./Components/HeaderBlogsPage";
import "./BlogsPage.css";
import AllCardsBlogs from "./Components/AllCardsBlogs";
const BlogsPage = () => {
  return (
    <>
      <HelmetInfo titlePage={"المدونات"} />
      <header>
        <BreadcrumbsPage
          newClassBread={"breadcrumb-page-route"}
          routeTitleTwoBread={"/blogsPage"}
          titleTwoBread={"المدونات"}
          isTruePageRoute={false}
          textBreadActive={false}
        />
      </header>
      <main>
        <HeaderBlogsPage />
        <AllCardsBlogs />
      </main>
    </>
  );
};

export default BlogsPage;
