import React from 'react'

const EmailIcon = () => {
  return (
    <><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.00024 13.6523C3.24024 13.6523 1.00024 15.8823 1.00024 18.6523C1.00024 21.4223 3.24024 23.6523 6.00024 23.6523C8.76024 23.6523 11.0002 21.4123 11.0002 18.6523C11.0002 15.8923 8.77024 13.6523 6.00024 13.6523ZM3.96024 16.6123H6.00024C6.38024 16.6123 6.68024 16.9223 6.68024 17.2923C6.68024 17.6623 6.38024 17.9723 6.00024 17.9723H3.96024C3.58024 17.9723 3.28024 17.6623 3.28024 17.2923C3.28024 16.9223 3.58024 16.6123 3.96024 16.6123ZM8.04024 20.6923H3.96024C3.58024 20.6923 3.28024 20.3823 3.28024 20.0123C3.28024 19.6423 3.59024 19.3323 3.96024 19.3323H8.05024C8.43024 19.3323 8.73024 19.6423 8.73024 20.0123C8.73024 20.3823 8.42024 20.6923 8.04024 20.6923Z" fill="#42CE8F"/>
    <path d="M17.0002 3.65234H7.00024C4.00024 3.65234 2.00024 5.15234 2.00024 8.65234V11.7923C2.00024 12.5223 2.75024 12.9823 3.42024 12.6923C4.52024 12.2123 5.77024 12.0323 7.08024 12.2423C9.70024 12.6723 11.8402 14.7423 12.3702 17.3423C12.5202 18.1023 12.5402 18.8423 12.4402 19.5523C12.3602 20.1423 12.8402 20.6623 13.4302 20.6623H17.0002C20.0002 20.6623 22.0002 19.1623 22.0002 15.6623V8.65234C22.0002 5.15234 20.0002 3.65234 17.0002 3.65234ZM17.4702 9.24234L14.3402 11.7423C13.6802 12.2723 12.8402 12.5323 12.0002 12.5323C11.1602 12.5323 10.3102 12.2723 9.66024 11.7423L6.53024 9.24234C6.21024 8.98234 6.16024 8.50234 6.41024 8.18234C6.67024 7.86234 7.14024 7.80234 7.46024 8.06234L10.5902 10.5623C11.3502 11.1723 12.6402 11.1723 13.4002 10.5623L16.5302 8.06234C16.8502 7.80234 17.3302 7.85234 17.5802 8.18234C17.8402 8.50234 17.7902 8.98234 17.4702 9.24234Z" fill="#42CE8F"/>
    </svg>
    </>
  )
}

export default EmailIcon