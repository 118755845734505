import React from "react";

const SunIcon = () => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 25.3334C21.1546 25.3334 25.3333 21.1547 25.3333 16C25.3333 10.8454 21.1546 6.66669 16 6.66669C10.8453 6.66669 6.66663 10.8454 6.66663 16C6.66663 21.1547 10.8453 25.3334 16 25.3334Z"
          fill="#cebd42"
        />
        <path
          d="M16 30.6133C15.2667 30.6133 14.6667 30.0666 14.6667 29.3333V29.2266C14.6667 28.4933 15.2667 27.8933 16 27.8933C16.7334 27.8933 17.3334 28.4933 17.3334 29.2266C17.3334 29.96 16.7334 30.6133 16 30.6133ZM25.52 26.8533C25.1734 26.8533 24.84 26.72 24.5734 26.4666L24.4 26.2933C23.88 25.7733 23.88 24.9333 24.4 24.4133C24.92 23.8933 25.76 23.8933 26.28 24.4133L26.4534 24.5866C26.9734 25.1066 26.9734 25.9466 26.4534 26.4666C26.2 26.72 25.8667 26.8533 25.52 26.8533ZM6.48004 26.8533C6.13337 26.8533 5.80004 26.72 5.53337 26.4666C5.01337 25.9466 5.01337 25.1066 5.53337 24.5866L5.70671 24.4133C6.22671 23.8933 7.06671 23.8933 7.58671 24.4133C8.10671 24.9333 8.10671 25.7733 7.58671 26.2933L7.41337 26.4666C7.16004 26.72 6.81337 26.8533 6.48004 26.8533ZM29.3334 17.3333H29.2267C28.4934 17.3333 27.8934 16.7333 27.8934 16C27.8934 15.2666 28.4934 14.6666 29.2267 14.6666C29.96 14.6666 30.6134 15.2666 30.6134 16C30.6134 16.7333 30.0667 17.3333 29.3334 17.3333ZM2.77337 17.3333H2.66671C1.93337 17.3333 1.33337 16.7333 1.33337 16C1.33337 15.2666 1.93337 14.6666 2.66671 14.6666C3.40004 14.6666 4.05337 15.2666 4.05337 16C4.05337 16.7333 3.50671 17.3333 2.77337 17.3333ZM25.3467 7.98665C25 7.98665 24.6667 7.85331 24.4 7.59998C23.88 7.07998 23.88 6.23998 24.4 5.71998L24.5734 5.54665C25.0934 5.02665 25.9334 5.02665 26.4534 5.54665C26.9734 6.06665 26.9734 6.90665 26.4534 7.42665L26.28 7.59998C26.0267 7.85331 25.6934 7.98665 25.3467 7.98665ZM6.65337 7.98665C6.30671 7.98665 5.97337 7.85331 5.70671 7.59998L5.53337 7.41331C5.01337 6.89331 5.01337 6.05331 5.53337 5.53331C6.05337 5.01331 6.89337 5.01331 7.41337 5.53331L7.58671 5.70665C8.10671 6.22665 8.10671 7.06665 7.58671 7.58665C7.33337 7.85331 6.98671 7.98665 6.65337 7.98665ZM16 4.05331C15.2667 4.05331 14.6667 3.50665 14.6667 2.77331V2.66665C14.6667 1.93331 15.2667 1.33331 16 1.33331C16.7334 1.33331 17.3334 1.93331 17.3334 2.66665C17.3334 3.39998 16.7334 4.05331 16 4.05331Z"
          fill="#cebd42"
        />
      </svg>
    </>
  );
};

export default SunIcon;
