import LoaderFetch from "Components/Ui/LoaderErrorFetch/LoaderFetch/LoaderFetch";
import UseFetchTerms from "./GetDataTerms/GetDataTerms";
import ErrorFetch from "Components/Ui/LoaderErrorFetch/ErrorFetch/ErrorFetch";
import "./Terms.css";
import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import BreadcrumbsPage from "Components/Ui/BreadcrumbsPage/BreadcrumbsPage";
import NotFoundDataPage from "Components/Ui/NotFoundDataPage/NotFoundDataPage";

const Terms = () => {
  // FETCHH DATA NEWS CARDS
  const { data, error, isLoading } = UseFetchTerms("allTermsContent");

  if (isLoading) return <LoaderFetch />;
  if (error) return <ErrorFetch />;

  return (
    <>
      <HelmetInfo titlePage={"الشروط والاحكام"} />
      <header>
        <BreadcrumbsPage
          newClassBread={"breadcrumb-page-route"}
          routeTitleTwoBread={"/terms"}
          titleTwoBread={"الشروط والاحكام"}
          textBreadActive={false}
          isTruePageRoute={false}
        />
      </header>
      <main>
        <div className="all-terms-content padding-top-30">
          <ContainerMedia>
            {data?.data && data.data.terms ? (
              <p className="text-terms">{data.data.terms}</p>
            ) : (
              <NotFoundDataPage />
            )}
          </ContainerMedia>
        </div>
      </main>
    </>
  );
};

export default Terms;
