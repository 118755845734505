import { Link } from "react-router-dom";
import "./BreadcrumbsPage.css";
import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import ToggleBars from "Assets/Icons/ToggleBars";
const BreadcrumbsPage = ({
  newClassBread,
  routeTitleTwoBread,
  titleTwoBread,
  textBreadActive,
  isTruePageRoute
}) => {
  return (
    <div
      data-aos="fade-left"
      className={`breadcrumb-page position-relative  ${newClassBread} z-1`}
    >
      <ContainerMedia>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb gap-1">
            <li className="breadcrumb-item">
              <Link
                to="/"
                className="link-bread d-flex align-items-center gap-2"
              >
                {" "}
                <ToggleBars />
                الصفحة الرئيسية{" "}
              </Link>
            </li>
            <li className="breadcrumb-item">/</li>

            <li className="breadcrumb-item">
              <Link
                to={routeTitleTwoBread}
                className="link-bread  d-flex align-items-center gap-2"
              >
                {titleTwoBread}
              </Link>
            </li>
            {isTruePageRoute && (
              <>
                <li className="breadcrumb-item">/</li>
                <li
                  className="breadcrumb-item active d-flex align-items-center gap-2"
                  aria-current="page"
                >
                  {textBreadActive}
                </li>
              </>
            )}
          </ol>
        </nav>
      </ContainerMedia>
    </div>
  );
};

export default BreadcrumbsPage;
