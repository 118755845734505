import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import SectionTitle from "Components/Ui/SectionTitle/SectionTitle";
import SliderCardNewsContent from "Pages/Home/Components/NewsCards/SliderCardNewsContent";

const SliderNewsCards = () => {
  return (
    <div className="slider-cards-news-details padding-top pb-2">
      {/* ================== START CONTAINER ==================== */}
      <ContainerMedia>
        <SectionTitle title={"مقالات مشابهه"} />
        <SliderCardNewsContent />
      </ContainerMedia>
      {/* ================== END CONTAINER ==================== */}
    </div>
  );
};

export default SliderNewsCards;
