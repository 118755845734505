import "./DetailsPageContent.css";
import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import InfoDetailsPerson from "../InfoDetailsPerson/InfoDetailsPerson";
import { Skeleton } from "@mui/material";
import { useState } from "react";

const DetailsPageContent = ({
  image,
  onImageLoad,
  BadgeTitle,
  titleInfo,
  timeDay,
  isTrueUserInfo,
  imageUser,
  nameUser,
  timeAdd,
  isRenderHtml,
  renderTextContent,
  isTrueTextContentDetails,
  textInfoDetails
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  // FUNCTION WHEN LOAD IMAGE
  const handleImageLoad = () => {
    setImageLoaded(true);
    if (onImageLoad) {
      onImageLoad();
    }
  };
  return (
    <div className="header-details-card-page padding-top-30">
      {/* ============== START CONTAINER ============== */}
      <ContainerMedia>
        {/* ============== START ALL HEADER DETAILS CARDS =========== */}
        <div className="all-header-details-cards">
          {/* =============== START MAIN IMAGE CARD ================ */}
          <div className="main-image-header h-100 position-relative">
            {!imageLoaded && (
              <div className="loader text-white">
                {" "}
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  variant="rectangular"
                  className="new-skelton"
                />
              </div>
            )}
            {/* ============== START HEADER IMAGE ============== */}
            <div className="header-image" data-aos="fade-up">
              <img
                src={image}
                alt="imageDetails"
                className="w-100 object-fit-cover"
                height={"440px"}
                onLoad={handleImageLoad}
              />
            </div>
            {/* ============== END HEADER IMAGE ============== */}
          </div>
          {/* =============== END MAIN IMAGE CARD ================ */}
          {/* ============== START CONTENT INFO DETAILS ======= */}
          <div className="content-info-details" data-aos="fade-up">
            <div className="badge-head">{BadgeTitle}</div>
            <h2 className="title">{titleInfo}</h2>
            <p className="text-day">{timeDay}</p>
            {isTrueUserInfo && (
              <>
                {/* ============= START INFO DETAILS PERSON ======== */}
                <div className="main-details-person">
                  <InfoDetailsPerson
                    imagePerson={imageUser}
                    nameUser={nameUser}
                    timeAdd={timeAdd}
                  />
                </div>
                {/* ============= END INFO DETAILS PERSON ======== */}
              </>
            )}

            {isRenderHtml && (
              <div className="content-text">
                <p className="text">{renderTextContent}</p>
              </div>
            )}
            {isTrueTextContentDetails && (
              <div className="content-text">
                <p className="text">{textInfoDetails}</p>
              </div>
            )}
          </div>
          {/* ============== END CONTENT INFO DETAILS ======= */}
        </div>
        {/* ============== END ALL HEADER DETAILS CARDS =========== */}
      </ContainerMedia>
      {/* ============== END CONTAINER ============== */}
    </div>
  );
};

export default DetailsPageContent;
