import { Outlet, createBrowserRouter } from "react-router-dom";

import Layout from "../Components/Layout/Layout";
import Home from "../Pages/Home/Home";
import NotFound from "../Components/NotFound/NotFound";
import NewsPage from "Pages/News/NewsPage";
import BlogsPage from "Pages/Blogs/BlogsPage";
import DetailsPageNews from "Pages/News/Components/DetailsPageNews/DetailsPageNews";
import DetailsPageBlogs from "Pages/Blogs/Components/DetailsPageBlogs/DetailsPageBlogs";
import RecommendationsPage from "Pages/RecommendationsPage/RecommendationsPage";
import UserAccount from "Pages/UserAccount/UserAccount";
import ProtectedRoute from "../Components/ProtectedRoute/ProtectedRoute";
import Terms from "Pages/Terms/Terms";

let routers = createBrowserRouter([
  {
    path: "",
    element: <Layout />,
    children: [
      {
        path: "",
        element: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            index: true,
            element: <Home />
          },
          {
            path: "detailsPageNews/:id",
            element: (
              <ProtectedRoute>
                <DetailsPageNews />
              </ProtectedRoute>
            )
          },
          {
            path: "detailsPageBlog/:id",
            element: (
              <ProtectedRoute>
                <DetailsPageBlogs />
              </ProtectedRoute>
            )
          }
        ]
      },
      {
        path: "newsPage",
        element: (
          <>
            <NewsPage />
          </>
        )
      },
      { path: "blogsPage", element: <BlogsPage /> },
      { path: "recommendationsPage", element: <RecommendationsPage /> },
      {
        path: "userAccount",
        element: (
          <ProtectedRoute>
            <UserAccount />
          </ProtectedRoute>
        )
      },
      { path: "terms", element: <Terms /> },

      { path: "*", element: <NotFound /> }
    ]
  }
]);

export default routers;
