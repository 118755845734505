import React from 'react'

const UserIcon = () => {
  return (
    <><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9.99984" cy="4.99984" r="3.33333" stroke="white" stroke-width="1.5"/>
    <ellipse cx="9.99984" cy="14.1668" rx="5.83333" ry="3.33333" stroke="white" stroke-width="1.5"/>
    </svg>
    </>
  )
}

export default UserIcon