import { useDarkMode } from "Context/DarkModeProvider/DarkModeProvider";
import MoonIcon from "../../Assets/Icons/MoonIcon";
import SunIcon from "Assets/Icons/SunIcon";

const DarkLightToggle = () => {
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  return (
    <>
      <div onClick={toggleDarkMode} className="mode-icon cursor-pointer-event">
        {!isDarkMode ? <MoonIcon /> : <SunIcon />}
      </div>
    </>
  );
};

export default DarkLightToggle;
