import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import SectionTitle from "Components/Ui/SectionTitle/SectionTitle";
import "./FaqHome.css";
import { Accordion } from "react-bootstrap";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import BASE_URL from "config";
import LoaderFetch from "Components/Ui/LoaderErrorFetch/LoaderFetch/LoaderFetch";
import ErrorFetch from "Components/Ui/LoaderErrorFetch/ErrorFetch/ErrorFetch";
import NotFoundDataPage from "Components/Ui/NotFoundDataPage/NotFoundDataPage";

const faqApi = async () => {
  const response = await axios.get(`${BASE_URL}/faq`);
  return response.data;
};
const FaqHome = () => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["faq"], // Unique key
    queryFn: faqApi
  });

  if (isLoading)
    return (
      <>
        <LoaderFetch />
      </>
    );
  if (error)
    return (
      <>
        <ErrorFetch />
      </>
    );

  return (
    <div className="faq-home padding-top">
      {/* =============== START CONTAINER ============= */}
      <ContainerMedia>
        <SectionTitle title={"الأسئلة الشائعة"} />
        {/* ============== START ALL FAQ HOME ============ */}
        <div className="all-faq-home" data-aos="fade-up">
          <Accordion>
            {data?.faq && data.faq.length > 0 ? (
              data?.faq.map((item) => {
                return (
                  <Accordion.Item
                    key={item.id}
                    className="faq-one"
                    eventKey={`${item.id}`}
                  >
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                );
              })
            ) : (
              <NotFoundDataPage />
            )}
          </Accordion>
        </div>
        {/* ============== END ALL FAQ HOME ============ */}
      </ContainerMedia>
      {/* =============== END CONTAINER ============= */}
    </div>
  );
};

export default FaqHome;
