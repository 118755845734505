import React from 'react'

const PhoneIcon = () => {
  return (
    <><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.6202 11.4039C17.1902 11.4039 16.8502 11.0539 16.8502 10.6339C16.8502 10.2639 16.4802 9.49391 15.8602 8.82391C15.2502 8.17391 14.5802 7.79391 14.0202 7.79391C13.5902 7.79391 13.2502 7.44391 13.2502 7.02391C13.2502 6.60391 13.6002 6.25391 14.0202 6.25391C15.0202 6.25391 16.0702 6.79391 16.9902 7.76391C17.8502 8.67391 18.4002 9.80391 18.4002 10.6239C18.4002 11.0539 18.0502 11.4039 17.6202 11.4039Z" fill="#42CE8F"/>
    <path d="M21.2303 11.4023C20.8003 11.4023 20.4603 11.0523 20.4603 10.6323C20.4603 7.08234 17.5703 4.20234 14.0303 4.20234C13.6003 4.20234 13.2603 3.85234 13.2603 3.43234C13.2603 3.01234 13.6003 2.65234 14.0203 2.65234C18.4203 2.65234 22.0003 6.23234 22.0003 10.6323C22.0003 11.0523 21.6503 11.4023 21.2303 11.4023Z" fill="#42CE8F"/>
    <path d="M11.0502 15.6023L9.20024 17.4523C8.81024 17.8423 8.19024 17.8423 7.79024 17.4623C7.68024 17.3523 7.57024 17.2523 7.46024 17.1423C6.43024 16.1023 5.50024 15.0123 4.67024 13.8723C3.85024 12.7323 3.19024 11.5923 2.71024 10.4623C2.24024 9.32234 2.00024 8.23234 2.00024 7.19234C2.00024 6.51234 2.12024 5.86234 2.36024 5.26234C2.60024 4.65234 2.98024 4.09234 3.51024 3.59234C4.15024 2.96234 4.85024 2.65234 5.59024 2.65234C5.87024 2.65234 6.15024 2.71234 6.40024 2.83234C6.66024 2.95234 6.89024 3.13234 7.07024 3.39234L9.39024 6.66234C9.57024 6.91234 9.70024 7.14234 9.79024 7.36234C9.88024 7.57234 9.93024 7.78234 9.93024 7.97234C9.93024 8.21234 9.86024 8.45234 9.72024 8.68234C9.59024 8.91234 9.40024 9.15234 9.16024 9.39234L8.40024 10.1823C8.29024 10.2923 8.24024 10.4223 8.24024 10.5823C8.24024 10.6623 8.25024 10.7323 8.27024 10.8123C8.30024 10.8923 8.33024 10.9523 8.35024 11.0123C8.53024 11.3423 8.84024 11.7723 9.28024 12.2923C9.73024 12.8123 10.2102 13.3423 10.7302 13.8723C10.8302 13.9723 10.9402 14.0723 11.0402 14.1723C11.4402 14.5623 11.4502 15.2023 11.0502 15.6023Z" fill="#42CE8F"/>
    <path d="M21.9703 18.9814C21.9703 19.2614 21.9203 19.5514 21.8203 19.8314C21.7903 19.9114 21.7603 19.9914 21.7203 20.0714C21.5503 20.4314 21.3303 20.7714 21.0403 21.0914C20.5503 21.6314 20.0103 22.0214 19.4003 22.2714C19.3903 22.2714 19.3803 22.2814 19.3703 22.2814C18.7803 22.5214 18.1403 22.6514 17.4503 22.6514C16.4303 22.6514 15.3403 22.4114 14.1903 21.9214C13.0403 21.4314 11.8903 20.7714 10.7503 19.9414C10.3603 19.6514 9.97034 19.3614 9.60034 19.0514L12.8703 15.7814C13.1503 15.9914 13.4003 16.1514 13.6103 16.2614C13.6603 16.2814 13.7203 16.3114 13.7903 16.3414C13.8703 16.3714 13.9503 16.3814 14.0403 16.3814C14.2103 16.3814 14.3403 16.3214 14.4503 16.2114L15.2103 15.4614C15.4603 15.2114 15.7003 15.0214 15.9303 14.9014C16.1603 14.7614 16.3903 14.6914 16.6403 14.6914C16.8303 14.6914 17.0303 14.7314 17.2503 14.8214C17.4703 14.9114 17.7003 15.0414 17.9503 15.2114L21.2603 17.5614C21.5203 17.7414 21.7003 17.9514 21.8103 18.2014C21.9103 18.4514 21.9703 18.7014 21.9703 18.9814Z" fill="#42CE8F"/>
    </svg>
    </>
  )
}

export default PhoneIcon