import { Skeleton } from "@mui/material";
import InfoDetailsPerson from "../InfoDetailsPerson/InfoDetailsPerson";
import "./CardBoxBlog.css";
import { useState } from "react";
const CardBoxBlog = ({
  image,
  onImageLoad,
  badgeText,
  title,
  imagePerson,
  nameUser,
  timeAdd,
  isTrueTextContent,
  textContent
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  // FUNCTION WHEN LOAD IMAGE
  const handleImageLoad = () => {
    setImageLoaded(true);
    if (onImageLoad) {
      onImageLoad();
    }
  };
  return (
    <div className="card-box-blog-one card-box-style h-100 cursor-pointer-event">
      {/* =============== START MAIN IMAGE CARD ================ */}
      <div className="main-image-header h-100 position-relative">
        {!imageLoaded && (
          <div className="loader text-white">
            {" "}
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              variant="rectangular"
              className="new-skelton"
            />
          </div>
        )}
        {/* ============= START IMAGE CARD NEWS ============= */}
        <div className="image-card-news h-100">
          <img
            src={image}
            className="w-100 h-100 object-fit-cover"
            alt="imageBlog"
            onLoad={handleImageLoad}
          />
        </div>
        {/* ============= END IMAGE CARD NEWS ============= */}
      </div>
      {/* =============== END MAIN IMAGE CARD ================ */}
      {/* ============= START CONTENT CARD NEWS =========== */}
      <div className="content-card-news">
        <h2 className="badge-head">{badgeText}</h2>
        <h2 className="title">{title}</h2>
      </div>
      {/* ============= END CONTENT CARD NEWS =========== */}
      {/* ========== START END CONTENT ============ */}
      <div className="end-content-info mt-auto">
        {/* ============= START INFO DETAILS PERSON ======== */}
        <InfoDetailsPerson
          imagePerson={imagePerson}
          nameUser={nameUser}
          timeAdd={timeAdd}
        />
        {/* ============= END INFO DETAILS PERSON ======== */}
        {isTrueTextContent && <p className="text-content">{textContent}</p>}
      </div>
      {/* ========== END END CONTENT ============ */}
    </div>
  );
};

export default CardBoxBlog;
