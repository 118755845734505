import React from 'react'

const WalletIcon = () => {
  return (
    <><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.8799 3.35882L16.8399 3.45215L12.9733 12.4255H9.17328C8.26661 12.4255 7.39995 12.6121 6.61328 12.9455L8.94661 7.37215L8.99995 7.23882L9.09328 7.02548C9.11995 6.94548 9.14662 6.86548 9.18662 6.79882C10.9333 2.75882 12.9066 1.83882 16.8799 3.35882Z" stroke="#42CE8F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24.0667 12.6927C23.4667 12.506 22.8267 12.426 22.1867 12.426H12.9734L16.8401 3.45271L16.8801 3.35938C17.0801 3.42604 17.2667 3.51938 17.4667 3.59938L20.4134 4.83938C22.0534 5.51938 23.2001 6.22604 23.8934 7.07938C24.0267 7.23938 24.1334 7.38604 24.2267 7.55938C24.3467 7.74604 24.4401 7.93271 24.4934 8.13271C24.5467 8.25271 24.5867 8.37271 24.6134 8.47938C24.9734 9.59938 24.7601 10.9727 24.0667 12.6927Z" stroke="#42CE8F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M28.6957 18.9324V21.5324C28.6957 21.7991 28.6824 22.0658 28.669 22.3324C28.4157 26.9858 25.8157 29.3324 20.8824 29.3324H10.4824C10.1624 29.3324 9.84237 29.3058 9.53571 29.2658C5.29571 28.9858 3.02904 26.7191 2.74904 22.4791C2.70904 22.1724 2.68237 21.8524 2.68237 21.5324V18.9324C2.68237 16.2524 4.30904 13.9458 6.62904 12.9458C7.42904 12.6124 8.28237 12.4258 9.18904 12.4258H22.2024C22.8557 12.4258 23.4957 12.5191 24.0824 12.6924C26.7357 13.5058 28.6957 15.9858 28.6957 18.9324Z" stroke="#42CE8F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.94675 7.37109L6.61341 12.9444C4.29341 13.9444 2.66675 16.2511 2.66675 18.9311V15.0244C2.66675 11.2378 5.36008 8.07776 8.94675 7.37109Z" stroke="#42CE8F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M28.6915 15.0222V18.9289C28.6915 15.9956 26.7448 13.5022 24.0781 12.7022C24.7715 10.9689 24.9715 9.60891 24.6381 8.47557C24.6115 8.35557 24.5715 8.23557 24.5181 8.12891C26.9981 9.40891 28.6915 12.0356 28.6915 15.0222Z" stroke="#42CE8F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    </>
  )
}

export default WalletIcon