import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import CardComapny from "./CardComapny";
import SectionTitle from "Components/Ui/SectionTitle/SectionTitle";
import LoaderFetch from "Components/Ui/LoaderErrorFetch/LoaderFetch/LoaderFetch";
import ErrorFetch from "Components/Ui/LoaderErrorFetch/ErrorFetch/ErrorFetch";

import axios from "axios";
import BASE_URL from "config";
import { useQuery } from "@tanstack/react-query";
import NotFoundDataPage from "Components/Ui/NotFoundDataPage/NotFoundDataPage";

const Companys = () => {
  const partnerApi = async () => {
    const response = await axios.get(`${BASE_URL}/partner`);
    return response.data;
  };
  const { data, error, isLoading } = useQuery({
    queryKey: ["partner"], // Unique key
    queryFn: partnerApi
  });

  if (isLoading)
    return (
      <>
        <LoaderFetch />
      </>
    );
  if (error)
    return (
      <>
        <ErrorFetch />
      </>
    );

  return (
    <div className="company-content padding-top">
      {/* ============== START CONTAINER ============= */}
      <ContainerMedia>
        <SectionTitle title={"أفضل الشركات"} />
        {/* ============= START ALL COMPANY CARDS =========== */}
        <div className="all-company-cards" data-aos="fade-up">
          {/* ============= START ROW ============= */}
          <div className="row g-3">
            {data?.partner.length > 0 ? (
              data?.partner.map((item) => {
                return (
                  <>
                    {/* ============= START COL ============ */}
                    <div key={item.id} className="col-6 col-sm-4 col-xl-2">
                      <CardComapny imageCompany={item.image} />
                    </div>
                    {/* ============= END COL ============ */}
                  </>
                );
              })
            ) : (
              <NotFoundDataPage />
            )}
          </div>
          {/* ============= END ROW ============= */}
        </div>
        {/* ============= END ALL COMPANY CARDS =========== */}
      </ContainerMedia>
      {/* ============== END CONTAINER ============= */}
    </div>
  );
};

export default Companys;
