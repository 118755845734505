import React from "react";

const ErrorFetch = () => {
  return (
    <>
      {" "}
      <div className="text-error-content mt-3">
        <h2 className="error-text">هناك خطاء برجاء المحاولة لاحقا</h2>
      </div>
    </>
  );
};

export default ErrorFetch;
