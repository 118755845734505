import "./Companys.css"
const CardComapny = ({imageCompany}) => {
  return (
    <div className="card-company-one">
      {/* =========== START IMAGE CARD COMPANY ============ */}
      <div className="image-card-comapny">
        <img
          src={imageCompany}
          alt="imageCompany"
          className="w-100"
        />
      </div>
      {/* =========== END IMAGE CARD COMPANY ============ */}
    </div>
  );
};

export default CardComapny;
