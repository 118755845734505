import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";
import AosAnimation from "./Components/AosAnimation/AosAnimation";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "swiper/swiper-bundle.css";
import routers from "./Routes/Routers";
import "../src/Assets/WebFont/IBM.css";
import WindowLoader from "Components/WindowLoader/WindowLoader";
import ButtonScroll from "Components/ButtonScroll/ButtonScroll";
import ButtonWhatsAppFixed from "Components/Ui/ButtonWhatsAppFixed/ButtonWhatsAppFixed";
import "./index.css";
import "./DarkMode.css";
import ToastContainerApp from "Components/ToastContainerApp/ToastContainerApp";
import { useContext, useEffect } from "react";
import { UserAuthContext } from "Context/UserAuthProvider/UserAuthProvider";

function App() {
  // GET TOKEN FRO MLOCAL STORGE IF USER LOGIN OR REGISTER
  let { setUserIsLoginToken } = useContext(UserAuthContext);
  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      setUserIsLoginToken(localStorage.getItem("token"));
    }
  }, []);

  useEffect(() => {
    console.clear();
  }, []);

  return (
    <AosAnimation>
      <div className={`App`}>
        <WindowLoader />
        <ButtonScroll />
        <ButtonWhatsAppFixed />
        <ToastContainerApp />
        <HelmetProvider>
          <RouterProvider router={routers} />
        </HelmetProvider>
      </div>
    </AosAnimation>
  );
}

export default App;
