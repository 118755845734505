import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let toastDisplayed = false;

const ProtectedRoute = (props) => {
  useEffect(() => {
    return () => {
      toastDisplayed = false;
    };
  }, []);

  if (localStorage.getItem("token") !== null) {
    return props.children;
  } else {
    if (!toastDisplayed) {
      toast.error("برجاء تسجيل الدخول لرؤية التفاصيل.");
      toastDisplayed = true;
    }
    return <Navigate to="/" />;
  }
};

export default ProtectedRoute;
