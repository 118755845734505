import { useParams } from "react-router-dom";
import DetailsPageContent from "Components/Ui/DetailsPageContent/DetailsPageContent";
import ErrorFetch from "Components/Ui/LoaderErrorFetch/ErrorFetch/ErrorFetch";
import LoaderFetch from "Components/Ui/LoaderErrorFetch/LoaderFetch/LoaderFetch";
import UseImageLoading from "Components/Ui/UseImageLoading/UseImageLoading";
import useFetchBlogs from "../GetCardsBlogs/GetCardsBlogs";
import { FormatDateTime } from "Components/Ui/FormatDateTime/FormatDateTime";
import { formatDistanceToNow } from "date-fns";
import { ar } from "date-fns/locale";
import NotFoundDataPage from "Components/Ui/NotFoundDataPage/NotFoundDataPage";
import DOMPurify from "dompurify";
const HeaderDetailsPageBlog = () => {
  const { id } = useParams();
  // FETCHH DATA NEWS CARDS
  const { data, error, isLoading } = useFetchBlogs("allBlogsCards");

  const blogsItem = data?.data.data.find((item) => item.id === parseInt(id));
  // LOADING WHEN GET IMAGE
  const { handleImageLoad } = UseImageLoading(blogsItem, isLoading);
  if (error)
    return (
      <>
        <ErrorFetch />
      </>
    );
  // LOADER WHEN GET ALL CARDS
  if (isLoading) {
    return (
      <>
        <LoaderFetch />
      </>
    );
  }
  if (!blogsItem) {
    return <NotFoundDataPage />;
  }
  const ContentInfo = DOMPurify.sanitize(blogsItem.content);

  return (
    <>
      <DetailsPageContent
        image={blogsItem.image}
        onImageLoad={() => handleImageLoad(blogsItem.id)}
        BadgeTitle={blogsItem.category.title}
        titleInfo={blogsItem.title}
        timeDay={FormatDateTime(blogsItem.created_at)}
        isTrueUserInfo={true}
        imageUser={blogsItem.admin.image}
        nameUser={blogsItem.admin.name}
        timeAdd={formatDistanceToNow(new Date(blogsItem.admin.created_at), {
          addSuffix: true,
          locale: ar
        })}
        isRenderHtml={true}
        renderTextContent={
          <div dangerouslySetInnerHTML={{ __html: ContentInfo }} />
        }
        isTrueTextContentDetails={false}
        textInfoDetails={false}
      />
    </>
  );
};

export default HeaderDetailsPageBlog;
