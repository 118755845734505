import { useNavigate } from "react-router-dom";
import PaginationPage from "Components/Pagination/Pagination";
import { FormatDateTime } from "../../../Components/Ui/FormatDateTime/FormatDateTime";
import CardBoxNews from "Components/Ui/CardBoxNews/CardBoxNews";
import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import ErrorFetch from "../../../Components/Ui/LoaderErrorFetch/ErrorFetch/ErrorFetch";
import LoaderFetch from "../../../Components/Ui/LoaderErrorFetch/LoaderFetch/LoaderFetch";
import useFetch from "./GetCardsApi/GetCardsNewsApi";
import usePagination from "../../../Components/Pagination/usePagination";
import UseImageLoading from "Components/Ui/UseImageLoading/UseImageLoading";
import NotFoundDataPage from "Components/Ui/NotFoundDataPage/NotFoundDataPage";
const AllCardsNews = () => {
  // ROUTE NAVGIATION FOR DETAILS CARD
  const navigate = useNavigate();
  // FETCHH DATA NEWS CARDS
  const { data, error, isLoading } = useFetch("allNewsCards");
  // PAGINATION CARDS
  const { setCurrentPage, totalPages, currentPageData } = usePagination(
    data?.data.data, // THIS DATA OF ARRAY
    12, // THIS HOW MANY CARDS TO SHOW IN PAGE I'M ADD "12" CARDS
    3 // THIS "3" FOR START FROM NUMBER CARD 3 BECAUSE I USE FROM "0" TO "3" IN HEADER
  );

  // Pagination
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // LOADING WHEN GET IMAGE
  const { handleImageLoad } = UseImageLoading(data, isLoading);

  // ERROR
  if (error) return <ErrorFetch />;
  // LOADING
  if (isLoading) return <LoaderFetch />;

  return (
    <div className="all-cards-news-page padding-top-30">
      <ContainerMedia>
        <div className="main-cards-news-page" data-aos="fade-up">
          <div className="row g-3">
            {currentPageData.length > 0 ? (
              currentPageData.map((item) => {
                return (
                  <div
                    key={item.id}
                    onClick={() => {
                      navigate(`/detailsPageNews/${item.id}`);
                    }}
                    className="col-12 col-sm-6 col-lg-4"
                  >
                    <CardBoxNews
                      image={item.image}
                      onImageLoad={() => handleImageLoad(item.id)}
                      badgeText={item.category.title}
                      title={item.title.split(" ").slice(0, 20).join(" ")}
                      timeDay={FormatDateTime(item.created_at)}
                    />
                  </div>
                );
              })
            ) : (
              <NotFoundDataPage />
            )}
          </div>
          {currentPageData.length > 0 ? (
            <>
              {/* ============ START PAGINATION ============= */}
              <PaginationPage
                itemCount={totalPages}
                onPageChange={handlePageChange}
              />
              {/* ============ END PAGINATION ============= */}
            </>
          ) : (
            ""
          )}
        </div>
      </ContainerMedia>
    </div>
  );
};

export default AllCardsNews;
