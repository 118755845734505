import React from "react";

const UsersIcon1 = () => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.9999 9.54797C23.9199 9.53464 23.8266 9.53464 23.7466 9.54797C21.9066 9.48131 20.4399 7.97464 20.4399 6.10797C20.4399 4.2013 21.9733 2.66797 23.8799 2.66797C25.7866 2.66797 27.3199 4.21464 27.3199 6.10797C27.3066 7.97464 25.8399 9.48131 23.9999 9.54797Z"
          stroke="#42CE8F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.6267 19.2549C24.4533 19.5615 26.4667 19.2415 27.88 18.2948C29.76 17.0415 29.76 14.9882 27.88 13.7349C26.4533 12.7882 24.4133 12.4682 22.5867 12.7882"
          stroke="#42CE8F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.95989 9.54797C8.03989 9.53464 8.13323 9.53464 8.21323 9.54797C10.0532 9.48131 11.5199 7.97464 11.5199 6.10797C11.5199 4.2013 9.98656 2.66797 8.0799 2.66797C6.17323 2.66797 4.63989 4.21464 4.63989 6.10797C4.65323 7.97464 6.11989 9.48131 7.95989 9.54797Z"
          stroke="#42CE8F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.33325 19.2549C7.50659 19.5615 5.49326 19.2415 4.07992 18.2948C2.19992 17.0415 2.19992 14.9882 4.07992 13.7349C5.50659 12.7882 7.54659 12.4682 9.37325 12.7882"
          stroke="#42CE8F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.9999 19.505C15.9199 19.4917 15.8266 19.4917 15.7466 19.505C13.9066 19.4383 12.4399 17.9317 12.4399 16.065C12.4399 14.1583 13.9733 12.625 15.8799 12.625C17.7866 12.625 19.3199 14.1717 19.3199 16.065C19.3066 17.9317 17.8399 19.4517 15.9999 19.505Z"
          stroke="#42CE8F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.12 23.7045C10.24 24.9579 10.24 27.0112 12.12 28.2645C14.2533 29.6912 17.7466 29.6912 19.88 28.2645C21.76 27.0112 21.76 24.9579 19.88 23.7045C17.76 22.2912 14.2533 22.2912 12.12 23.7045Z"
          stroke="#42CE8F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default UsersIcon1;
