import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import BASE_URL from "../../../../config";
const useFetchRecommendations = (queryKey) => {
  const getAllRecommendations = () => axios.get(`${BASE_URL}/allrecommends`);
  return useQuery({
    queryKey,
    queryFn: getAllRecommendations
  });
};

export default useFetchRecommendations;
