import BlogsCards from "Pages/Home/Components/BlogsCards/BlogsCards";
import React from "react";

const HeaderBlogsPage = () => {
  return (
    <div className="all-blogs-header-page padding-top-30" >
      <BlogsCards />
    </div>
  );
};

export default HeaderBlogsPage;
