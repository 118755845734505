import { useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import CustomModal from "Components/CustomModal/CustomModal";
import InputFiled from "Components/Forms/InputField";
import FormField from "Components/Forms/FormFiled";
import OtpForm from "../OtpForm/OtpForm";
import { toast } from "react-toastify";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BASE_URL from "config";
const ForgotPassword = ({
  showModalForgotPassword,
  hideModalForgotPassword
}) => {
  // EMAIL SEND OTP
  const [email, setEmail] = useState("");
  const initialValues = {
    email: ""
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("بريد إلكتروني غير صالح")
      .required("البريد الإلكتروني مطلوب")
  });

  // CHANGE PASSWORD
  const validationSchemaPassword = Yup.object().shape({
    password: Yup.string()
      .min(8, "كلمة المرور قصيرة جداً")
      .max(30, "كلمة المرور طويلة جداً")
      .required("كلمة المرور مطلوبة"),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("password"), null], "كلمة المرور غير متطابقة")
      .required("تأكيد كلمة المرور مطلوب")
  });

  const initialValuesPassword = {
    password: "",
    passwordConfirm: ""
  };
  // FOR EYE SOW HIDE PASSOWRD
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  const togglePasswordVisibility2 = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };
  // OTP FORM
  const [otpFormOpen, setOtpFormOpen] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const hideOtpForm = () => {
    setOtpFormOpen(false);
  };
  const [OtpNumber, setOtpNumber] = useState("");
  const [loaderSubmit, setLoaderSubmit] = useState(false);
  const [disabledValue, setDisabledValue] = useState(false);
  const removeLoaderDisabled = () => {
    setLoaderSubmit(false);
    setDisabledValue(false);
  };
  const [ChangePasswordLoading, setChangePasswordLoading] = useState(false);
  // HANDEL SUBMIT EMAIL
  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoaderSubmit(true);
      setDisabledValue(true);
      const response = await axios.post(`${BASE_URL}/otp`, {
        email: values.email
      });

      toast.success("لقد تم الارسال على الايميل.");
      setEmail(values.email);
      setOtpNumber(response.data.otp);
      setOtpFormOpen(true);
      removeLoaderDisabled();
      hideModalForgotPassword();
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
        removeLoaderDisabled();
      } else if (error.request) {
        toast.error("برجاء المحاولة لاحقا.");
        removeLoaderDisabled();
      } else {
        toast.error("برجاء المحاولة لاحقا.");
        removeLoaderDisabled();
      }
    }
    resetForm();
  };

  // CHANGE PASSWORD
  const handleSubmitPassword = async (values, { resetForm }) => {
    try {
      setChangePasswordLoading(true);

      const response = await axios.post(`${BASE_URL}/forgetpassword`, {
        password: values.password,
        password_confirmation: values.passwordConfirm,
        email: email,
        otp: OtpNumber
      });
      if (response.data.success) {
        toast.success("لقد تم تعير كلمة السر.");
        setChangePasswordModal(false);
        setChangePasswordLoading(false);
      } else {
        toast.success(response.data.message);
        setOtpFormOpen(true);
        removeLoaderDisabled();
        hideModalForgotPassword();
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
        removeLoaderDisabled();
      } else if (error.request) {
        toast.error("برجاء المحاولة لاحقا.");
        removeLoaderDisabled();
      } else {
        toast.error("برجاء المحاولة لاحقا.");
        removeLoaderDisabled();
      }
    }
    resetForm();
  };
  return (
    <>
      <OtpForm
        showOtpForm={otpFormOpen}
        hideOtpForm={hideOtpForm}
        email={email}
        otp={OtpNumber}
        setChangePasswordModal={setChangePasswordModal}
      />
      {/* ============== START MODAL CHANGE PASSWORD ================= */}
      <CustomModal
        show={changePasswordModal}
        onHide={() => setChangePasswordModal(false)}
        title={"تغير الرقم السرى"}
        newClass={"modal-forgot-pass form-auth form-modal-width"}
      >
        <div className="all-form-auth">
          {/* ========== START FORM FIELD ========= */}
          <FormField
            initialValues={initialValuesPassword}
            validationSchema={validationSchemaPassword}
            onSubmit={handleSubmitPassword}
          >
            <div className="input-pass position-relative">
              <InputFiled
                label="كلمة المرور"
                name="password"
                placeholder="كلمة المرور"
                success
                value={password}
                onChange={handlePasswordChange}
                type={showPassword ? "text" : "password"}
              />

              <button
                className="icon-eye-button icon-eye-button-left"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </button>
            </div>
            <div className="input-pass position-relative">
              <InputFiled
                label="تأكيد كلمة المرور"
                name="passwordConfirm"
                placeholder="تأكيد كلمة المرور"
                success
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                type={showConfirmPassword ? "text" : "password"}
              />

              <button
                className="icon-eye-button icon-eye-button-left"
                onClick={togglePasswordVisibility2}
              >
                {showConfirmPassword ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </button>
            </div>

            <button
              type="submit"
              className={`btn-main btn-height btn-submit w-100 mt-3 ${
                ChangePasswordLoading ? "bttDisabled" : ""
              }`}
              disabled={ChangePasswordLoading ? "disabled" : ""}
            >
              {ChangePasswordLoading ? (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "ارسال الرمز"
              )}
            </button>
          </FormField>
          {/* ========== END FORM FIELD ========= */}
        </div>
      </CustomModal>
      {/* ============== END MODAL CHANGE PASSWORD ================= */}

      {/* ============== START MODAL EMAIL ================= */}
      <CustomModal
        show={showModalForgotPassword}
        onHide={hideModalForgotPassword}
        title={"تغير الرقم السرى"}
        newClass={"modal-forgot-pass form-auth form-modal-width"}
      >
        <div className="all-form-auth">
          {/* ========== START FORM FIELD ========= */}
          <FormField
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <InputFiled
              label="البريد الإلكتروني"
              name="email"
              type="email"
              placeholder=""
              success
            />

            <button
              type="submit"
              className={`btn-main btn-height btn-submit w-100 mt-3 ${
                disabledValue ? "bttDisabled" : ""
              }`}
              disabled={disabledValue ? "disabled" : ""}
            >
              {loaderSubmit ? (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "ارسال الرمز"
              )}
            </button>
          </FormField>
          {/* ========== END FORM FIELD ========= */}
        </div>
      </CustomModal>
      {/* ============== END MODAL EMAIL ================= */}
    </>
  );
};

export default ForgotPassword;
