import React from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import CardBoxNews from "Components/Ui/CardBoxNews/CardBoxNews";
import ErrorFetch from "../../../../Components/Ui/LoaderErrorFetch/ErrorFetch/ErrorFetch";
import LoaderFetch from "../../../../Components/Ui/LoaderErrorFetch/LoaderFetch/LoaderFetch";
import { FormatDateTime } from "../../../../Components/Ui/FormatDateTime/FormatDateTime";
import useFetch from "../../../News/Components/GetCardsApi/GetCardsNewsApi";
import UseImageLoading from "Components/Ui/UseImageLoading/UseImageLoading";
import NotFoundDataPage from "Components/Ui/NotFoundDataPage/NotFoundDataPage";

const SliderCardNewsContent = () => {
  // NAVIGATE TO THE PAGE DETAILS USING ID OF CARD
  const navigate = useNavigate();
  // FETCHH DATA NEWS CARDS
  const { data, error, isLoading } = useFetch("allNewsCards");
  // LOADING WHEN GET IMAGE FROM API
  const { handleImageLoad } = UseImageLoading(data, isLoading);

  if (error)
    return (
      <>
        <ErrorFetch />
      </>
    );
  // LOADER WHEN GET ALL CARDS
  if (isLoading) {
    return (
      <>
        <LoaderFetch />
      </>
    );
  }
  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={15}
        loop={true}
        pagination={false}
        className="mySwiper mySwiper-news"
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        modules={[Autoplay]}
        breakpoints={{
          0: {
            slidesPerView: 1
          },
          480: {
            slidesPerView: 2
          },
          768: {
            slidesPerView: 3
          }
        }}
      >
        {data?.data.data.length > 0 ? (
          data?.data.data.map((item) => {
            return (
              <>
                <SwiperSlide
                  onClick={() => {
                    navigate(`/detailsPageNews/${item.id}`);
                  }}
                  key={item.id}
                >
                  <CardBoxNews
                    image={item.image}
                    onImageLoad={() => handleImageLoad(item.id)}
                    badgeText={item.category.title}
                    title={item.title.split(" ").slice(0, 20).join(" ")}
                    timeDay={FormatDateTime(item.created_at)}
                  />
                </SwiperSlide>
              </>
            );
          })
        ) : (
          <NotFoundDataPage />
        )}
      </Swiper>
    </>
  );
};

export default SliderCardNewsContent;
