import "./AppsInfo.css";
import appGoogle from "../../../Assets/images/apps/01.svg";
import appStore from "../../../Assets/images/apps/02.svg";
const AppsInfo = () => {
  return (
    <>
      <div className="apps-content">
        <h2 className="title-app">حمل التطبيق من هنا</h2>
        <div className="apps-images d-flex align-items-center gap-2">
          <a
            target="_blank"
            href="https://www.apple.com/app-store/"
            className="app-one cursor-pointer-event"
            rel="noreferrer"
          >
            <img
              src={appStore}
              alt="app store"
              loading="lazy"
              width={"189.27px"}
              height={"63px"}
            />
          </a>
          <a
            target="_blank"
            href="https://play.google.com/store"
            className="app-one cursor-pointer-event"
            rel="noreferrer"
          >
            <img
              src={appGoogle}
              alt="google app"
              loading="lazy"
              width={"189.27px"}
              height={"63px"}
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default AppsInfo;
