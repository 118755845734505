import { useContext, useState } from "react";
import axios from "axios";
import CustomModal from "Components/CustomModal/CustomModal";
import "./ApplicationManagePortfolio.css";
import iconGold from "../../Assets/images/main/iconGold.svg";
import { Slider } from "@mui/material";
import SuccessSend from "Components/Ui/SuccessSend/SuccessSend";
import { toast } from "react-toastify";
import { UserAuthContext } from "Context/UserAuthProvider/UserAuthProvider";
import BASE_URL from "config";

const ApplicationManagePortfolio = ({
  showModalApplication,
  hideModalApplication
}) => {
  // VALUE RANG
  const [value, setValue] = useState(2000);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [contactFrom, setContactFrom] = useState("");
  const [contactTo, setContactTo] = useState("");
  const [successSend, setSuccessSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledValue, setDisabledValue] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(null);
  // TO GET TOKEN
  let { userIsLoginToken } = useContext(UserAuthContext);
  const successSendButton = async (e) => {
    e.preventDefault();
    // IF VALUE RANGE < 2000 ADD TOAST ERROR
    if (value < 2000) {
      toast.error("الحد الأدنى للإستثمار 2000 ريال");
      return;
    }
    setLoading(true);
    setDisabledValue(true);

    const data = {
      amount: value,
      contact_from: contactFrom,
      contact_to: contactTo
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/project`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userIsLoginToken}`
          }
        }
      );

      // REMOVE LOADING AND DISABLED AND ADD MODAL SUCCESS
      setLoading(false);
      setDisabledValue(false);
      setSuccessSend(true);
      hideModalApplication();
      // IF DATA SENND SUCCESS ADD MODAL CONTENT SUCCESS
      if (response.data.success === true) {
        setMessageSuccess(response.data.message);
      }
    } catch (err) {
      // REMOVE DISABLED AND ERROR FROM BUTTON IF HAS ERROR
      setLoading(false);
      setDisabledValue(false);
      if (err.response && err.response.data && err.response.data.errors) {
        if (err.response.data.errors.contact_from) {
          toast.error(err.response.data.errors.contact_from[0]);
        }
        if (err.response.data.errors.contact_to) {
          toast.error(err.response.data.errors.contact_to[0]);
        }
      }
    }
  };

  const hidesuccessSendButton = () => {
    setSuccessSend(false);
  };

  return (
    <>
      <SuccessSend
        showsuccessModalSend={successSend}
        hideSuccessModalSend={hidesuccessSendButton}
        titleModal={messageSuccess}
        titleSend={messageSuccess}
        isTrueText={false}
        textSend={false}
        functionSend={hidesuccessSendButton}
        textButton={"تم"}
      />
      <CustomModal
        show={showModalApplication}
        onHide={hideModalApplication}
        title={"طلب لإدارة محفظتك"}
        newClass={"modal-application  modal-width-content"}
      >
        {/* ============= START ALL MODAL APPLICATION ================ */}
        <div className="all-modal-application">
          {/* =============== START HEADER APPLICATION ============= */}
          <div className="header-application d-flex align-items-center gap-2">
            <h2 className="title">طلب لإدارة محفظتك </h2>
            <img src={iconGold} alt="icon" width={"33px"} height={"33px"} />
          </div>
          {/* =============== END HEADER APPLICATION ============= */}
          {/* =============== START FILTER PRICE ================= */}
          <div className="filter-price-rang">
            <div className="main-header-price d-flex align-items-center justify-content-between">
              <h2 className="text-head">مبلغ الإستثمار</h2>
              <p className="price-head">{value}</p>
            </div>
            <div className="rang-price">
              <Slider
                value={value}
                onChange={handleChange}
                min={1000}
                max={1000000}
                aria-label="Price"
                valueLabelDisplay="auto"
              />
            </div>
            <h2 className="text-head">الحد الأدنى للإستثمار 2000 ريال</h2>
          </div>
          {/* =============== END FILTER PRICE ================= */}
          {/* =============== START TIME CONTACT =============== */}
          <div className="all-time-contact">
            <div className="header-time-contact d-flex align-items-center justify-content-between">
              <h2 className="text-head">وقت التواصل المفضل</h2>
            </div>
            {/* ============== START ALL INPUT TIMES ============== */}
            <div className="all-inputs-times mt-3">
              {/* ============== START ROW ============== */}
              <div className="row g-3">
                {/* ============== START COL ================ */}
                <div className="col-12 col-md-6">
                  <div className="form-time">
                    <label htmlFor="timeOne" className="form-label">
                      من الساعة
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      value={contactFrom}
                      onChange={(e) => setContactFrom(e.target.value)}
                    />
                  </div>
                </div>
                {/* ============== END COL ================ */}
                {/* ============== START COL ================ */}
                <div className="col-12 col-md-6">
                  <div className="form-time">
                    <label htmlFor="timeTwo" className="form-label">
                      حتى الساعة
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      value={contactTo}
                      onChange={(e) => setContactTo(e.target.value)}
                    />
                  </div>
                </div>
                {/* ============== END COL ================ */}
              </div>
              {/* ============== ENd ROW ============== */}
            </div>
            {/* ============== END ALL INPUT TIMES ============== */}
          </div>
          {/* =============== END TIME CONTACT =============== */}

          <button
            onClick={successSendButton}
            className={`btn-main btn-height w-100 ${
              disabledValue ? "bttDisabled" : ""
            }`}
            disabled={disabledValue ? "disabled" : ""}
          >
            {loading ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "إرسال طلب"
            )}
          </button>
        </div>
        {/* ============= END ALL MODAL APPLICATION ================ */}
      </CustomModal>
    </>
  );
};

export default ApplicationManagePortfolio;
