import { useDarkMode } from "Context/DarkModeProvider/DarkModeProvider";
import logoDark from "../../Assets/images/logo/01.png";
import logoLight from "../../Assets/images/logo/02.png";
import { Link } from "react-router-dom";
import "./LogoInfo.css"

const LogoInfo = () => {
  const { isDarkMode } = useDarkMode();

  return (
    <>
      {" "}
      <Link to="/" className="image-logo">
        <img
          src={!isDarkMode ? logoLight : logoDark}
          alt="logo"
          className="object-fit-cover w-100 h-100"
        />
      </Link>
    </>
  );
};

export default LogoInfo;
