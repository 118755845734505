// Function to format date and time
export function formatDateTimeRecommend(timestamp) {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    };
    return date.toLocaleString("en-US", options);
  }