import ScrollToTopPage from "Components/ScrollToTopPage/ScrollToTopPage";
import Footer from "../../Components/Footer/Footer";
import NavBarMenu from "../../Components/NavBarMenu/NavBarMenu";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <NavBarMenu />
      <Outlet />
      <ScrollToTopPage />
      <Footer />
    </>
  );
};

export default Layout;
