import axios from "axios";
import BASE_URL from "config";
import { useEffect, useState } from "react";

const SelectCountries = ({ name,selectedCountry,change }) => {
  // State to hold the fetched countries
  const [countries, setCountries] = useState([]);


  // Fetch data from the API
  useEffect(() => {
    axios
      .get(`${BASE_URL}/countries`)
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []); 

 

  return (
    <>
      <select
        className="form-select m-0 form-select-1"
        value={selectedCountry}
        onChange={change}
        name={name}
      >
        <option value="" disabled>
          اختار الدولة
        </option>
        {countries.map((country) => (
          <option key={country.id} value={country.id}>
            {country.title}
          </option>
        ))}
      </select>
    </>
  );
};

export default SelectCountries;



