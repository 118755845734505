import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { DarkModeProvider } from "Context/DarkModeProvider/DarkModeProvider";
import UserAuthProvider from "Context/UserAuthProvider/UserAuthProvider";
import { UserImageContextProvider } from "Context/UserDetailsProvider/UserImageContextProvider";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <UserAuthProvider>
    <UserImageContextProvider>
      <QueryClientProvider client={queryClient}>
        <React.StrictMode>
          <DarkModeProvider>
            <App />
          </DarkModeProvider>
        </React.StrictMode>
      </QueryClientProvider>
    </UserImageContextProvider>
  </UserAuthProvider>
);

reportWebVitals();
