import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import BASE_URL from "../../../../config";
const useFetch = (queryKey) => {
  const getAllNews = () => axios.get(`${BASE_URL}/allnews`);
  return useQuery({
    queryKey,
    queryFn: getAllNews
  });
};

export default useFetch;

