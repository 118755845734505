import CustomModal from "Components/CustomModal/CustomModal";
import CardTradingRecommendation from "Components/Ui/CardTradingRecommendation/CardTradingRecommendation";
import "./ModalDetailsRecommend.css";
import LoaderFetch from "Components/Ui/LoaderErrorFetch/LoaderFetch/LoaderFetch";
import ErrorFetch from "Components/Ui/LoaderErrorFetch/ErrorFetch/ErrorFetch";
import { formatDateTimeRecommend } from "./GetAllRecommendations/FormateDateTimeRecommend";
import useFetchRecommendations from "./GetAllRecommendations/GetAllRecommendations";
import { UserAuthContext } from "Context/UserAuthProvider/UserAuthProvider";
import { useContext } from "react";
import NotFoundDataPage from "Components/Ui/NotFoundDataPage/NotFoundDataPage";
import DOMPurify from "dompurify";

const ModalDetailsRecommend = ({
  showModalRecommend,
  hideModalRecommend,
  selectedCardId
}) => {
  // CONTEXT FOR LOGIN
  let { userIsLoginToken } = useContext(UserAuthContext);
  // FETCH DATA NEWS CARDS
  const { data, error, isLoading } = useFetchRecommendations(
    "allRecommendationsCards"
  );

  if (error) return <ErrorFetch />;
  if (isLoading) return <LoaderFetch />;


  const cardItemRecommend = data?.data?.data?.find(
    (item) => item.id === selectedCardId
  );

  if (!cardItemRecommend) {
    return ;
  }

  const ContentInfo = DOMPurify.sanitize(cardItemRecommend?.content || "");
  if (userIsLoginToken) {
    return (
      <CustomModal
        show={showModalRecommend}
        onHide={hideModalRecommend}
        title={""}
        newClass={"modal-recommend modal-width-content"}
      >
        {/* =============== START ALL MODAL RECOMMEND =========== */}
        <div className="all-modal-recommend">
          <h2 className="fs-5 text-white">
            {cardItemRecommend ? (
              <>
                {" "}
                <CardTradingRecommendation
                  imageSrc={cardItemRecommend.image}
                  isTrueEye={false}
                  title={cardItemRecommend.title}
                  times={formatDateTimeRecommend(cardItemRecommend.created_at)}
                  valueopening={Number(cardItemRecommend.opening)}
                  valueClosing={Number(cardItemRecommend.closing)}
                  valueTarget={Number(cardItemRecommend.target)}
                  isTrueCardContent={true}
                  textContentCard={<div dangerouslySetInnerHTML={{ __html: ContentInfo }} />}
                />
              </>
            ) : (
              <NotFoundDataPage />
            )}
          </h2>
        </div>
        {/* =============== END ALL MODAL RECOMMEND =========== */}
      </CustomModal>
    );
  }
};

export default ModalDetailsRecommend;
