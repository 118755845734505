import React, { useContext, useEffect, useState } from "react";
import CustomModal from "Components/CustomModal/CustomModal";
import axios from "axios";
import { UserAuthContext } from "Context/UserAuthProvider/UserAuthProvider";
import { toast } from "react-toastify";
import SelectCountries from "Components/Ui/SelectCountries/SelectCountries";
import { useNavigate } from "react-router-dom";
import BASE_URL from "config";

const ModalEditInfoUser = ({
  showModalEditAccount,
  hideModalEditAccount,
  userData,
  setUserData
}) => {
  const [editedData, setEditedData] = useState({
    name: userData.name,
    email: userData.email,
    phone: userData.phone,
    country: userData.country
  });

  const navigate = useNavigate();
  const [countryError, setCountryError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(userData.country_id);
  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    setEditedData((prevData) => ({
      ...prevData,
      country: e.target.options[e.target.selectedIndex].text // UPDATECINTRY NAME
    }));
  };
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [id]: value
    }));
  };

  let { userIsLoginToken, setUserIsLoginToken } = useContext(UserAuthContext);
  const [loaderSubmit, setLoaderSubmit] = useState(false);
  const [disabledValue, setDisabledValue] = useState(false);

  const removeLoaderDisabled = () => {
    setLoaderSubmit(false);
    setDisabledValue(false);
  };

  useEffect(() => {
    let showToast = true;
    const interval = setInterval(() => {
      if (!localStorage.getItem("token")) {
        console.log("token", localStorage.getItem("token"));
        localStorage.removeItem("token");

        navigate("/");
        setUserIsLoginToken(null);
        if (showToast) {
          toast.error("لم يتم تسجيل الدخول برجاء تسجيل الدخول مرة اخرى");
          showToast = false;
        }
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    if (!selectedCountry) {
      setCountryError(true);
      return;
    }
    setLoaderSubmit(true);
    setDisabledValue(true);
    axios
      .post(
        `${BASE_URL}/updateprofile`,
        { ...editedData, country_id: selectedCountry },
        {
          headers: {
            Authorization: `Bearer ${userIsLoginToken}`
          }
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        removeLoaderDisabled();
        setUserData(editedData); //UPDATE USER DATA
        hideModalEditAccount();
      })
      .catch((error) => {
        toast.error("برجاء المحاولة لاحقا.");
        removeLoaderDisabled();
      });
  };

  return (
    <CustomModal
      show={showModalEditAccount}
      onHide={hideModalEditAccount}
      title={"تعديل المعلومات الشخصية"}
      newClass={"modal-edit-account modal-width-content"}
    >
      <div className="user-content-info-details padding-top-30">
        <form onSubmit={handleSave}>
          <div className="row g-3">
            <div className="col-12 col-sm-6">
              <div className="input-user-one">
                <label htmlFor="name" className="form-label">
                  إسم المستخدم
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={editedData.name}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-12 col-sm-6">
              <div className="input-user-one">
                <label htmlFor="email" className="form-label">
                  البريد الإلكتروني
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  value={editedData.email}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="input-user-one">
                <label htmlFor="phone" className="form-label">
                  رقم الهاتف
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  value={editedData.phone}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-12 col-sm-6">
              <div className="form-select-country">
                <label htmlFor="countrySelect" className="form-label">
                  الدولة
                </label>
                <SelectCountries
                  name={"country_id"}
                  selectedCountry={selectedCountry}
                  change={handleCountryChange}
                />
                {countryError && (
                  <div className="error">يرجي اختيار الدولة</div>
                )}
              </div>
            </div>
          </div>

          <button
            type="submit"
            className={`btn-main btn-height w-100 mt-4 ${
              disabledValue ? "bttDisabled" : ""
            }`}
            disabled={disabledValue}
          >
            {loaderSubmit ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "حفظ"
            )}
          </button>
        </form>
      </div>
    </CustomModal>
  );
};

export default ModalEditInfoUser;
