
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import BASE_URL from "../../../../config";
import { UserAuthContext } from "Context/UserAuthProvider/UserAuthProvider";
import { useContext } from "react";
import LogOutFunction from "Components/Ui/UserDropMenu/LogOutFunction";

const UseFetchProfile = (queryKey) => {
  const { userIsLoginToken, setUserIsLoginToken } = useContext(UserAuthContext);
  const logOut = LogOutFunction();

  const getProfileData = async () => {
    const response = await axios.get(`${BASE_URL}/profile`, {
      headers: {
        Authorization: `Bearer ${userIsLoginToken}`
      }
    });
    return response.data;
  };

  // Use an object form when calling useQuery
  const { data, isLoading, isError } = useQuery({
    queryKey,
    queryFn: getProfileData,
    onError: (error) => {
      if (error.response && error.response.status === 401) {
        setUserIsLoginToken(null);
        localStorage.removeItem("token");
        logOut();
      }
    }
  });

  return { data, isLoading, isError };
};

export default UseFetchProfile;
