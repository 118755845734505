import { useContext, useState } from "react";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import SelectCountries from "Components/Ui/SelectCountries/SelectCountries";
import axios from "axios";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import InputField from "Components/Forms/InputField";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { UserAuthContext } from "Context/UserAuthProvider/UserAuthProvider";
import BASE_URL from "config";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import DateIcon from "Assets/Icons/DateIcon";

const RegisterForm = ({ buttonLogin, hideModalForm }) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "الاسم الأول قصير جداً")
      .max(50, "الاسم الأول طويل جداً")
      .required("الاسم الأول مطلوب"),
    password: Yup.string()
      .min(8, "كلمة المرور قصيرة جداً")
      .max(30, "كلمة المرور طويلة جداً")
      .required("كلمة المرور مطلوبة"),
    email: Yup.string()
      .email("بريد إلكتروني غير صالح")
      .required("البريد الإلكتروني مطلوب"),
    phone: Yup.string()
      .matches(/^\d{10,11}$/, "رقم الهاتف يجب أن يكون بين 10 و 11 أرقام")
      .required("رقم الهاتف مطلوب"),
    birthday: Yup.date().required("تاريخ الميلاد مطلوب")
  });

  const [selectedCountry, setSelectedCountry] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [error, setError] = useState(null);
  const [loaderSubmit, setLoaderSubmit] = useState(false);
  const [disabledValue, setDisabledValue] = useState(false);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    password: "",
    birthday: "",
    address: ""
  };

  const removeDisabled = () => {
    setDisabledValue(false);
  };

  // TO GET TOKEN IF REGISTER SUCCESS GO TO ADD USER
  let { setUserIsLoginToken } = useContext(UserAuthContext);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoaderSubmit(true);
      setDisabledValue(true);
      const formData = {
        ...values,
        // ADD BIRTH DATE
        birthday: values.birthday
          ? values.birthday.toISOString().split("T")[0]
          : "",
        // ADD COUNTRY ID
        country_id: selectedCountry
      };
     

      // API LINK
      let res = await axios.post(`${BASE_URL}/register`, formData);

  
      // RESET FORM
      resetForm();

      if (res.data && res.data.access_token) {
        toast.success("تم التسجيل بنجاح.");
        localStorage.setItem("token", res.data.access_token);
        hideModalForm();
        setUserIsLoginToken(res.data.access_token);
        setLoaderSubmit(false);
        removeDisabled();
      }
      if (formData) {
        hideModalForm();
        setLoaderSubmit(false);
        removeDisabled();
      }
    } catch (err) {
      setLoaderSubmit(false);
      removeDisabled();
      if (
        err.response.data.message ===
          "قيمة البريد الالكتروني مُستخدمة من قبل" ||
        err.response.data.message ===
          "قيمة البريد الالكتروني مُستخدمة من قبل (and 1 more error)"
      ) {
        setError("هذه البيانات موجودة بالفعل.");
        toast.error(error);
      }
      toast.error(err.response.data.message);
    }
  };

  // SHOW PASSWORD INPUT
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <>
      <div className="all-form-auth">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="row gx-3">
                <div className="col-12 col-sm-6">
                  <InputField
                    label="إسم المستخدم"
                    name="name"
                    type="text"
                    placeholder="إسم المستخدم"
                    success
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <div className="input-pass position-relative">
                    <InputField
                      label="كلمة المرور"
                      name="password"
                      placeholder="كلمة المرور"
                      success
                      value={password}
                      onChange={handlePasswordChange}
                      type={showPassword ? "text" : "password"}
                    />

                    <button
                      className="icon-eye-button"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} />
                      )}
                    </button>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <InputField
                    label="البريد الإلكتروني"
                    name="email"
                    type="email"
                    placeholder="البريد الإلكتروني"
                    success
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <InputField
                    label="رقم الهاتف"
                    name="phone"
                    type="tel"
                    placeholder="رقم الهاتف"
                    success
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <div className="input-date-content__1 position-relative mt-3">
                    <label htmlFor="birthday" className="form-label m-0">
                      تاريخ الميلاد
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          name="birthday"
                          className="input-date-1"
                          value={startDate}
                          onChange={(date) => {
                            setStartDate(date);
                            setFieldValue("birthday", date);
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <div className="icon-date-add">
                    <DateIcon />
                  </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="form-select-country mt-3">
                    <label htmlFor="countrySelect" className="form-label">
                      الدولة
                    </label>
                    <SelectCountries
                      name={"country_id"}
                      selectedCountry={selectedCountry}
                      change={handleCountryChange}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12">
                  <InputField
                    label="عنوان السكن"
                    name="address"
                    type="text"
                    placeholder="عنوان السكن"
                    success
                  />
                </div>
              </div>
              <button
                type="submit"
                className={`btn-main btn-height btn-submit w-100 mt-3 ${
                  disabledValue ? "bttDisabled" : ""
                }`}
                disabled={disabledValue ? "disabled" : ""}
              >
                {loaderSubmit ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "إنشاء حساب"
                )}
              </button>
            </Form>
          )}
        </Formik>
        <div className="bottom-info-not-accout gap-2 d-flex justify-content-center align-items-center">
          لديك حساب بالفعل ؟
          <div
            onClick={buttonLogin}
            className="cursor-pointer-event text-decoration-underline link-a"
          >
            تسجيل الدخول
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterForm;
