import React from "react";
import "./CardTradingRecommendation.css";
import EyeIcon from "Assets/Icons/EyeIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const CardTradingRecommendation = ({
  imageSrc,
  isTrueEye,
  title,
  times,
  valueopening,
  valueClosing,
  valueTarget,
  isTrueCardContent,
  textContentCard
}) => {
  return (
    <>
      {/* ========== START CARD TRADING RECOMMENDAION ============ */}
      <div className="card-trading-recommendation h-100">
        {/* ================ START HEADER CARD ================== */}
        <div className="header-card d-flex align-items-center gap-2">
          {/* ========= START IMAGE TRADE ========= */}
          <div className="image-trade">
            <img
              src={imageSrc}
              alt="imageCard"
              width={"73.98px"}
              height={"50px"}
              className=" object-fit-cover"
            />
          </div>
          {/* ========= END IMAGE TRADE ========= */}
          {/* ========= START CONTENT INFO ======== */}
          <div className="content-info-header">
            <div className="main-head d-flex justify-content-between align-items-center gap-3">
              <h2 className="num-head">{title}</h2>
              {isTrueEye && (
                <div className="icon-eye cursor-pointer-event">
                  <EyeIcon />
                </div>
              )}
            </div>
            <div className="time-add">{times}</div>
          </div>
          {/* ========= END CONTENT INFO ======== */}
        </div>
        {/* ================ END HEADER CARD ================== */}
        {/* ================ START CONTENT INFO CARD ========== */}
        <div className="content-info-card flex-wrap d-flex align-items-center justify-content-between text-center gap-3">
          {/* =============== START INFO CONTENT ================ */}
          <div className="info-one-content">
            <h2 className="text-content">سعر الإفتتاح</h2>
            <p className="num-content">{valueopening}</p>
          </div>
          {/* =============== END INFO CONTENT ================ */}
          {/* =============== START INFO CONTENT ================ */}
          <div className="info-one-content">
            <h2 className="text-content">سعر إيقاف الخسارة</h2>
            <p className="num-content">{valueClosing}</p>
          </div>
          {/* =============== END INFO CONTENT ================ */}
          {/* =============== START INFO CONTENT ================ */}
          <div className="info-one-content">
            <h2 className="text-content">الهدف</h2>
            <p className="num-content">
              <div className="icon-check-card">
                <FontAwesomeIcon icon={faCheck} />
              </div>{" "}
              {valueTarget}
            </p>
          </div>
          {/* =============== END INFO CONTENT ================ */}
        </div>
        {/* ================ END CONTENT INFO CARD ========== */}
        {isTrueCardContent && (
          <>
            {" "}
            {/* ============== START CONTENT CARD RECOMMEND ============== */}
            <div className="content-card-recommend">
              <p className="text">{textContentCard}</p>
            </div>
            {/* ============== END CONTENT CARD RECOMMEND ============== */}
          </>
        )}
      </div>
      {/* ========== END CARD TRADING RECOMMENDAION ============ */}
    </>
  );
};

export default CardTradingRecommendation;
