import React, { createContext, useContext, useState, useEffect } from 'react';

const UserImageContext = createContext();

export const UserImageContextProvider = ({ children }) => {
  const [userImage, setUserImage] = useState(null);
 

  return (
    <UserImageContext.Provider value={{ userImage, setUserImage }}>
      {children}
    </UserImageContext.Provider>
  );
};

export const useUserImage = () => useContext(UserImageContext);
