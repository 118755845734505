import  { useEffect, useState } from 'react'

const UseImageLoading = (data, isLoading) => {
    const [cardLoadingStates, setCardLoadingStates] = useState({});
    useEffect(() => {
      if (isLoading) {
        const loadingStates = {};
        data?.data.data.forEach((item) => {
          loadingStates[item.id] = true;
        });
        setCardLoadingStates(loadingStates);
      }
    }, [isLoading, data]);
  
    const handleImageLoad = (id) => {
      setCardLoadingStates((prevState) => ({
        ...prevState,
        [id]: false
      }));
    };
  
    return { cardLoadingStates, handleImageLoad };
}

export default UseImageLoading