import ContainerMedia from "../../../../Components/ContainerMedia/ContainerMedia";

import imageContent from "../../../../Assets/images/main/01.png";
import imageTrading1 from "../../../../Assets/images/main/02.svg";
import imageTrading2 from "../../../../Assets/images/main/03.png";

import "./HeaderHome.css";
import AppsInfo from "Components/Ui/AppsInfo/AppsInfo";
const HeaderHome = () => {
  return (
    <div className="header-home position-relative">
      <div className="shape-content shape-content--1" />
      {/* ============ START  CONTAINER ============= */}
      <ContainerMedia>
        {/* =========== START ALL HEADER CONTENT ============ */}
        <div className="all-header-content">
          {/* =========== START ROW ============ */}
          <div className="row flex-wrap-reverse  g-3 align-items-center">
            {/* ============ START COL ============== */}
            <div className="col-12 col-md-6">
              {/* =========== START CONTENT INFO HEADER =========== */}
              <div className="content-info-header" data-aos="fade-left">
                <h1 className="title">
                  احصل على تجربة تداول استثنائية مع تطبيقنا لأخبار السلع والأسهم
                  العالمية
                </h1>
                <p className="text">
                  إكتشف عالم التداول بسهولة وثقة من خلال تطبيقنا الذي يقدم لك
                  آخر الأخبار المالية وفرص التداول المميزة. قم بتنزيل التطبيق
                  الآن واملأ الاستمارة لطلب إدارة محفظتك المالية، حيث ستتواصل
                  معك إدارتنا المالية لتقديم الدعم وإدارة أموالك بحرفية.
                </p>
                <AppsInfo />
              </div>
              {/* =========== END CONTENT INFO HEADER =========== */}
            </div>
            {/* ============ END COL ============== */}
            {/* ============ START COL ============== */}
            <div className="col-12  col-md-6">
              {/* ============ START IMAGE CONTENT HEADER ============== */}
              <div
                className="image-content-header position-relative"
                data-aos="fade-right"
              >
                <div className="image-content--1 position-relative">
                  <img
                    src={imageContent}
                    alt="img-content"
                    loading="lazy"
                    className="object-fit-cover w-100 h-100"
                  />

                  <div className="shape-content" />
                </div>
                <div className="image-trading">
                  <img
                    src={imageTrading1}
                    alt="imageTrading"
                    className="object-fit-cover"
                    width={"107.84px"}
                    height={"118.73px"}
                  />
                </div>
                <div className="image-trading-2">
                  <img
                    src={imageTrading2}
                    alt="imageTrading"
                    className="object-fit-cover"
                  />
                </div>
              </div>
              {/* ============ END IMAGE CONTENT HEADER ============== */}
            </div>
            {/* ============ END COL ============== */}
          </div>
          {/* =========== END ROW ============ */}
        </div>
        {/* =========== END ALL HEADER CONTENT ============ */}
      </ContainerMedia>
      {/* ============ END  CONTAINER ============= */}
    </div>
  );
};

export default HeaderHome;
