import { createContext, useState } from "react";

export let UserAuthContext = createContext();

const UserAuthProvider = (props) => {
  const [userIsLoginToken, setUserIsLoginToken] = useState(null);
  return (
    <UserAuthContext.Provider value={{ userIsLoginToken, setUserIsLoginToken }}>
      {props.children}
    </UserAuthContext.Provider>
  );
};

export default UserAuthProvider;
