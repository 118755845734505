import React from "react";
import HeaderHome from "./Components/HeaderHome/HeaderHome";
import HelmetInfo from "../../Components/HelmetInfo/HelmetInfo";
import MainCards from "./Components/MainCards/MainCards";
import BannerHome from "./Components/BannerHome/BannerHome";
import TradingRecommendations from "./Components/TradingRecommendations/TradingRecommendations";
import NewsCards from "./Components/NewsCards/NewsCards";
import Companys from "./Components/Companys/Companys";
import BlogsCards from "./Components/BlogsCards/BlogsCards";
import FaqHome from "./Components/FaqHome/FaqHome";
import FinalBanner from "./Components/FinalBanner/FinalBanner";

const Home = () => {
  return (
    <>
      <HelmetInfo titlePage={"الصفحة الرئيسية"} />
      <header>
        <HeaderHome />
      </header>
      <main>
        <MainCards />
        <BannerHome />
        <TradingRecommendations />
        <NewsCards />
        <Companys />
        <BlogsCards />
        <FaqHome />
        <FinalBanner />
      </main>
    </>
  );
};

export default Home;
