import Lottie from "lottie-react";
import notFoundPage from "./NotFoundDataImage.json";
import "./NotFoundDataPage.css";
const NotFoundDataPage = () => {
  return (
    <div className="data-not-found-page">
      <div className="image-anim">
        <Lottie animationData={notFoundPage} />
      </div>
      <div className="text-error-content">
        <h2 className="error-text"> لا توجد بيانات حاليا</h2>
      </div>
    </div>
  );
};

export default NotFoundDataPage;
