import { useState, useEffect } from "react";

const usePagination = (dataArray, initialPerPage, itemsCardsNumberStart) => {
  const [currentPage, setCurrentPage] = useState(0); // CURRANT PAGE ITEMS START FORM "0"
  const [perPage, setPerPage] = useState(initialPerPage); // INITIAL PAGE ITEMS ADD I ADD IN PAGE "12" ITEMS CARDS

  //   THIS FUNCTION FOR SCREENS WEBISTE I USE FROM MOBILE SCREEN TO LARGE SCREEN
  const updatePerPage = () => {
    if (window.innerWidth < 600) {
      setPerPage(6);
    } else if (window.innerWidth < 900) {
      setPerPage(8);
    } else {
      setPerPage(initialPerPage);
    }
  };

  //   THIS FOR USE IN CREEN SIZES
  useEffect(() => {
    updatePerPage();
    window.addEventListener("resize", updatePerPage);

    return () => {
      window.removeEventListener("resize", updatePerPage);
    };
  }, []);

  // GET  TOTAL OF UNMBER PAGES
  const totalPages = Math.ceil(dataArray?.length / perPage);
  // HERE I ADD NUMBER "3" TO GET CARDS FROM ITEM 3 TO END BECAUSE I USE FROM "0-3" IN HEADER
  const offset = itemsCardsNumberStart + currentPage * perPage;
  const currentPageData = dataArray?.slice(offset, offset + perPage);

  return {
    setCurrentPage,
    totalPages,
    currentPageData
  };
};

export default usePagination;
