const ImageBanner = () => {
  return (
    <>
      {" "}
      <svg
        width={362}
        height={327}
        viewBox="0 0 362 327"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="g-top" style={{ mixBlendMode: "soft-light" }}>
          <path
            d="M181 326.596C280.964 326.596 362 282.19 362 227.413C362 172.636 280.964 128.23 181 128.23C81.0365 128.23 0 172.636 0 227.413C0 282.19 81.0365 326.596 181 326.596Z"
            fill="#e4e4e750"
          />
        </g>
        <path
          d="M266.571 228.922L317.351 199.711C317.544 199.645 317.711 199.521 317.83 199.356C317.948 199.191 318.012 198.993 318.012 198.79C318.012 198.587 317.948 198.389 317.83 198.224C317.711 198.059 317.544 197.934 317.351 197.869L290.496 182.402C290 182.151 289.452 182.02 288.895 182.02C288.339 182.02 287.791 182.151 287.295 182.402L236.582 211.665C236.389 211.731 236.222 211.855 236.104 212.02C235.985 212.186 235.921 212.383 235.921 212.587C235.921 212.79 235.985 212.988 236.104 213.153C236.222 213.318 236.389 213.442 236.582 213.508L263.377 228.922C263.872 229.173 264.419 229.304 264.974 229.304C265.529 229.304 266.076 229.173 266.571 228.922Z"
          fill="#E0E0E0"
        />
        <path
          d="M96.992 227.975L67.1985 245.12C66.704 245.371 66.1568 245.502 65.6018 245.502C65.0467 245.502 64.4996 245.371 64.005 245.12L28.2153 224.522C28.0217 224.458 27.8531 224.335 27.7336 224.171C27.6141 224.006 27.5498 223.808 27.5498 223.605C27.5498 223.401 27.6141 223.203 27.7336 223.038C27.8531 222.874 28.0217 222.751 28.2153 222.687L58.0013 205.542C58.4971 205.291 59.0455 205.16 59.6018 205.16C60.158 205.16 60.7064 205.291 61.2023 205.542L96.992 226.14C97.1857 226.204 97.3542 226.327 97.4737 226.491C97.5932 226.656 97.6575 226.854 97.6575 227.057C97.6575 227.261 97.5932 227.459 97.4737 227.624C97.3542 227.788 97.1857 227.911 96.992 227.975Z"
          fill="#E0E0E0"
        />
        <path
          d="M148.028 198.643L118.242 215.787C117.745 216.034 117.197 216.163 116.641 216.163C116.086 216.163 115.538 216.034 115.041 215.787L79.2588 195.19C79.066 195.124 78.8987 195 78.7803 194.835C78.6618 194.669 78.5981 194.471 78.5981 194.268C78.5981 194.065 78.6618 193.867 78.7803 193.702C78.8987 193.537 79.066 193.413 79.2588 193.347L109.045 176.202C109.539 175.951 110.086 175.82 110.641 175.82C111.197 175.82 111.744 175.951 112.238 176.202L148.028 196.8C148.221 196.866 148.388 196.99 148.506 197.155C148.625 197.32 148.689 197.518 148.689 197.721C148.689 197.924 148.625 198.122 148.506 198.287C148.388 198.453 148.221 198.577 148.028 198.643Z"
          fill="#E0E0E0"
        />
        <path
          d="M198.974 169.278L169.188 186.423C168.692 186.674 168.144 186.805 167.587 186.805C167.031 186.805 166.483 186.674 165.987 186.423L130.197 165.833C130.005 165.767 129.837 165.643 129.719 165.478C129.6 165.312 129.537 165.114 129.537 164.911C129.537 164.708 129.6 164.51 129.719 164.345C129.837 164.18 130.005 164.056 130.197 163.99L159.991 146.808C160.485 146.557 161.032 146.426 161.587 146.426C162.142 146.426 162.69 146.557 163.184 146.808L198.974 167.405C199.183 167.459 199.368 167.579 199.5 167.749C199.632 167.918 199.704 168.127 199.704 168.342C199.704 168.556 199.632 168.765 199.5 168.935C199.368 169.104 199.183 169.225 198.974 169.278Z"
          fill="#E0E0E0"
        />
        <path
          d="M249.89 139.994L220.096 157.131C219.602 157.383 219.054 157.514 218.499 157.514C217.944 157.514 217.397 157.383 216.903 157.131L181.09 136.541C180.898 136.476 180.73 136.351 180.612 136.186C180.493 136.021 180.43 135.823 180.43 135.62C180.43 135.417 180.493 135.219 180.612 135.054C180.73 134.889 180.898 134.764 181.09 134.699L210.876 117.554C211.372 117.303 211.92 117.172 212.477 117.172C213.033 117.172 213.581 117.303 214.077 117.554L249.867 138.152C250.061 138.215 250.229 138.337 250.35 138.501C250.47 138.665 250.537 138.862 250.539 139.065C250.542 139.268 250.48 139.467 250.364 139.633C250.247 139.8 250.082 139.926 249.89 139.994Z"
          fill="#E0E0E0"
        />
        <path
          d="M95.2563 259.658L179.031 307.849C179.526 308.1 180.073 308.231 180.628 308.231C181.183 308.231 181.73 308.1 182.225 307.849L224.011 283.799C224.204 283.735 224.373 283.612 224.492 283.447C224.612 283.283 224.676 283.084 224.676 282.881C224.676 282.678 224.612 282.48 224.492 282.315C224.373 282.15 224.204 282.027 224.011 281.964L140.243 233.773C139.747 233.522 139.199 233.391 138.643 233.391C138.087 233.391 137.538 233.522 137.042 233.773L95.2563 257.823C95.0627 257.887 94.8941 258.01 94.7746 258.175C94.6551 258.339 94.5908 258.538 94.5908 258.741C94.5908 258.944 94.6551 259.142 94.7746 259.307C94.8941 259.472 95.0627 259.595 95.2563 259.658Z"
          fill="#E0E0E0"
        />
        <path
          d="M220.118 223.428C211.191 218.29 196.727 218.29 187.807 223.428C178.888 228.566 178.881 236.91 187.807 242.018C196.734 247.126 211.191 247.156 220.118 242.018C229.045 236.88 229.037 228.558 220.118 223.428Z"
          fill="#E0E0E0"
        />
        <path
          d="M253.594 238.881C244.675 233.743 230.21 233.743 221.284 238.881C212.357 244.019 212.364 252.363 221.284 257.479C230.203 262.594 244.675 262.609 253.594 257.479C262.514 252.348 262.521 244.019 253.594 238.881Z"
          fill="#E0E0E0"
        />
        <path
          d="M331.087 196.936L325.722 193.827C325.722 193.827 323.873 182.173 325.722 172.106C328.307 157.95 338.12 153.269 341.614 154.767C345.108 156.265 345.837 160.489 339.277 168.548C335.4 173.305 331.275 183.746 331.087 196.936Z"
          fill="#37474F"
        />
        <path
          d="M328.66 190.946H328.705C328.747 190.943 328.788 190.931 328.825 190.911C328.862 190.891 328.895 190.863 328.921 190.83C328.947 190.797 328.966 190.76 328.976 190.719C328.987 190.678 328.989 190.636 328.983 190.594C327.48 177.255 333.153 162.806 339.315 157.069C339.374 157.009 339.407 156.928 339.407 156.844C339.407 156.76 339.374 156.68 339.315 156.62C339.254 156.563 339.173 156.531 339.09 156.531C339.006 156.531 338.925 156.563 338.864 156.62C332.59 162.462 326.774 177.142 328.344 190.684C328.358 190.758 328.397 190.824 328.455 190.872C328.513 190.92 328.585 190.946 328.66 190.946Z"
          fill="white"
        />
        <path
          d="M342.989 203.813C343.68 204.233 345.243 203.491 345.769 203.132C346.725 202.502 347.501 201.638 348.024 200.622C348.625 199.471 348.853 198.163 348.677 196.877C348.577 196.194 348.431 195.519 348.241 194.855L347.723 192.9C347.514 192.263 347.432 191.591 347.483 190.923C347.685 189.597 348.895 188.676 349.992 187.927C351.202 187.065 352.472 185.92 352.502 184.309C352.446 183.064 351.975 181.873 351.164 180.924C350.413 179.935 349.526 179.051 348.85 178.01C348.323 177.282 348.058 176.398 348.099 175.501C348.188 174.952 348.387 174.428 348.685 173.958C349.356 172.976 349.951 171.944 350.466 170.872C350.711 170.329 350.797 169.728 350.715 169.139C350.632 168.549 350.384 167.994 350 167.539C349.613 167.237 349.17 167.016 348.697 166.887C348.223 166.758 347.729 166.725 347.242 166.79C345.544 166.857 343.898 168.056 342.538 168.992C341.178 169.928 340.141 171.239 338.593 171.748C336.565 172.497 333.867 172.048 332.522 174.108C331.35 175.898 332.199 177.898 332.312 179.8C332.499 182.856 329.614 183.995 328.201 186.212C327.767 186.94 327.58 187.788 327.668 188.631C327.718 190.057 328.034 191.462 328.6 192.773C329.272 194.188 330.024 195.563 330.854 196.892C330.951 196.907 342.884 203.738 342.989 203.813Z"
          fill="#455A64"
        />
        <path
          d="M335.881 199.901C335.923 199.902 335.966 199.895 336.005 199.879C336.044 199.863 336.08 199.839 336.109 199.809C336.139 199.779 336.162 199.743 336.177 199.703C336.192 199.664 336.199 199.621 336.197 199.579C335.964 186.981 342.208 173.222 346.656 169.282C346.687 169.255 346.713 169.221 346.731 169.184C346.749 169.147 346.76 169.106 346.762 169.065C346.764 169.023 346.759 168.982 346.745 168.943C346.731 168.904 346.71 168.867 346.683 168.836C346.655 168.805 346.621 168.78 346.584 168.762C346.547 168.744 346.506 168.733 346.464 168.731C346.423 168.729 346.381 168.734 346.342 168.748C346.303 168.761 346.267 168.783 346.235 168.81C341.727 172.825 335.325 186.786 335.558 199.594C335.559 199.635 335.568 199.676 335.585 199.714C335.602 199.752 335.626 199.786 335.656 199.814C335.686 199.843 335.721 199.865 335.76 199.88C335.798 199.895 335.84 199.902 335.881 199.901Z"
          fill="white"
        />
        <path
          d="M338.135 185.079H338.225C341.236 184.328 344.32 183.904 347.422 183.814C347.465 183.814 347.506 183.805 347.545 183.789C347.584 183.773 347.619 183.749 347.648 183.719C347.678 183.689 347.701 183.653 347.716 183.614C347.732 183.575 347.739 183.533 347.738 183.492C347.739 183.45 347.732 183.408 347.716 183.369C347.699 183.33 347.675 183.295 347.645 183.266C347.615 183.237 347.578 183.215 347.539 183.201C347.499 183.187 347.457 183.181 347.415 183.184C344.257 183.271 341.117 183.701 338.052 184.465C337.98 184.488 337.918 184.535 337.877 184.599C337.836 184.663 337.819 184.739 337.828 184.814C337.838 184.89 337.874 184.959 337.931 185.01C337.987 185.061 338.059 185.091 338.135 185.094V185.079Z"
          fill="white"
        />
        <path
          d="M42.2291 204.8C42.2291 204.8 43.266 193.655 39.5992 184.075C35.9323 174.496 29.5753 167.665 22.7225 165.665C15.8696 163.665 8.91151 168.886 15.4413 174.825C21.971 180.765 34.2867 188.008 36.9467 205.669L42.2291 204.8Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.2"
          d="M42.2291 204.8C42.2291 204.8 43.266 193.655 39.5992 184.075C35.9323 174.496 29.5753 167.665 22.7225 165.665C15.8696 163.665 8.91151 168.886 15.4413 174.825C21.971 180.765 34.2867 188.008 36.9467 205.669L42.2291 204.8Z"
          fill="black"
        />
        <path
          d="M41.5455 202.713C41.453 202.711 41.3645 202.675 41.2956 202.614C41.2268 202.552 41.1823 202.468 41.1698 202.376C39.0208 180.453 19.6494 169.42 19.454 169.293C19.3669 169.243 19.3032 169.16 19.2764 169.064C19.2497 168.967 19.2622 168.864 19.3113 168.776C19.3602 168.69 19.4415 168.626 19.5373 168.6C19.633 168.573 19.7355 168.585 19.8222 168.634C20.0176 168.746 39.7346 179.989 41.9212 202.301C41.9278 202.4 41.8966 202.497 41.8338 202.574C41.771 202.65 41.6814 202.7 41.5831 202.713H41.5455Z"
          fill="white"
        />
        <path
          d="M42.0116 225.582C39.8099 226.204 36.2182 226.998 33.8738 226.077C31.5294 225.155 29.4254 223.425 29.0272 220.085C28.9745 218.659 28.706 217.25 28.2307 215.905C27.9123 215.306 27.4717 214.78 26.9373 214.361C26.4028 213.942 25.7863 213.639 25.1274 213.471C23.5419 212.992 21.8512 212.999 20.2432 212.61C18.6352 212.22 16.9821 211.299 16.4861 209.726C16.2028 208.601 16.3473 207.411 16.8919 206.385C17.3653 205.344 18.0416 204.393 18.545 203.389C19.4242 201.569 19.1311 199.847 17.2901 198.895C13.6909 197.06 7.84491 193.817 10.3396 190.656C12.8343 187.496 18.823 188.514 23.0009 190.814C24.3136 191.635 25.7889 192.164 27.3257 192.364C28.8625 192.565 30.4246 192.432 31.9051 191.975C35.6622 190.986 37.4054 192.109 39.2539 196.469C40.0729 198.386 41.17 198.775 42.5526 200.004C44.0554 201.345 43.815 204.498 43.8225 206.475C43.8225 212.512 43.572 218.539 43.0711 224.556C42.9659 225.492 42.7029 224.946 42.0116 225.582Z"
          fill="#42CE8F"
        />
        <path
          d="M43.8673 218.028C42.4962 214.206 40.4894 210.641 37.9312 207.482C37.9015 207.414 37.855 207.355 37.7959 207.31C36.8244 206.111 35.7885 204.966 34.6926 203.88C34.3673 201.056 34.4255 198.202 34.8654 195.393C34.8752 195.345 34.8751 195.295 34.8653 195.246C34.8554 195.197 34.836 195.151 34.808 195.11C34.78 195.069 34.7441 195.033 34.7024 195.006C34.6606 194.979 34.6139 194.961 34.5648 194.952C34.5169 194.942 34.4676 194.942 34.4196 194.951C34.3716 194.96 34.326 194.979 34.2855 195.007C34.2449 195.034 34.2102 195.069 34.1833 195.109C34.1565 195.15 34.138 195.196 34.129 195.244C33.6956 197.827 33.6047 200.456 33.8585 203.063C28.4315 198.025 22.0782 194.08 15.1484 191.446C15.0537 191.418 14.9515 191.426 14.8633 191.471C14.775 191.516 14.7076 191.593 14.675 191.686C14.6438 191.779 14.6508 191.881 14.6944 191.969C14.738 192.058 14.8147 192.125 14.9079 192.158C15.0732 192.21 27.5842 196.532 36.4358 206.853C31.7308 205.471 26.7082 205.605 22.0839 207.235C22.0338 207.25 21.9874 207.276 21.9478 207.31C21.9081 207.344 21.8761 207.386 21.8537 207.433C21.8313 207.481 21.8191 207.532 21.8178 207.584C21.8164 207.636 21.8261 207.688 21.8461 207.736C21.8661 207.785 21.896 207.828 21.9339 207.864C21.9717 207.9 22.0168 207.928 22.066 207.946C22.1153 207.963 22.1678 207.971 22.22 207.967C22.2723 207.963 22.3232 207.949 22.3694 207.924C27.2152 206.268 32.4767 206.268 37.3225 207.924C39.8362 211.025 41.8098 214.524 43.161 218.275C43.185 218.35 43.2322 218.415 43.2957 218.46C43.3592 218.506 43.4357 218.531 43.5141 218.53C43.5539 218.537 43.5946 218.537 43.6344 218.53C43.6835 218.513 43.7286 218.487 43.7672 218.452C43.8057 218.418 43.8369 218.376 43.8586 218.329C43.8804 218.282 43.8924 218.231 43.8939 218.179C43.8954 218.128 43.8863 218.076 43.8673 218.028Z"
          fill="white"
        />
        <path
          d="M110.382 29.7184L105.521 32.5197C105.55 32.3062 105.566 32.091 105.566 31.8755V30.4749C105.566 27.9732 103.808 26.9621 101.636 28.2279C100.703 28.7991 99.9054 29.5658 99.2991 30.4749C98.9384 27.3291 96.4738 26.1606 93.4681 27.8908C91.7578 29.0018 90.3345 30.4991 89.3135 32.2612C88.2925 34.0233 87.7028 36.0005 87.5921 38.0323V40.1295C87.5697 40.9687 87.7419 41.8018 88.0955 42.5638L84.0604 44.8857C83.4275 45.2953 82.9004 45.8479 82.5218 46.4985C82.1432 47.1492 81.9238 47.8797 81.8813 48.6307C81.8813 50.0163 82.8507 50.5781 84.0604 49.889L110.36 34.7367C110.991 34.3257 111.518 33.7729 111.896 33.1225C112.275 32.4721 112.495 31.7423 112.539 30.9917C112.561 29.5911 111.585 29.0293 110.382 29.7184Z"
          fill="#EBEBEB"
        />
        <path
          d="M78.2667 16.8618L75.5917 17.8879V11.9933C75.5917 7.32703 71.8346 6.21102 67.8071 8.54042C63.7495 10.8698 60.4658 16.5472 60.4658 21.206V22.0674C59.8962 21.8909 59.2938 21.8457 58.7042 21.9352C58.1145 22.0248 57.5529 22.2467 57.0619 22.5842C55.528 23.5863 54.2525 24.9344 53.3387 26.5193C52.4249 28.1043 51.8985 29.8815 51.802 31.707V32.089L48.045 34.2461C47.0327 34.9013 46.19 35.7855 45.5854 36.8268C44.9809 37.868 44.6316 39.0369 44.5659 40.2381C44.5659 42.4477 46.1213 43.3465 48.045 42.238L78.2667 24.8462C79.2773 24.1892 80.1187 23.3046 80.7231 22.2637C81.3274 21.2228 81.6778 20.0549 81.7458 18.8542C81.7458 16.6521 80.1903 15.7383 78.2667 16.8618Z"
          fill="#EBEBEB"
        />
        <path
          d="M225.003 226.766C223.782 224.856 222.086 223.293 220.081 222.227C212.026 217.591 198.974 217.591 190.926 222.227C188.921 223.293 187.226 224.856 186.005 226.766H184.893V230.961C185.043 233.882 187.049 236.773 190.904 239.005C198.951 243.634 212.003 243.634 220.059 239.005C223.928 236.758 225.95 233.882 226.07 230.961V226.766H225.003Z"
          fill="#42CE8F"
        />
        <g opacity="0.25">
          <path
            d="M225.003 226.762C223.782 224.852 222.086 223.289 220.081 222.223C212.026 217.587 198.974 217.587 190.926 222.223C188.921 223.289 187.226 224.852 186.005 226.762H184.893V230.957C185.043 233.878 187.049 236.769 190.904 239.001C198.951 243.63 212.003 243.63 220.059 239.001C223.928 236.754 225.95 233.878 226.07 230.957V226.762H225.003Z"
            fill="black"
          />
        </g>
        <path
          opacity="0.1"
          d="M205.504 218.754C200.244 218.754 194.984 219.907 190.926 222.229C188.921 223.295 187.226 224.858 186.005 226.768H184.893V230.963C185.043 233.884 187.049 236.775 190.904 239.007C194.931 241.321 200.206 242.475 205.481 242.475L205.504 218.754Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M194.248 220.676C193.099 221.098 191.988 221.617 190.926 222.226C188.921 223.292 187.226 224.855 186.005 226.765H184.893V230.96C185.043 233.881 187.049 236.772 190.904 239.004C191.967 239.608 193.078 240.124 194.225 240.547L194.248 220.676Z"
          fill="black"
        />
        <path
          opacity="0.5"
          d="M216.76 220.676C217.908 221.1 219.019 221.618 220.081 222.226C222.086 223.292 223.781 224.855 225.003 226.765H226.115V230.96C225.972 233.881 223.951 236.772 220.104 239.004C219.04 239.606 217.929 240.122 216.782 240.547L216.76 220.676Z"
          fill="#42CE8F"
        />
        <path
          d="M220.081 218.38C212.025 213.743 198.973 213.743 190.926 218.38C182.878 223.016 182.878 230.521 190.926 235.157C198.973 239.794 212.025 239.786 220.081 235.157C228.136 230.528 228.128 223.008 220.081 218.38Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.2"
          d="M220.081 218.38C212.025 213.743 198.973 213.743 190.926 218.38C182.878 223.016 182.878 230.521 190.926 235.157C198.973 239.794 212.025 239.786 220.081 235.157C228.136 230.528 228.128 223.008 220.081 218.38Z"
          fill="black"
        />
        <path
          d="M214.994 221.448C212.009 220.115 208.775 219.426 205.504 219.426C202.233 219.426 198.999 220.115 196.013 221.448C193.316 222.826 191.828 224.714 191.828 226.766C191.828 228.818 193.331 230.706 196.013 232.084C198.999 233.417 202.233 234.106 205.504 234.106C208.775 234.106 212.009 233.417 214.994 232.084C217.692 230.706 219.18 228.818 219.18 226.766C219.18 224.714 217.692 222.826 214.994 221.448Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.4"
          d="M214.994 221.448C212.009 220.115 208.775 219.426 205.504 219.426C202.233 219.426 198.999 220.115 196.013 221.448C193.316 222.826 191.828 224.714 191.828 226.766C191.828 228.818 193.331 230.706 196.013 232.084C198.999 233.417 202.233 234.106 205.504 234.106C208.775 234.106 212.009 233.417 214.994 232.084C217.692 230.706 219.18 228.818 219.18 226.766C219.18 224.714 217.692 222.826 214.994 221.448Z"
          fill="black"
        />
        <path
          d="M219.63 219.148C215.873 216.991 210.854 215.793 205.504 215.793C200.154 215.793 195.134 216.991 191.377 219.148C187.77 221.223 185.787 223.927 185.787 226.766C185.787 229.605 187.77 232.308 191.377 234.383C195.134 236.54 200.154 237.731 205.504 237.731C210.854 237.731 215.873 236.54 219.63 234.383C223.237 232.308 225.221 229.605 225.221 226.766C225.221 223.927 223.237 221.223 219.63 219.148ZM214.994 232.084C212.009 233.417 208.775 234.106 205.504 234.106C202.233 234.106 198.998 233.417 196.013 232.084C193.316 230.706 191.828 228.818 191.828 226.766C191.828 224.714 193.331 222.826 196.013 221.448C198.998 220.115 202.233 219.426 205.504 219.426C208.775 219.426 212.009 220.115 214.994 221.448C217.692 222.826 219.179 224.714 219.179 226.766C219.179 228.818 217.692 230.698 214.994 232.084Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.2"
          d="M205.503 233.394C212.662 233.394 218.465 230.426 218.465 226.765C218.465 223.104 212.662 220.137 205.503 220.137C198.345 220.137 192.542 223.104 192.542 226.765C192.542 230.426 198.345 233.394 205.503 233.394Z"
          fill="black"
        />
        <path
          d="M205.503 233.394C212.662 233.394 218.465 230.426 218.465 226.765C218.465 223.104 212.662 220.137 205.503 220.137C198.345 220.137 192.542 223.104 192.542 226.765C192.542 230.426 198.345 233.394 205.503 233.394Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.6"
          d="M205.503 233.394C212.662 233.394 218.465 230.426 218.465 226.765C218.465 223.104 212.662 220.137 205.503 220.137C198.345 220.137 192.542 223.104 192.542 226.765C192.542 230.426 198.345 233.394 205.503 233.394Z"
          fill="black"
        />
        <path
          d="M214.671 223.834C209.606 221.243 201.401 221.243 196.336 223.834C194.24 224.905 193.015 226.254 192.654 227.647C193.015 229.04 194.24 230.388 196.336 231.459C201.401 234.051 209.606 234.051 214.671 231.459C216.767 230.388 217.992 229.04 218.353 227.647C217.992 226.254 216.767 224.905 214.671 223.834Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.5"
          d="M214.671 223.834C209.606 221.243 201.401 221.243 196.336 223.834C194.24 224.905 193.015 226.254 192.654 227.647C193.015 229.04 194.24 230.388 196.336 231.459C201.401 234.051 209.606 234.051 214.671 231.459C216.767 230.388 217.992 229.04 218.353 227.647C217.992 226.254 216.767 224.905 214.671 223.834Z"
          fill="black"
        />
        <path
          d="M206.728 222.176V224.071L206.082 224.752L204.88 226.011V224.033L206.728 222.176Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.3"
          d="M206.728 222.176V224.071L206.082 224.752L204.88 226.011V224.033L206.728 222.176Z"
          fill="black"
        />
        <path
          d="M204.879 224.034V225.15C204.283 225.381 203.667 225.557 203.038 225.675C202.776 225.728 202.505 225.728 202.242 225.675C202.113 225.634 201.987 225.584 201.866 225.525C201.408 225.248 201.34 224.843 202.212 224.341C202.603 224.091 203.047 223.933 203.51 223.879C203.972 223.826 204.441 223.879 204.879 224.034Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.45"
          d="M204.879 224.034V225.15C204.283 225.381 203.667 225.557 203.038 225.675C202.776 225.728 202.505 225.728 202.242 225.675C202.113 225.634 201.987 225.584 201.866 225.525C201.408 225.248 201.34 224.843 202.212 224.341C202.603 224.091 203.047 223.933 203.51 223.879C203.972 223.826 204.441 223.879 204.879 224.034Z"
          fill="black"
        />
        <path
          d="M213.491 229.418V231.051L211.409 232.249L209.869 231.358C207.998 232.226 205.86 232.323 203.918 231.627V229.987L204.857 229.058L203.918 229.313C203.147 229.535 202.34 229.603 201.543 229.513C200.746 229.423 199.975 229.177 199.274 228.789C198.342 228.249 197.914 227.665 197.914 227.058V225.456L198.079 224.759L197.516 224.429V222.789L199.056 223.68C198.744 223.866 198.478 224.12 198.28 224.424C198.082 224.728 197.957 225.073 197.914 225.433C197.914 226.017 198.365 226.586 199.274 227.111C201.295 228.279 203.302 227.897 205.135 227.306C206.848 226.751 208.088 226.1 209.072 226.661C209.681 227.021 209.501 227.41 208.809 227.837C208.365 228.082 207.871 228.225 207.364 228.255C206.857 228.284 206.349 228.201 205.879 228.01L203.918 229.95C204.268 230.088 204.63 230.195 205 230.272C206.644 230.582 208.345 230.378 209.869 229.687L211.409 230.571L213.491 229.418Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.35"
          d="M197.914 225.458C197.914 226.043 198.365 226.612 199.274 227.136C201.295 228.304 203.302 227.922 205.135 227.331C206.848 226.777 208.088 226.125 209.073 226.687C209.681 227.046 209.501 227.436 208.81 227.863C208.365 228.107 207.871 228.25 207.364 228.28C206.857 228.31 206.349 228.226 205.879 228.035L203.918 229.975C204.269 230.113 204.631 230.221 205 230.297C206.645 230.607 208.345 230.403 209.869 229.713L211.409 230.596L213.491 229.406V231.038L211.409 232.237L209.869 231.345C207.998 232.213 205.86 232.31 203.918 231.615V229.975L204.857 229.046L203.918 229.301C203.147 229.523 202.34 229.591 201.543 229.501C200.746 229.411 199.975 229.165 199.274 228.776C198.342 228.237 197.914 227.653 197.914 227.046V225.458L198.079 224.762L197.914 225.458Z"
          fill="black"
        />
        <path
          opacity="0.45"
          d="M197.516 222.801V224.441L198.079 224.771C198.319 224.343 198.653 223.974 199.056 223.692L197.516 222.801Z"
          fill="black"
        />
        <path
          d="M213.145 228.345C213.144 228.603 213.091 228.858 212.988 229.094L211.951 228.495C212.264 228.352 212.536 228.134 212.742 227.859C212.949 227.584 213.082 227.262 213.13 226.922C213.138 227.401 213.145 228.3 213.145 228.345Z"
          fill="#42CE8F"
        />
        <g opacity="0.3">
          <path
            d="M213.145 228.345C213.144 228.603 213.091 228.858 212.988 229.094L211.951 228.495C212.264 228.352 212.536 228.134 212.742 227.859C212.949 227.584 213.082 227.262 213.13 226.922C213.138 227.401 213.145 228.3 213.145 228.345Z"
            fill="black"
          />
        </g>
        <path
          d="M213.138 229.2L211.951 228.511C212.301 228.353 212.599 228.099 212.81 227.777C213.02 227.456 213.134 227.082 213.138 226.699C213.142 226.644 213.142 226.589 213.138 226.534C213.063 226.18 212.902 225.849 212.671 225.57C212.439 225.291 212.143 225.072 211.808 224.931C210.97 224.44 210.015 224.182 209.043 224.182H208.78H208.532H208.314H208.224C208.172 224.178 208.119 224.178 208.066 224.182C207.612 224.245 207.163 224.338 206.721 224.459C206.511 224.512 206.293 224.572 206.09 224.639L205.218 224.924L204.888 225.036C204.292 225.268 203.676 225.443 203.047 225.56C202.784 225.618 202.513 225.618 202.25 225.56C202.122 225.522 201.998 225.469 201.882 225.403C201.416 225.141 201.349 224.736 202.213 224.227C202.608 223.98 203.054 223.827 203.518 223.779C203.982 223.731 204.45 223.789 204.888 223.95L206.736 222.092C204.902 221.515 202.92 221.624 201.161 222.399L199.62 221.516L197.524 222.722L199.064 223.605C198.75 223.792 198.484 224.049 198.286 224.356C198.087 224.662 197.963 225.011 197.922 225.373C197.922 225.957 198.373 226.534 199.282 227.051C201.311 228.219 203.31 227.837 205.151 227.246C206.849 226.691 208.096 226.04 209.081 226.601C209.689 226.953 209.509 227.35 208.818 227.777C208.373 228.022 207.879 228.165 207.372 228.195C206.865 228.224 206.358 228.141 205.887 227.95L203.918 229.897C204.274 230.027 204.638 230.132 205.008 230.212C205.63 230.339 206.267 230.387 206.902 230.354C207.939 230.28 208.957 230.027 209.907 229.605L211.448 230.496L213.521 229.298L213.138 229.2Z"
          fill="#42CE8F"
        />
        <path
          d="M209.869 229.727V231.359L211.41 232.251V230.61L209.869 229.727Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.45"
          d="M209.869 229.727V231.359L211.41 232.251V230.61L209.869 229.727Z"
          fill="black"
        />
        <path
          d="M197.516 222.807L199.612 221.602L201.152 222.485L199.627 221.849L197.516 222.807Z"
          fill="#F5F5F5"
        />
        <path
          d="M202.205 224.31C202.6 224.064 203.046 223.91 203.51 223.862C203.974 223.814 204.442 223.873 204.88 224.033L206.728 222.176L204.88 223.846C204.431 223.692 203.95 223.654 203.482 223.735C203.014 223.816 202.575 224.014 202.205 224.31Z"
          fill="#F5F5F5"
        />
        <path
          d="M203.911 229.994C204.266 230.125 204.63 230.23 205 230.309C205.623 230.437 206.259 230.484 206.894 230.451C205.977 230.457 205.068 230.273 204.226 229.912L205.879 228.047L203.911 229.994Z"
          fill="#F5F5F5"
        />
        <path
          d="M209.869 229.729L211.41 230.418L213.483 229.422L211.41 230.613L209.869 229.729Z"
          fill="#F5F5F5"
        />
        <path
          d="M191.535 219.465C196.99 216.162 205.504 215.832 205.504 215.832C200.154 215.832 195.134 217.03 191.377 219.188C187.77 221.262 185.787 223.966 185.787 226.805C185.787 226.767 185.982 222.79 191.535 219.465Z"
          fill="#F5F5F5"
        />
        <path
          d="M203.911 238.597C209.704 238.851 215.648 237.705 220.081 235.159C224.687 232.507 226.656 228.912 225.995 225.422C225.995 225.422 226.851 231.062 219.735 234.919C212.62 238.777 203.911 238.597 203.911 238.597Z"
          fill="#F5F5F5"
        />
        <path
          opacity="0.3"
          d="M191.827 226.714C191.831 226.483 191.854 226.253 191.895 226.025C191.909 225.957 191.927 225.889 191.948 225.823C191.984 225.65 192.031 225.48 192.09 225.314C192.09 225.224 192.166 225.141 192.196 225.059C192.226 224.976 192.323 224.767 192.399 224.624C192.474 224.482 192.511 224.445 192.571 224.355C192.652 224.22 192.74 224.09 192.834 223.965C192.902 223.868 192.985 223.778 193.067 223.681C193.15 223.583 193.263 223.456 193.368 223.344C193.473 223.231 193.571 223.141 193.676 223.044L193.999 222.767C194.134 222.655 194.27 222.542 194.412 222.437L194.69 222.243C194.878 222.115 195.066 221.988 195.269 221.868L192.166 218.715L191.414 219.149C189.92 219.965 188.595 221.056 187.507 222.362L191.865 226.707L191.827 226.714Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M207.525 234.032L206.908 234.07H206.773C206.352 234.07 205.924 234.07 205.503 234.07H205.361C204.827 234.07 204.301 234.07 203.775 234.01H203.287C202.731 233.965 202.175 233.905 201.641 233.823C201.085 233.733 200.544 233.62 200.01 233.493L199.65 233.411C199.131 233.276 198.62 233.134 198.147 232.969L202.828 237.635C203.722 237.71 204.632 237.74 205.556 237.74C207.337 237.745 209.117 237.607 210.876 237.328L207.577 234.04L207.525 234.032Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M197.914 225.46C197.914 226.044 198.365 226.621 199.282 227.145C199.908 227.515 200.603 227.752 201.325 227.842L198.139 224.711C197.994 224.934 197.916 225.194 197.914 225.46Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M225.22 226.767C225.22 223.929 223.237 221.232 219.63 219.157C218.003 218.234 216.27 217.512 214.468 217.008L225.153 227.659C225.198 227.364 225.221 227.066 225.22 226.767Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M205.504 219.463C205.789 219.463 206.067 219.463 206.345 219.463H206.849C207.074 219.463 207.285 219.463 207.502 219.508L208.254 219.568L208.802 219.643L209.554 219.747L210.027 219.837L210.839 219.995H210.959L206.744 215.785C206.33 215.785 205.925 215.785 205.511 215.785C204.11 215.782 202.709 215.867 201.318 216.04L204.722 219.433L205.504 219.463Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M218.939 228.143L218.856 228.368C218.78 228.554 218.695 228.737 218.601 228.915L218.488 229.102C218.387 229.271 218.277 229.433 218.157 229.589L218.044 229.739C217.902 229.911 217.751 230.076 217.586 230.24L217.443 230.39C217.256 230.57 217.053 230.742 216.842 230.915L216.707 231.012L216.497 231.177L219.683 234.353C220.86 233.69 221.937 232.866 222.884 231.903L218.991 228.023L218.939 228.143Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M213.146 226.714C213.15 226.659 213.15 226.604 213.146 226.549C213.093 225.98 212.68 225.441 211.816 224.947C211.125 224.552 210.355 224.314 209.562 224.25L212.95 227.628C213.094 227.346 213.162 227.031 213.146 226.714Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M199.876 220.069H199.921L196.787 216.945C195.703 217.241 194.641 217.612 193.609 218.054L196.705 221.132C197.726 220.681 198.788 220.324 199.876 220.069Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M212.74 233.017C212.304 233.159 211.853 233.286 211.387 233.406L211.004 233.496C210.583 233.593 210.155 233.683 209.711 233.758L209.418 233.818L212.604 236.994C213.741 236.755 214.86 236.442 215.956 236.058L212.867 232.949L212.74 233.017Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M205.887 228.047L204.76 229.163L206.045 230.444C206.33 230.462 206.616 230.462 206.901 230.444C207.861 230.382 208.804 230.159 209.689 229.785L208.073 228.174C207.35 228.37 206.582 228.326 205.887 228.047Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M206.691 226.811L204.985 225.118H204.88C204.284 225.349 203.667 225.525 203.039 225.643C202.776 225.696 202.505 225.696 202.242 225.643C202.113 225.602 201.988 225.552 201.867 225.493C201.408 225.216 201.341 224.811 202.212 224.309C202.667 224.034 203.183 223.874 203.715 223.845L202.032 222.175C201.733 222.263 201.44 222.368 201.153 222.489L199.612 221.605L198.064 222.497L203.377 227.792C203.975 227.684 204.565 227.534 205.143 227.343C205.699 227.163 206.217 226.953 206.691 226.811Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M208.141 215.891L212.815 220.557C213.553 220.8 214.271 221.098 214.964 221.448C217.661 222.826 219.149 224.714 219.149 226.766V226.826L223.492 231.148C223.831 230.745 224.125 230.308 224.372 229.844L210.613 216.138C209.832 216.048 209.005 215.951 208.141 215.891Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M191.377 234.38C191.97 234.725 192.582 235.036 193.211 235.309L185.93 228.059C186.433 230.418 188.319 232.628 191.377 234.38Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M185.794 226.576L195.442 236.186C196.427 236.518 197.43 236.79 198.448 237.002L186.14 224.711C185.928 225.311 185.811 225.94 185.794 226.576Z"
          fill="white"
        />
        <g opacity="0.15">
          <path
            d="M187.47 222.369C188.558 221.062 189.884 219.972 191.378 219.156L192.129 218.722L191.468 218.062C191.265 218.167 191.085 218.272 190.889 218.385C189.328 219.242 187.94 220.379 186.794 221.74L187.433 222.377L187.47 222.369Z"
            fill="white"
          />
          <path
            d="M210.824 237.32C209.064 237.6 207.285 237.738 205.504 237.732C204.579 237.732 203.67 237.732 202.776 237.627L203.738 238.579C206.37 238.717 209.009 238.556 211.605 238.099L210.854 237.35L210.824 237.32Z"
            fill="white"
          />
          <path
            d="M192.624 227.502C192.225 225.69 193.3 223.81 195.825 222.357L195.306 221.84H195.269C195.066 221.96 194.878 222.087 194.69 222.214L194.412 222.409C194.269 222.514 194.134 222.626 193.999 222.739L193.676 223.016C193.57 223.113 193.465 223.211 193.368 223.315C193.27 223.42 193.157 223.54 193.067 223.652C192.977 223.765 192.902 223.84 192.834 223.937C192.74 224.062 192.652 224.192 192.571 224.327C192.511 224.416 192.451 224.506 192.398 224.596C192.346 224.686 192.263 224.881 192.195 225.031C192.128 225.18 192.12 225.195 192.09 225.285C192.031 225.452 191.983 225.622 191.947 225.795C191.926 225.861 191.909 225.929 191.895 225.997C191.853 226.224 191.831 226.455 191.827 226.686L192.624 227.502Z"
            fill="white"
          />
          <path
            d="M206.856 233.357C203.418 233.593 199.973 233.015 196.802 231.672L198.094 232.96C198.575 233.125 199.086 233.267 199.597 233.402L199.958 233.484C200.491 233.612 201.032 233.724 201.588 233.814C202.122 233.896 202.678 233.956 203.234 234.001H203.722C204.248 234.039 204.774 234.061 205.308 234.061H205.451C205.879 234.061 206.3 234.061 206.72 234.061H206.856L207.464 234.009L206.856 233.357Z"
            fill="white"
          />
          <path
            d="M219.63 219.158C223.237 221.233 225.221 223.929 225.221 226.768C225.221 227.066 225.199 227.364 225.153 227.659L225.905 228.408C226.776 224.843 224.83 221.098 220.074 218.364C217.928 217.162 215.617 216.281 213.213 215.75L214.461 216.993C216.266 217.502 218.002 218.229 219.63 219.158Z"
            fill="white"
          />
          <path
            d="M205.503 215.791C205.917 215.791 206.322 215.791 206.736 215.791L205.826 214.885C204.052 214.864 202.278 214.98 200.521 215.23L201.318 216.024C202.707 215.859 204.105 215.781 205.503 215.791Z"
            fill="white"
          />
          <path
            d="M219.675 234.352L220.329 235.004C221.499 234.334 222.568 233.504 223.507 232.539L222.876 231.91C221.93 232.87 220.852 233.692 219.675 234.352Z"
            fill="white"
          />
          <path
            d="M218.398 227.441C218.034 228.786 217.165 229.94 215.971 230.662L216.482 231.171L216.707 231.014L216.842 230.917C217.053 230.745 217.256 230.572 217.443 230.392L217.586 230.243C217.752 230.078 217.902 229.913 218.045 229.741L218.157 229.591C218.277 229.435 218.387 229.273 218.488 229.104L218.601 228.917C218.695 228.739 218.78 228.557 218.856 228.37L218.939 228.145C218.939 228.145 218.939 228.063 218.976 228.018L218.398 227.441Z"
            fill="white"
          />
          <path
            d="M204.722 219.465L205.413 220.154C207.627 220.129 209.831 220.427 211.958 221.038L210.959 220.042H210.831C210.568 219.982 210.29 219.929 210.02 219.884L209.546 219.794L208.795 219.69L208.246 219.615L207.495 219.555C207.277 219.555 207.067 219.517 206.841 219.51H206.338C206.06 219.51 205.782 219.51 205.496 219.51C205.211 219.51 204.978 219.465 204.722 219.465Z"
            fill="white"
          />
          <path
            d="M212.604 236.987L213.356 237.736C214.477 237.481 215.579 237.151 216.655 236.748L215.963 236.059C214.865 236.441 213.743 236.751 212.604 236.987Z"
            fill="white"
          />
          <path
            d="M196.78 216.937L196.028 216.188C194.959 216.5 193.912 216.885 192.895 217.341L193.571 218.015C194.614 217.582 195.687 217.221 196.78 216.937Z"
            fill="white"
          />
          <path
            d="M197.231 221.665C198.284 221.22 199.379 220.879 200.499 220.647L199.913 220.062H199.876C198.787 220.315 197.725 220.671 196.705 221.126L197.231 221.665Z"
            fill="white"
          />
          <path
            d="M212.296 232.402C211.148 232.753 209.972 233.004 208.78 233.151L209.411 233.781L209.712 233.728C210.147 233.653 210.583 233.563 211.004 233.466L211.387 233.376C211.853 233.256 212.304 233.129 212.74 232.987H212.867L212.296 232.402Z"
            fill="white"
          />
          <path
            d="M208.141 215.889C208.975 215.949 209.802 216.046 210.606 216.174L209.644 215.14C208.855 215.043 208.059 214.983 207.262 214.945L208.217 215.889H208.141Z"
            fill="white"
          />
          <path
            d="M225.048 230.542L224.394 229.883C224.148 230.347 223.853 230.784 223.515 231.186L224.154 231.83C224.487 231.426 224.787 230.995 225.048 230.542Z"
            fill="white"
          />
          <path
            d="M214.994 221.446C214.305 221.1 213.592 220.805 212.86 220.562L214.115 221.813C214.303 221.903 214.49 221.978 214.671 222.075C216.865 223.191 218.105 224.622 218.428 226.083L219.179 226.832V226.742C219.172 224.704 217.691 222.824 214.994 221.446Z"
            fill="white"
          />
          <path
            d="M191.377 234.371C188.319 232.618 186.433 230.409 185.929 228.05L184.9 227.023C185.005 229.975 187.026 232.903 190.911 235.15C192.148 235.855 193.448 236.442 194.796 236.903L193.195 235.3C192.572 235.026 191.964 234.716 191.377 234.371Z"
            fill="white"
          />
          <path
            d="M195.442 236.176L196.78 237.516C197.689 237.756 198.628 237.958 199.583 238.131L198.448 236.992C197.431 236.781 196.427 236.508 195.442 236.176Z"
            fill="white"
          />
          <path
            d="M186.14 224.705L185.441 224.016C185.213 224.563 185.054 225.137 184.968 225.723L185.794 226.555C185.813 225.924 185.93 225.3 186.14 224.705Z"
            fill="white"
          />
        </g>
        <path
          d="M223.59 220.829C222.364 218.922 220.67 217.36 218.668 216.29C210.613 211.653 197.561 211.653 189.514 216.29C187.512 217.36 185.817 218.922 184.592 220.829H183.472V225.031C183.622 227.944 185.629 230.843 189.483 233.067C197.531 237.704 210.583 237.704 218.638 233.067C222.508 230.82 224.529 227.944 224.65 225.031V220.829H223.59Z"
          fill="#42CE8F"
        />
        <g opacity="0.25">
          <path
            d="M223.59 220.829C222.364 218.922 220.67 217.36 218.668 216.29C210.613 211.653 197.561 211.653 189.514 216.29C187.512 217.36 185.817 218.922 184.592 220.829H183.472V225.031C183.622 227.944 185.629 230.843 189.483 233.067C197.531 237.704 210.583 237.704 218.638 233.067C222.508 230.82 224.529 227.944 224.65 225.031V220.829H223.59Z"
            fill="black"
          />
        </g>
        <path
          opacity="0.1"
          d="M204.091 212.812C198.831 212.812 193.571 213.973 189.514 216.288C187.512 217.358 185.817 218.92 184.592 220.827H183.472V225.029C183.622 227.942 185.629 230.841 189.483 233.065C193.511 235.38 198.786 236.541 204.061 236.541L204.091 212.812Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M192.835 214.738C191.687 215.164 190.576 215.683 189.514 216.289C187.512 217.359 185.817 218.921 184.592 220.828H183.472V225.03C183.622 227.943 185.629 230.842 189.483 233.066C190.546 233.673 191.657 234.191 192.805 234.617L192.835 214.738Z"
          fill="black"
        />
        <path
          opacity="0.5"
          d="M215.347 214.738C216.494 215.165 217.605 215.684 218.668 216.289C220.67 217.359 222.364 218.921 223.59 220.828H224.672V225.03C224.529 227.943 222.508 230.842 218.661 233.066C217.597 233.671 216.486 234.19 215.339 234.617L215.347 214.738Z"
          fill="#42CE8F"
        />
        <path
          d="M218.661 212.44C210.606 207.812 197.554 207.812 189.506 212.44C181.459 217.069 181.459 224.589 189.506 229.218C197.554 233.847 210.606 233.854 218.661 229.218C226.716 224.582 226.716 217.077 218.661 212.44Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.2"
          d="M218.661 212.44C210.606 207.812 197.554 207.812 189.506 212.44C181.459 217.069 181.459 224.589 189.506 229.218C197.554 233.847 210.606 233.854 218.661 229.218C226.716 224.582 226.716 217.077 218.661 212.44Z"
          fill="black"
        />
        <path
          d="M213.582 215.511C210.595 214.182 207.361 213.496 204.091 213.496C200.821 213.496 197.587 214.182 194.601 215.511C191.903 216.889 190.416 218.784 190.416 220.828C190.416 222.873 191.918 224.768 194.601 226.146C197.586 227.48 200.82 228.169 204.091 228.169C207.362 228.169 210.596 227.48 213.582 226.146C216.279 224.768 217.767 222.881 217.767 220.828C217.767 218.776 216.279 216.889 213.582 215.511Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.4"
          d="M213.582 215.511C210.595 214.182 207.361 213.496 204.091 213.496C200.821 213.496 197.587 214.182 194.601 215.511C191.903 216.889 190.416 218.784 190.416 220.828C190.416 222.873 191.918 224.768 194.601 226.146C197.586 227.48 200.82 228.169 204.091 228.169C207.362 228.169 210.596 227.48 213.582 226.146C216.279 224.768 217.767 222.881 217.767 220.828C217.767 218.776 216.279 216.889 213.582 215.511Z"
          fill="black"
        />
        <path
          d="M218.217 213.219C214.46 211.054 209.441 209.863 204.091 209.863C198.741 209.863 193.721 211.054 189.964 213.219C186.357 215.294 184.374 217.99 184.374 220.829C184.374 223.667 186.357 226.371 189.964 228.446C193.721 230.603 198.741 231.802 204.091 231.802C209.441 231.802 214.46 230.603 218.217 228.446C221.816 226.371 223.808 223.667 223.808 220.829C223.808 217.99 221.816 215.294 218.217 213.219ZM213.581 226.147C210.596 227.48 207.362 228.169 204.091 228.169C200.82 228.169 197.585 227.48 194.6 226.147C191.903 224.768 190.415 222.881 190.415 220.829C190.415 218.776 191.918 216.889 194.6 215.511C197.587 214.183 200.82 213.496 204.091 213.496C207.361 213.496 210.594 214.183 213.581 215.511C216.278 216.889 217.766 218.784 217.766 220.829C217.766 222.873 216.278 224.768 213.581 226.147Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.2"
          d="M213.258 225.516C218.322 222.932 218.322 218.731 213.258 216.139C208.193 213.547 199.988 213.547 194.923 216.139C189.859 218.731 189.859 222.932 194.923 225.516C199.988 228.101 208.193 228.108 213.258 225.516Z"
          fill="black"
        />
        <path
          d="M213.258 225.516C218.322 222.932 218.322 218.731 213.258 216.139C208.193 213.547 199.988 213.547 194.923 216.139C189.859 218.731 189.859 222.932 194.923 225.516C199.988 228.101 208.193 228.108 213.258 225.516Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.6"
          d="M213.258 225.516C218.322 222.932 218.322 218.731 213.258 216.139C208.193 213.547 199.988 213.547 194.923 216.139C189.859 218.731 189.859 222.932 194.923 225.516C199.988 228.101 208.193 228.108 213.258 225.516Z"
          fill="black"
        />
        <path
          d="M213.258 217.897C208.194 215.305 199.988 215.305 194.924 217.897C192.827 218.968 191.602 220.309 191.242 221.702C191.602 223.102 192.827 224.443 194.924 225.514C199.988 228.106 208.194 228.106 213.258 225.514C215.354 224.443 216.579 223.102 216.94 221.709C216.579 220.309 215.354 218.968 213.258 217.897Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.5"
          d="M213.258 217.897C208.194 215.305 199.988 215.305 194.924 217.897C192.827 218.968 191.602 220.309 191.242 221.702C191.602 223.102 192.827 224.443 194.924 225.514C199.988 228.106 208.194 228.106 213.258 225.514C215.354 224.443 216.579 223.102 216.94 221.709C216.579 220.309 215.354 218.968 213.258 217.897Z"
          fill="black"
        />
        <path
          d="M205.316 216.242V218.137L204.67 218.819L203.467 220.07V218.1L205.316 216.242Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.3"
          d="M205.316 216.242V218.137L204.67 218.819L203.467 220.07V218.1L205.316 216.242Z"
          fill="black"
        />
        <path
          d="M203.467 218.09V219.214C202.869 219.441 202.253 219.616 201.626 219.738C201.225 219.82 200.808 219.753 200.454 219.551C199.995 219.281 199.928 218.877 200.799 218.375C201.191 218.125 201.636 217.969 202.099 217.919C202.562 217.87 203.03 217.928 203.467 218.09Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.45"
          d="M203.467 218.09V219.214C202.869 219.441 202.253 219.616 201.626 219.738C201.225 219.82 200.808 219.753 200.454 219.551C199.995 219.281 199.928 218.877 200.799 218.375C201.191 218.125 201.636 217.969 202.099 217.919C202.562 217.87 203.03 217.928 203.467 218.09Z"
          fill="black"
        />
        <path
          d="M212.071 223.475V225.108L209.997 226.306L208.457 225.452C206.586 226.32 204.447 226.417 202.506 225.722V224.081L203.445 223.145L202.506 223.407C201.735 223.628 200.927 223.695 200.13 223.604C199.333 223.513 198.562 223.265 197.862 222.875C196.93 222.344 196.502 221.752 196.502 221.153V219.557L196.667 218.861L196.104 218.531V216.898L197.606 217.782C197.294 217.968 197.029 218.224 196.832 218.53C196.635 218.835 196.512 219.182 196.472 219.542C196.472 220.134 196.923 220.703 197.832 221.228C199.853 222.389 201.859 221.977 203.693 221.422C205.398 220.861 206.646 220.209 207.63 220.778C208.239 221.13 208.051 221.527 207.367 221.954C206.922 222.198 206.428 222.339 205.921 222.369C205.415 222.399 204.907 222.316 204.437 222.126L202.475 224.066C202.826 224.202 203.188 224.307 203.558 224.381C205.202 224.694 206.904 224.49 208.427 223.797L209.967 224.688L212.071 223.475Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.35"
          d="M196.502 219.519C196.502 220.111 196.953 220.68 197.862 221.204C199.883 222.365 201.89 221.953 203.723 221.399C205.429 220.837 206.676 220.185 207.66 220.755C208.269 221.107 208.081 221.504 207.397 221.931C206.952 222.174 206.458 222.316 205.952 222.345C205.445 222.375 204.938 222.292 204.467 222.103L202.506 224.043C202.857 224.178 203.219 224.284 203.588 224.357C205.232 224.67 206.934 224.466 208.457 223.773L209.997 224.664L212.071 223.466V225.099L209.997 226.297L208.457 225.458C206.586 226.326 204.448 226.423 202.506 225.728V224.088L203.445 223.151L202.506 223.414C201.735 223.635 200.928 223.702 200.131 223.61C199.334 223.519 198.563 223.272 197.862 222.882C196.93 222.35 196.502 221.758 196.502 221.159V219.564L196.667 218.867"
          fill="black"
        />
        <path
          opacity="0.45"
          d="M196.118 216.875V218.508L196.682 218.837C196.921 218.409 197.252 218.038 197.651 217.751L196.118 216.875Z"
          fill="black"
        />
        <path
          d="M211.733 222.412C211.733 222.67 211.679 222.925 211.575 223.161L210.53 222.569C210.845 222.428 211.119 222.209 211.326 221.934C211.534 221.659 211.668 221.337 211.718 220.996C211.725 221.468 211.733 222.367 211.733 222.412Z"
          fill="#42CE8F"
        />
        <g opacity="0.3">
          <path
            d="M211.733 222.404C211.733 222.662 211.679 222.917 211.575 223.153L210.53 222.561C210.845 222.42 211.119 222.201 211.326 221.926C211.534 221.652 211.668 221.329 211.718 220.988C211.725 221.46 211.733 222.359 211.733 222.404Z"
            fill="black"
          />
        </g>
        <path
          d="M211.725 223.282L210.538 222.593C210.889 222.434 211.187 222.178 211.397 221.856C211.607 221.533 211.721 221.158 211.725 220.773C211.725 220.713 211.725 220.668 211.725 220.616C211.65 220.26 211.49 219.928 211.259 219.648C211.027 219.367 210.731 219.147 210.395 219.006C209.557 218.515 208.602 218.256 207.63 218.257H207.389H207.141H206.923H206.833H206.668C206.217 218.321 205.77 218.413 205.33 218.534L204.699 218.721L203.828 218.998L203.497 219.118C202.901 219.348 202.285 219.521 201.656 219.635C201.394 219.692 201.122 219.692 200.86 219.635C200.73 219.603 200.606 219.552 200.491 219.485C200.026 219.215 199.95 218.811 200.822 218.309C201.216 218.059 201.662 217.904 202.127 217.856C202.591 217.808 203.06 217.868 203.497 218.032L205.345 216.174C203.512 215.597 201.529 215.706 199.77 216.481L198.23 215.598L196.118 216.871L197.621 217.755C197.307 217.941 197.041 218.196 196.843 218.501C196.645 218.807 196.52 219.154 196.479 219.515C196.479 220.107 196.93 220.676 197.839 221.2C199.868 222.369 201.866 221.949 203.7 221.387C205.406 220.833 206.653 220.182 207.637 220.751C208.246 221.103 208.058 221.5 207.374 221.927C206.93 222.172 206.435 222.315 205.928 222.343C205.421 222.372 204.913 222.286 204.444 222.092L202.475 224.039C202.83 224.172 203.194 224.279 203.565 224.361C204.187 224.49 204.824 224.535 205.458 224.496C206.497 224.426 207.514 224.172 208.464 223.747L210.004 224.638L212.078 223.44L211.725 223.282Z"
          fill="#42CE8F"
        />
        <path
          d="M208.457 223.785V225.425L209.997 226.309V224.676L208.457 223.785Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.45"
          d="M208.457 223.785V225.425L209.997 226.309V224.676L208.457 223.785Z"
          fill="black"
        />
        <path
          d="M196.104 216.874L198.2 215.668L199.74 216.552L198.215 215.915L196.104 216.874Z"
          fill="#F5F5F5"
        />
        <path
          d="M200.792 218.377C201.186 218.127 201.632 217.971 202.097 217.923C202.561 217.875 203.03 217.936 203.467 218.1L205.315 216.242L203.467 217.905C203.018 217.751 202.536 217.714 202.068 217.796C201.6 217.879 201.161 218.079 200.792 218.377Z"
          fill="#F5F5F5"
        />
        <path
          d="M202.498 224.057C202.853 224.19 203.217 224.297 203.588 224.379C204.21 224.508 204.847 224.553 205.481 224.514C204.565 224.52 203.657 224.339 202.814 223.982L204.467 222.109L202.498 224.057Z"
          fill="#F5F5F5"
        />
        <path
          d="M208.457 223.788L209.997 224.484L212.071 223.48L209.997 224.679L208.457 223.788Z"
          fill="#F5F5F5"
        />
        <path
          d="M190.108 213.469C195.563 210.158 204.076 209.836 204.076 209.836C198.726 209.836 193.707 211.027 189.95 213.191C186.343 215.266 184.359 217.963 184.359 220.801C184.374 220.824 184.57 216.847 190.108 213.469Z"
          fill="#F5F5F5"
        />
        <path
          d="M202.498 232.664C208.291 232.919 214.235 231.773 218.668 229.219C223.275 226.568 225.243 222.972 224.575 219.52C224.575 219.52 225.439 225.152 218.315 229.009C211.192 232.867 202.498 232.664 202.498 232.664Z"
          fill="#F5F5F5"
        />
        <path
          opacity="0.3"
          d="M190.415 220.774C190.415 220.542 190.438 220.312 190.483 220.085C190.496 220.019 190.513 219.954 190.535 219.89C190.57 219.715 190.615 219.542 190.671 219.373L190.783 219.118C190.851 218.976 190.911 218.826 190.986 218.692C191.061 218.557 191.099 218.504 191.159 218.414C191.24 218.282 191.328 218.155 191.422 218.032C191.49 217.935 191.572 217.838 191.647 217.74L191.956 217.403L192.264 217.104L192.587 216.827C192.722 216.714 192.857 216.602 193 216.497L193.278 216.302C193.466 216.175 193.654 216.048 193.857 215.928L190.723 212.797L189.972 213.231C188.479 214.049 187.154 215.139 186.064 216.445L190.423 220.781L190.415 220.774Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M206.112 228.101H205.496H205.361C204.94 228.101 204.511 228.138 204.091 228.138H203.948C203.414 228.138 202.888 228.138 202.362 228.079L201.874 228.041C201.318 227.989 200.762 227.936 200.228 227.846C199.672 227.756 199.131 227.644 198.598 227.524L198.237 227.434C197.719 227.307 197.208 227.165 196.734 227L201.416 231.666C202.307 231.741 203.217 231.779 204.143 231.779C205.925 231.78 207.703 231.642 209.463 231.367L206.172 228.079L206.112 228.101Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M196.501 219.516C196.501 220.108 196.952 220.677 197.861 221.202C198.489 221.573 199.188 221.81 199.912 221.898L196.726 218.715C196.569 218.952 196.49 219.232 196.501 219.516Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M223.808 220.826C223.808 217.995 221.825 215.291 218.218 213.216C216.59 212.296 214.857 211.574 213.056 211.066L223.741 221.71C223.785 221.417 223.808 221.122 223.808 220.826Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M204.091 213.475C204.369 213.475 204.655 213.475 204.933 213.475H205.436L206.09 213.527C206.33 213.527 206.563 213.527 206.796 213.587C207.029 213.647 207.164 213.632 207.345 213.655C207.525 213.677 207.871 213.722 208.096 213.767L208.577 213.849L209.389 214.014H209.509L205.286 209.805C204.88 209.805 204.474 209.805 204.061 209.805C202.66 209.805 201.259 209.89 199.868 210.059L203.272 213.452L204.091 213.475Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M217.527 222.209L217.444 222.427C217.368 222.615 217.283 222.8 217.188 222.981L217.076 223.161C216.978 223.325 216.865 223.49 216.745 223.655L216.632 223.797C216.49 223.97 216.339 224.142 216.174 224.307C216.121 224.352 216.076 224.404 216.031 224.449C215.843 224.629 215.641 224.801 215.43 224.973L215.295 225.071L215.084 225.243L218.27 228.419C219.448 227.756 220.525 226.932 221.471 225.969L217.579 222.082L217.527 222.209Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M211.732 220.773C211.737 220.72 211.737 220.668 211.732 220.616C211.658 220.26 211.498 219.928 211.266 219.647C211.034 219.367 210.738 219.146 210.402 219.005C209.711 218.61 208.942 218.372 208.148 218.309L211.537 221.694C211.68 221.409 211.747 221.091 211.732 220.773Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M198.455 214.138H198.5L195.367 211C194.284 211.302 193.222 211.675 192.188 212.116L195.284 215.194C196.305 214.742 197.367 214.388 198.455 214.138Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M211.327 227.073C210.891 227.222 210.433 227.35 209.975 227.462L209.591 227.559C209.171 227.657 208.735 227.739 208.299 227.814L208.006 227.874L211.192 231.057C212.327 230.811 213.446 230.496 214.543 230.114L211.455 227.035L211.327 227.073Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M204.466 222.109L203.339 223.225L204.624 224.506C204.91 224.525 205.196 224.525 205.481 224.506C206.44 224.444 207.382 224.223 208.269 223.855L206.638 222.252C205.918 222.436 205.157 222.387 204.466 222.109Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M205.278 220.881L203.573 219.181L203.467 219.218C202.87 219.446 202.254 219.621 201.626 219.743C201.226 219.824 200.809 219.758 200.454 219.555C199.996 219.286 199.928 218.881 200.8 218.379C201.255 218.104 201.771 217.944 202.303 217.915L200.627 216.237C200.337 216.327 200.044 216.432 199.748 216.552L198.208 215.668L196.66 216.559L201.972 221.855C202.57 221.749 203.161 221.601 203.738 221.413C204.287 221.226 204.805 221.031 205.278 220.881Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M206.758 209.961L211.432 214.62C212.17 214.863 212.888 215.161 213.581 215.511C216.279 216.889 217.766 218.784 217.766 220.829C217.766 220.829 217.766 220.829 217.766 220.866V220.964L222.11 225.293C222.447 224.892 222.741 224.458 222.989 223.997L209.23 210.283C208.419 210.111 207.592 210.021 206.758 209.961Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M189.965 228.45C190.551 228.78 191.152 229.095 191.798 229.379L184.517 222.129C185.021 224.473 186.907 226.683 189.965 228.45Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M184.381 220.632L194.029 230.249C195.015 230.577 196.019 230.849 197.035 231.065L184.727 218.789C184.516 219.381 184.399 220.003 184.381 220.632Z"
          fill="white"
        />
        <g opacity="0.15">
          <path
            d="M186.057 216.467C187.147 215.162 188.472 214.072 189.964 213.254L190.716 212.819L190.055 212.16L189.476 212.475C187.918 213.337 186.531 214.474 185.381 215.83L186.02 216.474L186.057 216.467Z"
            fill="white"
          />
          <path
            d="M209.411 231.391C207.651 231.666 205.872 231.804 204.091 231.803C203.164 231.803 202.255 231.765 201.363 231.69L202.325 232.649C204.958 232.785 207.597 232.621 210.192 232.162L209.441 231.413L209.411 231.391Z"
            fill="white"
          />
          <path
            d="M191.212 221.58C190.814 219.76 191.888 217.888 194.413 216.435L193.895 215.918H193.857C193.654 216.038 193.466 216.165 193.278 216.292L193 216.487C192.858 216.592 192.722 216.704 192.587 216.817L192.264 217.094L191.956 217.394L191.648 217.731C191.573 217.828 191.49 217.925 191.422 218.023C191.328 218.145 191.24 218.273 191.159 218.405C191.099 218.495 191.039 218.584 190.987 218.682C190.934 218.779 190.851 218.966 190.784 219.109L190.671 219.363C190.615 219.533 190.57 219.705 190.536 219.88C190.514 219.944 190.496 220.009 190.483 220.075C190.442 220.305 190.419 220.538 190.416 220.771L191.212 221.58Z"
            fill="white"
          />
          <path
            d="M205.436 227.427C201.993 227.663 198.542 227.083 195.367 225.734L196.66 227.023C197.148 227.187 197.659 227.33 198.162 227.457L198.523 227.547C199.057 227.667 199.598 227.779 200.154 227.869C200.687 227.959 201.243 228.011 201.799 228.064L202.288 228.101C202.814 228.101 203.34 228.154 203.873 228.161H204.016C204.437 228.161 204.865 228.161 205.286 228.161H205.421L206.03 228.116L205.436 227.427Z"
            fill="white"
          />
          <path
            d="M218.218 213.217C221.825 215.292 223.808 217.996 223.808 220.827C223.808 221.128 223.786 221.428 223.741 221.726L224.492 222.475C225.364 218.909 223.418 215.164 218.661 212.43C216.513 211.234 214.202 210.354 211.801 209.816L213.048 211.06C214.852 211.569 216.588 212.294 218.218 213.217Z"
            fill="white"
          />
          <path
            d="M204.091 209.855C204.504 209.855 204.91 209.855 205.316 209.855L204.384 208.979C202.609 208.958 200.836 209.073 199.079 209.323L199.876 210.117C201.274 209.945 202.682 209.857 204.091 209.855Z"
            fill="white"
          />
          <path
            d="M218.263 228.453L218.916 229.097C220.086 228.428 221.156 227.598 222.095 226.633L221.464 226.004C220.518 226.966 219.44 227.791 218.263 228.453Z"
            fill="white"
          />
          <path
            d="M216.985 221.512C216.62 222.856 215.751 224.009 214.558 224.732L215.069 225.242C215.144 225.182 215.227 225.137 215.294 225.077L215.43 224.98C215.64 224.807 215.843 224.635 216.031 224.455C216.076 224.41 216.121 224.358 216.174 224.313C216.339 224.148 216.489 223.976 216.632 223.804L216.745 223.661C216.865 223.497 216.97 223.332 217.075 223.167L217.188 222.987C217.282 222.807 217.368 222.622 217.444 222.433L217.526 222.216C217.526 222.171 217.526 222.126 217.564 222.081L216.985 221.512Z"
            fill="white"
          />
          <path
            d="M203.31 213.512L203.993 214.201C206.206 214.183 208.41 214.48 210.538 215.085L209.546 214.096H209.419L208.607 213.931L208.126 213.849C207.871 213.804 207.608 213.774 207.375 213.736C207.142 213.699 207.014 213.684 206.826 213.669C206.638 213.654 206.36 213.624 206.12 213.609L205.466 213.557H204.963C204.685 213.557 204.407 213.557 204.121 213.557L203.31 213.512Z"
            fill="white"
          />
          <path
            d="M211.192 231.057L211.943 231.806C213.063 231.545 214.165 231.213 215.242 230.81L214.551 230.121C213.451 230.501 212.33 230.814 211.192 231.057Z"
            fill="white"
          />
          <path
            d="M195.367 210.999L194.616 210.25C193.546 210.562 192.499 210.948 191.482 211.403L192.159 212.085C193.203 211.653 194.275 211.29 195.367 210.999Z"
            fill="white"
          />
          <path
            d="M195.818 215.72C196.874 215.281 197.968 214.94 199.086 214.701L198.5 214.117H198.455C197.367 214.367 196.305 214.721 195.284 215.173L195.818 215.72Z"
            fill="white"
          />
          <path
            d="M210.883 226.469C209.735 226.82 208.558 227.07 207.367 227.218L207.998 227.854L208.298 227.794C208.734 227.72 209.17 227.637 209.591 227.54L209.974 227.442C210.44 227.33 210.891 227.195 211.327 227.053L211.454 227.016L210.883 226.469Z"
            fill="white"
          />
          <path
            d="M206.758 209.955C207.592 210.015 208.419 210.105 209.223 210.232L208.194 209.206C207.405 209.109 206.608 209.041 205.812 209.004L206.766 209.955H206.758Z"
            fill="white"
          />
          <path
            d="M223.635 224.601L222.982 223.949C222.734 224.41 222.44 224.844 222.103 225.245L222.741 225.882C223.077 225.482 223.376 225.053 223.635 224.601Z"
            fill="white"
          />
          <path
            d="M213.581 215.517C212.895 215.166 212.181 214.87 211.447 214.633L212.702 215.884L213.258 216.138C215.452 217.262 216.692 218.685 217.015 220.153L217.767 220.902V220.812C217.759 218.782 216.279 216.887 213.581 215.517Z"
            fill="white"
          />
          <path
            d="M189.964 228.449C186.906 226.689 185.02 224.479 184.517 222.127L183.487 221.094C183.593 224.045 185.606 226.973 189.499 229.22C190.736 229.925 192.037 230.514 193.383 230.981L191.783 229.378C191.152 229.086 190.551 228.771 189.964 228.449Z"
            fill="white"
          />
          <path
            d="M194.03 230.25L195.367 231.583C196.277 231.83 197.216 232.025 198.17 232.197L197.028 231.066C196.014 230.85 195.013 230.578 194.03 230.25Z"
            fill="white"
          />
          <path
            d="M184.727 218.794L184.029 218.098C183.802 218.646 183.643 219.219 183.555 219.805L184.382 220.637C184.399 220.008 184.516 219.387 184.727 218.794Z"
            fill="white"
          />
        </g>
        <path
          d="M226.543 215.083C225.312 213.178 223.616 211.616 221.613 210.544C213.566 205.907 200.514 205.907 192.459 210.544C190.458 211.616 188.764 213.178 187.537 215.083H186.425V219.284C186.575 222.198 188.574 225.097 192.436 227.321C200.491 231.958 213.543 231.958 221.591 227.321C225.461 225.074 227.482 222.198 227.602 219.277V215.075L226.543 215.083Z"
          fill="#42CE8F"
        />
        <g opacity="0.25">
          <path
            d="M226.543 215.086C225.312 213.181 223.616 211.619 221.613 210.548C213.566 205.911 200.514 205.911 192.459 210.548C190.458 211.62 188.764 213.182 187.537 215.086H186.425V219.288C186.575 222.202 188.574 225.101 192.436 227.325C200.491 231.961 213.543 231.961 221.591 227.325C225.461 225.078 227.482 222.202 227.602 219.281V215.079L226.543 215.086Z"
            fill="black"
          />
        </g>
        <path
          opacity="0.1"
          d="M207.036 207.066C201.776 207.066 196.516 208.227 192.459 210.542C190.458 211.614 188.764 213.176 187.537 215.081H186.425V219.283C186.575 222.196 188.574 225.095 192.436 227.319C196.464 229.634 201.739 230.795 207.013 230.795L207.036 207.066Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M195.78 208.977C194.633 209.405 193.523 209.924 192.459 210.527C190.458 211.599 188.764 213.161 187.537 215.066H186.425V219.268C186.575 222.181 188.574 225.08 192.436 227.305C193.5 227.908 194.611 228.426 195.757 228.855L195.78 208.977Z"
          fill="black"
        />
        <path
          opacity="0.5"
          d="M218.3 208.977C219.444 209.404 220.553 209.922 221.614 210.527C223.615 211.597 225.31 213.159 226.535 215.066H227.677V219.268C227.527 222.189 225.506 225.088 221.666 227.312C220.605 227.917 219.497 228.435 218.352 228.863L218.3 208.977Z"
          fill="#42CE8F"
        />
        <path
          d="M221.614 206.733C213.566 202.105 200.514 202.105 192.459 206.733C184.404 211.362 184.411 218.882 192.459 223.511C200.507 228.14 213.566 228.147 221.614 223.511C229.661 218.875 229.669 211.332 221.614 206.733Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.2"
          d="M221.614 206.733C213.566 202.105 200.514 202.105 192.459 206.733C184.404 211.362 184.411 218.882 192.459 223.511C200.507 228.14 213.566 228.147 221.614 223.511C229.661 218.875 229.669 211.332 221.614 206.733Z"
          fill="black"
        />
        <path
          d="M216.535 209.766C213.547 208.437 210.312 207.75 207.041 207.75C203.769 207.75 200.534 208.437 197.547 209.766C194.849 211.144 193.361 213.039 193.361 215.084C193.361 217.129 194.864 219.024 197.547 220.402C200.533 221.735 203.768 222.425 207.041 222.425C210.313 222.425 213.548 221.735 216.535 220.402C219.225 219.024 220.713 217.136 220.713 215.084C220.713 213.032 219.225 211.144 216.535 209.766Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.4"
          d="M216.535 209.766C213.547 208.437 210.312 207.75 207.041 207.75C203.769 207.75 200.534 208.437 197.547 209.766C194.849 211.144 193.361 213.039 193.361 215.084C193.361 217.129 194.864 219.024 197.547 220.402C200.533 221.735 203.768 222.425 207.041 222.425C210.313 222.425 213.548 221.735 216.535 220.402C219.225 219.024 220.713 217.136 220.713 215.084C220.713 213.032 219.225 211.144 216.535 209.766Z"
          fill="black"
        />
        <path
          d="M221.171 207.477C217.414 205.312 212.387 204.121 207.037 204.121C201.687 204.121 196.675 205.312 192.91 207.477C189.303 209.551 187.327 212.248 187.327 215.086C187.327 217.925 189.303 220.629 192.91 222.704C196.667 224.861 201.687 226.059 207.037 226.059C212.387 226.059 217.406 224.861 221.171 222.704C224.77 220.629 226.754 217.925 226.754 215.086C226.754 212.248 224.77 209.544 221.171 207.477ZM216.535 220.404C213.548 221.738 210.313 222.427 207.04 222.427C203.768 222.427 200.533 221.738 197.546 220.404C194.849 219.026 193.361 217.139 193.361 215.086C193.361 213.034 194.864 211.147 197.546 209.769C200.534 208.439 203.769 207.752 207.04 207.752C210.312 207.752 213.547 208.439 216.535 209.769C219.225 211.147 220.712 213.042 220.712 215.086C220.712 217.131 219.225 219.019 216.535 220.397V220.404Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.2"
          d="M207.037 221.71C214.195 221.71 219.998 218.743 219.998 215.082C219.998 211.421 214.195 208.453 207.037 208.453C199.878 208.453 194.075 211.421 194.075 215.082C194.075 218.743 199.878 221.71 207.037 221.71Z"
          fill="black"
        />
        <path
          d="M207.037 221.71C214.195 221.71 219.998 218.743 219.998 215.082C219.998 211.421 214.195 208.453 207.037 208.453C199.878 208.453 194.075 211.421 194.075 215.082C194.075 218.743 199.878 221.71 207.037 221.71Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.6"
          d="M207.037 221.71C214.195 221.71 219.998 218.743 219.998 215.082C219.998 211.421 214.195 208.453 207.037 208.453C199.878 208.453 194.075 211.421 194.075 215.082C194.075 218.743 199.878 221.71 207.037 221.71Z"
          fill="black"
        />
        <path
          d="M216.204 212.155C211.147 209.563 202.934 209.563 197.869 212.155C195.78 213.226 194.556 214.566 194.188 215.96C194.556 217.36 195.78 218.701 197.869 219.772C202.934 222.363 211.147 222.363 216.204 219.772C218.3 218.701 219.525 217.36 219.886 215.96C219.525 214.566 218.3 213.226 216.204 212.155Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.5"
          d="M216.204 212.155C211.147 209.563 202.934 209.563 197.869 212.155C195.78 213.226 194.556 214.566 194.188 215.96C194.556 217.36 195.78 218.701 197.869 219.772C202.934 222.363 211.147 222.363 216.204 219.772C218.3 218.701 219.525 217.36 219.886 215.96C219.525 214.566 218.3 213.226 216.204 212.155Z"
          fill="black"
        />
        <path
          d="M208.268 210.488V212.391L207.615 213.072L206.42 214.323V212.353L208.268 210.488Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.3"
          d="M208.268 210.488V212.391L207.615 213.072L206.42 214.323V212.353L208.268 210.488Z"
          fill="black"
        />
        <path
          d="M206.421 212.35V213.473C205.821 213.701 205.202 213.876 204.572 213.997C204.311 214.044 204.044 214.044 203.783 213.997C203.652 213.961 203.526 213.908 203.407 213.84C202.949 213.571 202.874 213.166 203.746 212.664C204.137 212.411 204.583 212.251 205.046 212.197C205.51 212.142 205.98 212.194 206.421 212.35Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.45"
          d="M206.421 212.35V213.473C205.821 213.701 205.202 213.876 204.572 213.997C204.311 214.044 204.044 214.044 203.783 213.997C203.652 213.961 203.526 213.908 203.407 213.84C202.949 213.571 202.874 213.166 203.746 212.664C204.137 212.411 204.583 212.251 205.046 212.197C205.51 212.142 205.98 212.194 206.421 212.35Z"
          fill="black"
        />
        <path
          d="M215.024 217.735V219.368L212.942 220.566L211.402 219.683C209.531 220.551 207.391 220.646 205.451 219.945V218.312L206.398 217.376L205.451 217.638C204.681 217.859 203.875 217.925 203.079 217.834C202.284 217.743 201.514 217.495 200.815 217.106C199.883 216.574 199.447 215.982 199.447 215.383V213.788L199.62 213.091L199.049 212.762V211.129L200.589 212.013C200.276 212.199 200.01 212.455 199.812 212.76C199.614 213.065 199.489 213.412 199.447 213.773C199.447 214.365 199.905 214.934 200.815 215.458C202.836 216.619 204.835 216.207 206.676 215.653C208.381 215.091 209.621 214.439 210.606 215.009C211.222 215.361 211.034 215.758 210.35 216.185C209.905 216.428 209.411 216.57 208.904 216.599C208.397 216.629 207.89 216.546 207.42 216.357L205.451 218.297C205.804 218.433 206.169 218.538 206.54 218.611C208.182 218.925 209.882 218.721 211.402 218.027L212.942 218.919L215.024 217.735Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.35"
          d="M199.448 213.771C199.448 214.362 199.906 214.932 200.815 215.456C202.837 216.617 204.835 216.205 206.676 215.651C208.382 215.089 209.622 214.437 210.606 215.007C211.222 215.359 211.035 215.756 210.351 216.183C209.906 216.426 209.412 216.568 208.905 216.597C208.398 216.627 207.891 216.544 207.42 216.355L205.452 218.295C205.805 218.431 206.17 218.536 206.541 218.609C208.183 218.923 209.882 218.719 211.403 218.025L212.943 218.916L215.025 217.718V219.351L212.943 220.549L211.403 219.665C209.532 220.534 207.392 220.628 205.452 219.928V218.295L206.398 217.359L205.452 217.621C204.682 217.842 203.876 217.908 203.08 217.817C202.284 217.726 201.515 217.478 200.815 217.089C199.884 216.557 199.448 215.965 199.448 215.366V213.771L199.621 213.074L199.448 213.771Z"
          fill="black"
        />
        <path
          opacity="0.45"
          d="M199.049 211.129V212.762L199.62 213.091C199.86 212.661 200.193 212.29 200.597 212.005L199.049 211.129Z"
          fill="black"
        />
        <path
          d="M214.678 216.662C214.678 216.92 214.624 217.175 214.52 217.411L213.483 216.819C213.797 216.677 214.069 216.458 214.275 216.183C214.481 215.908 214.615 215.586 214.663 215.246C214.671 215.718 214.678 216.617 214.678 216.662Z"
          fill="#42CE8F"
        />
        <g opacity="0.3">
          <path
            d="M214.678 216.666C214.678 216.923 214.624 217.178 214.52 217.415L213.483 216.823C213.797 216.68 214.069 216.462 214.275 216.187C214.481 215.912 214.615 215.59 214.663 215.25C214.671 215.722 214.678 216.621 214.678 216.666Z"
            fill="black"
          />
        </g>
        <path
          d="M214.678 217.533L213.484 216.851C213.835 216.691 214.134 216.435 214.345 216.113C214.556 215.791 214.672 215.416 214.678 215.031C214.682 214.979 214.682 214.926 214.678 214.874C214.604 214.518 214.444 214.186 214.212 213.905C213.98 213.625 213.684 213.405 213.348 213.263C212.51 212.772 211.555 212.513 210.583 212.514H210.32H210.072H209.862H209.772H209.606C209.153 212.579 208.703 212.671 208.261 212.792L207.63 212.979L206.758 213.256L206.435 213.376C205.837 213.605 205.218 213.778 204.587 213.893C204.327 213.95 204.058 213.95 203.798 213.893C203.666 213.862 203.539 213.811 203.422 213.743C202.956 213.473 202.889 213.069 203.76 212.567C204.154 212.317 204.601 212.162 205.065 212.113C205.529 212.065 205.998 212.126 206.435 212.29L208.284 210.432C206.447 209.855 204.462 209.964 202.701 210.739L201.16 209.855L199.064 211.061L200.604 211.945C200.291 212.131 200.025 212.387 199.827 212.692C199.629 212.998 199.504 213.344 199.462 213.705C199.462 214.297 199.913 214.866 200.83 215.391C202.851 216.559 204.857 216.14 206.691 215.578C208.396 215.024 209.636 214.372 210.621 214.941C211.237 215.293 211.049 215.69 210.365 216.117C209.921 216.363 209.426 216.505 208.919 216.534C208.411 216.562 207.904 216.476 207.435 216.282L205.466 218.229C205.818 218.363 206.18 218.471 206.548 218.551C207.173 218.681 207.812 218.726 208.449 218.686C209.475 218.612 210.48 218.359 211.417 217.937L212.958 218.828L215.039 217.63L214.678 217.533Z"
          fill="#42CE8F"
        />
        <path
          d="M211.402 218.043V219.683L212.943 220.567V218.934L211.402 218.043Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.45"
          d="M211.402 218.043V219.683L212.943 220.567V218.934L211.402 218.043Z"
          fill="black"
        />
        <path
          d="M199.049 211.128L201.145 209.922L202.686 210.806L201.16 210.169L199.049 211.128Z"
          fill="#F5F5F5"
        />
        <path
          d="M203.746 212.635C204.139 212.385 204.586 212.229 205.05 212.181C205.515 212.133 205.984 212.193 206.421 212.358L208.269 210.5L206.421 212.163C205.971 212.009 205.49 211.972 205.022 212.054C204.554 212.137 204.115 212.336 203.746 212.635Z"
          fill="#F5F5F5"
        />
        <path
          d="M205.451 218.311C205.803 218.444 206.165 218.552 206.533 218.633C207.158 218.762 207.797 218.807 208.434 218.768C207.516 218.773 206.605 218.592 205.759 218.236L207.42 216.363L205.451 218.311Z"
          fill="#F5F5F5"
        />
        <path
          d="M211.402 218.041L212.943 218.738L215.017 217.734L212.943 218.933L211.402 218.041Z"
          fill="#F5F5F5"
        />
        <path
          d="M193.068 207.75C198.531 204.439 207.037 204.117 207.037 204.117C201.687 204.117 196.675 205.308 192.91 207.473C189.303 209.547 187.327 212.244 187.327 215.083C187.327 215.083 187.523 211.105 193.068 207.75Z"
          fill="#F5F5F5"
        />
        <path
          d="M205.451 226.909C211.237 227.171 217.188 226.025 221.614 223.471C226.22 220.82 228.196 217.225 227.528 213.734C227.528 213.734 228.384 219.367 221.268 223.224C214.153 227.082 205.451 226.909 205.451 226.909Z"
          fill="#F5F5F5"
        />
        <path
          opacity="0.3"
          d="M193.368 215.031C193.369 214.8 193.389 214.57 193.428 214.342C193.428 214.274 193.465 214.207 193.48 214.14C193.519 213.967 193.566 213.797 193.623 213.63C193.623 213.548 193.698 213.458 193.736 213.376C193.793 213.228 193.861 213.086 193.939 212.949C193.991 212.851 194.052 212.761 194.104 212.672C194.185 212.54 194.273 212.412 194.367 212.29L194.615 211.975L194.916 211.638C195.021 211.541 195.119 211.436 195.231 211.338C195.331 211.24 195.437 211.148 195.547 211.061C195.682 210.949 195.817 210.836 195.968 210.732L196.238 210.537C196.426 210.41 196.614 210.282 196.817 210.162L193.676 207.039C193.405 207.189 193.15 207.324 192.924 207.473C191.432 208.292 190.107 209.382 189.017 210.687L193.375 215.031H193.368Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M209.058 222.353H208.442H208.306C207.886 222.353 207.465 222.353 207.037 222.353H206.901C206.36 222.353 205.834 222.353 205.308 222.293L204.82 222.256C204.264 222.204 203.715 222.151 203.174 222.061C202.618 221.971 202.077 221.859 201.544 221.739L201.183 221.649C200.665 221.522 200.161 221.38 199.68 221.215L204.361 225.881C205.248 225.956 206.157 225.993 207.082 225.993C208.865 225.995 210.647 225.858 212.409 225.582L209.11 222.293L209.058 222.353Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M199.447 213.774C199.447 214.366 199.898 214.935 200.815 215.46C201.443 215.831 202.141 216.068 202.866 216.156L199.673 212.98C199.52 213.217 199.441 213.493 199.447 213.774Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M226.753 215.084C226.753 212.252 224.769 209.549 221.17 207.474C219.539 206.554 217.804 205.832 216 205.324L226.693 215.983C226.732 215.684 226.752 215.384 226.753 215.084Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M207.037 207.74C207.322 207.74 207.6 207.74 207.886 207.74H208.389L209.036 207.793C209.276 207.793 209.517 207.793 209.75 207.853C209.982 207.913 210.118 207.898 210.298 207.92L211.049 208.025L211.523 208.115L212.334 208.28H212.462L208.239 204.07C207.826 204.07 207.42 204.07 207.007 204.07C205.605 204.071 204.205 204.156 202.814 204.325L206.218 207.718L207.037 207.74Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M220.472 216.467C220.472 216.542 220.419 216.609 220.397 216.684C220.32 216.871 220.232 217.054 220.134 217.231L220.021 217.418C219.923 217.583 219.811 217.748 219.69 217.912L219.578 218.055C219.435 218.233 219.282 218.404 219.119 218.564L218.977 218.706C218.789 218.886 218.593 219.058 218.375 219.231L218.248 219.328L218.03 219.493L221.216 222.669C222.393 222.006 223.471 221.182 224.417 220.219L220.517 216.332L220.472 216.467Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M214.678 215.031C214.682 214.976 214.682 214.921 214.678 214.866C214.625 214.304 214.212 213.757 213.348 213.263C212.657 212.868 211.887 212.63 211.094 212.566L214.49 215.952C214.627 215.665 214.691 215.348 214.678 215.031Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M201.378 208.395H201.423L198.32 205.234C197.236 205.534 196.174 205.907 195.142 206.35L198.23 209.429C199.245 208.988 200.299 208.642 201.378 208.395Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M214.273 221.33C213.837 221.48 213.386 221.608 212.92 221.72L212.537 221.817C212.116 221.915 211.688 221.997 211.245 222.072L210.952 222.132L214.145 225.315C215.278 225.07 216.395 224.755 217.489 224.371L214.408 221.293L214.273 221.33Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M207.389 216.363L206.262 217.479L207.547 218.76C207.835 218.779 208.123 218.779 208.411 218.76C209.368 218.697 210.307 218.477 211.191 218.108L209.576 216.498C208.851 216.689 208.085 216.641 207.389 216.363Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M208.224 215.138L206.518 213.475L206.42 213.513C205.821 213.74 205.202 213.916 204.572 214.037C204.311 214.083 204.044 214.083 203.783 214.037C203.652 214 203.526 213.947 203.407 213.88C202.949 213.61 202.874 213.206 203.745 212.704C204.201 212.429 204.717 212.27 205.248 212.24L203.565 210.479C203.272 210.569 202.987 210.674 202.686 210.794L201.146 209.91L199.598 210.801L204.91 216.097C205.508 215.991 206.098 215.844 206.676 215.655C207.239 215.483 207.75 215.288 208.224 215.138Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M209.711 204.211L214.393 208.87C215.129 209.115 215.848 209.413 216.542 209.761C219.232 211.139 220.72 213.034 220.72 215.079V215.176L225.055 219.506C225.396 219.107 225.69 218.673 225.934 218.21L212.176 204.481C211.372 204.361 210.545 204.271 209.711 204.211Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M192.917 222.697C193.496 223.026 194.105 223.341 194.743 223.625L187.47 216.375C187.966 218.712 189.859 220.959 192.917 222.697Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M187.334 214.889L196.975 224.507C197.96 224.834 198.964 225.107 199.98 225.323L187.672 213.047C187.467 213.641 187.353 214.262 187.334 214.889Z"
          fill="white"
        />
        <g opacity="0.15">
          <path
            d="M189.01 210.69C190.1 209.384 191.425 208.295 192.918 207.476C193.173 207.327 193.429 207.192 193.669 207.042L193.008 206.383C192.812 206.488 192.625 206.585 192.437 206.697C190.877 207.559 189.487 208.696 188.334 210.053L188.98 210.69H189.01Z"
            fill="white"
          />
          <path
            d="M212.364 225.648C210.602 225.924 208.82 226.062 207.037 226.06C206.112 226.06 205.203 226.023 204.316 225.948L205.278 226.907C207.911 227.043 210.55 226.879 213.145 226.42L212.394 225.671L212.364 225.648Z"
            fill="white"
          />
          <path
            d="M194.165 215.838C193.767 214.018 194.834 212.146 197.359 210.693L196.84 210.176C196.637 210.296 196.449 210.423 196.262 210.55C196.074 210.678 196.074 210.678 195.991 210.745C195.908 210.812 195.706 210.962 195.57 211.075C195.435 211.187 195.352 211.254 195.255 211.352C195.157 211.449 195.044 211.554 194.939 211.651L194.639 211.988L194.406 212.28C194.311 212.403 194.223 212.531 194.143 212.662C194.09 212.752 194.03 212.842 193.977 212.94C193.899 213.077 193.831 213.219 193.774 213.367C193.737 213.449 193.692 213.539 193.662 213.621C193.605 213.788 193.557 213.958 193.519 214.131C193.519 214.198 193.481 214.265 193.466 214.333C193.427 214.563 193.407 214.796 193.406 215.029L194.165 215.838Z"
            fill="white"
          />
          <path
            d="M208.389 221.715C204.961 221.932 201.53 221.342 198.373 219.992L199.665 221.28C200.146 221.445 200.657 221.588 201.168 221.715L201.529 221.805C202.062 221.925 202.603 222.037 203.16 222.127C203.701 222.217 204.249 222.269 204.805 222.322L205.294 222.359C205.82 222.359 206.353 222.411 206.887 222.419H207.022C207.45 222.419 207.871 222.419 208.292 222.419H208.427L209.036 222.374L208.389 221.715Z"
            fill="white"
          />
          <path
            d="M221.171 207.486C224.77 209.561 226.754 212.265 226.754 215.096C226.753 215.397 226.733 215.697 226.694 215.995L227.445 216.744C228.317 213.179 226.363 209.434 221.614 206.7C219.463 205.505 217.15 204.625 214.747 204.086L215.994 205.329C217.8 205.839 219.538 206.563 221.171 207.486Z"
            fill="white"
          />
          <path
            d="M207.037 204.113C207.45 204.113 207.856 204.113 208.269 204.113L207.39 203.229C205.613 203.208 203.837 203.323 202.078 203.573L202.874 204.367C204.255 204.2 205.645 204.115 207.037 204.113Z"
            fill="white"
          />
          <path
            d="M221.216 222.68L221.862 223.324C223.033 222.656 224.103 221.827 225.041 220.86L224.409 220.23C223.465 221.192 222.39 222.017 221.216 222.68Z"
            fill="white"
          />
          <path
            d="M219.931 215.762C219.572 217.108 218.705 218.265 217.512 218.99L218.015 219.499L218.248 219.334L218.376 219.237C218.594 219.065 218.789 218.893 218.977 218.713L219.12 218.57C219.285 218.406 219.435 218.233 219.578 218.061L219.691 217.919C219.811 217.754 219.924 217.589 220.021 217.425L220.164 217.222C220.262 217.045 220.35 216.863 220.427 216.676C220.427 216.601 220.48 216.533 220.502 216.458C220.525 216.383 220.502 216.368 220.502 216.323L219.931 215.762Z"
            fill="white"
          />
          <path
            d="M206.255 207.77L206.947 208.459C209.159 208.442 211.363 208.739 213.491 209.342L212.5 208.354H212.364L211.553 208.189L211.079 208.099L210.328 207.994C210.148 207.994 209.967 207.942 209.779 207.927C209.592 207.912 209.306 207.882 209.066 207.867L208.419 207.814H207.916H207.067L206.255 207.77Z"
            fill="white"
          />
          <path
            d="M214.152 225.315L214.904 226.064C216.023 225.801 217.125 225.468 218.202 225.068L217.504 224.379C216.407 224.76 215.288 225.073 214.152 225.315Z"
            fill="white"
          />
          <path
            d="M198.32 205.237L197.569 204.488C196.499 204.802 195.453 205.187 194.436 205.642L195.119 206.323C196.16 205.889 197.23 205.526 198.32 205.237Z"
            fill="white"
          />
          <path
            d="M198.763 209.99C199.822 209.55 200.918 209.209 202.04 208.971L201.446 208.387H201.401C200.313 208.638 199.251 208.992 198.23 209.443L198.763 209.99Z"
            fill="white"
          />
          <path
            d="M213.837 220.727C212.686 221.077 211.507 221.327 210.313 221.476L210.952 222.112L211.245 222.052C211.688 221.977 212.116 221.895 212.537 221.798L212.92 221.7C213.386 221.588 213.837 221.453 214.273 221.311L214.401 221.273L213.837 220.727Z"
            fill="white"
          />
          <path
            d="M209.712 204.217C210.546 204.277 211.373 204.367 212.177 204.494L211.147 203.468C210.358 203.37 209.562 203.303 208.765 203.266L209.712 204.217Z"
            fill="white"
          />
          <path
            d="M226.589 218.859L225.928 218.207C225.684 218.67 225.389 219.105 225.048 219.503L225.695 220.139C226.025 219.736 226.324 219.308 226.589 218.859Z"
            fill="white"
          />
          <path
            d="M216.535 209.774C215.848 209.425 215.134 209.129 214.401 208.891L215.656 210.141C215.844 210.224 216.039 210.306 216.212 210.396C218.406 211.52 219.646 212.943 219.969 214.411L220.72 215.16C220.72 215.16 220.72 215.16 220.72 215.107V215.07C220.713 213.04 219.225 211.145 216.535 209.774Z"
            fill="white"
          />
          <path
            d="M192.918 222.703C189.86 220.943 187.966 218.733 187.47 216.381L186.433 215.348C186.546 218.299 188.56 221.227 192.444 223.474C193.68 224.177 194.978 224.767 196.322 225.234L194.721 223.632C194.105 223.347 193.496 223.032 192.918 222.703Z"
            fill="white"
          />
          <path
            d="M196.976 224.508L198.321 225.841C199.23 226.088 200.162 226.283 201.116 226.455L199.981 225.324C198.965 225.108 197.961 224.836 196.976 224.508Z"
            fill="white"
          />
          <path
            d="M187.673 213.048L186.982 212.352C186.751 212.898 186.592 213.472 186.508 214.059L187.335 214.891C187.354 214.263 187.468 213.642 187.673 213.048Z"
            fill="white"
          />
        </g>
        <path
          d="M224.529 209.27C223.299 207.365 221.602 205.803 219.6 204.731C211.552 200.095 198.5 200.095 190.452 204.731C188.448 205.801 186.752 207.363 185.523 209.27H184.411V213.472C184.561 216.386 186.56 219.284 190.422 221.509C198.47 226.145 211.522 226.145 219.57 221.509C223.439 219.262 225.461 216.386 225.581 213.472V209.27H224.529Z"
          fill="#42CE8F"
        />
        <g opacity="0.25">
          <path
            d="M224.529 209.266C223.299 207.361 221.602 205.799 219.6 204.727C211.552 200.091 198.5 200.091 190.452 204.727C188.448 205.797 186.752 207.359 185.523 209.266H184.411V213.468C184.561 216.382 186.56 219.28 190.422 221.505C198.47 226.141 211.522 226.141 219.57 221.505C223.439 219.258 225.461 216.382 225.581 213.468V209.266H224.529Z"
            fill="black"
          />
        </g>
        <path
          opacity="0.1"
          d="M205.03 201.258C199.77 201.258 194.473 202.419 190.452 204.733C188.448 205.803 186.752 207.365 185.523 209.272H184.411V213.474C184.561 216.388 186.56 219.286 190.422 221.511C194.442 223.825 199.717 224.986 205 224.986L205.03 201.258Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M193.766 203.176C192.622 203.603 191.513 204.121 190.452 204.726C188.448 205.796 186.752 207.358 185.523 209.265H184.411V213.467C184.561 216.381 186.56 219.279 190.422 221.504C191.483 222.109 192.592 222.627 193.736 223.054L193.766 203.176Z"
          fill="black"
        />
        <path
          opacity="0.5"
          d="M216.286 203.176C217.431 203.603 218.539 204.121 219.6 204.726C221.602 205.798 223.299 207.36 224.529 209.265H225.641V213.467C225.491 216.381 223.47 219.279 219.63 221.504C218.569 222.109 217.461 222.627 216.316 223.054L216.286 203.176Z"
          fill="#42CE8F"
        />
        <path
          d="M219.6 200.882C211.553 196.253 198.501 196.253 190.453 200.882C182.405 205.511 182.398 213.031 190.453 217.659C198.508 222.288 211.553 222.296 219.6 217.659C227.648 213.023 227.678 205.518 219.6 200.882Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.2"
          d="M219.6 200.882C211.553 196.253 198.501 196.253 190.453 200.882C182.405 205.511 182.398 213.031 190.453 217.659C198.508 222.288 211.553 222.296 219.6 217.659C227.648 213.023 227.678 205.518 219.6 200.882Z"
          fill="black"
        />
        <path
          d="M214.52 203.954C211.533 202.624 208.298 201.938 205.026 201.938C201.755 201.938 198.52 202.624 195.532 203.954C192.834 205.332 191.347 207.227 191.347 209.279C191.347 211.331 192.85 213.211 195.532 214.589C198.518 215.923 201.754 216.612 205.026 216.612C208.298 216.612 211.534 215.923 214.52 214.589C217.21 213.211 218.698 211.324 218.698 209.279C218.698 207.234 217.21 205.332 214.52 203.954Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.4"
          d="M214.52 203.954C211.533 202.624 208.298 201.938 205.026 201.938C201.755 201.938 198.52 202.624 195.532 203.954C192.834 205.332 191.347 207.227 191.347 209.279C191.347 211.331 192.85 213.211 195.532 214.589C198.518 215.923 201.754 216.612 205.026 216.612C208.298 216.612 211.534 215.923 214.52 214.589C217.21 213.211 218.698 211.324 218.698 209.279C218.698 207.234 217.21 205.332 214.52 203.954Z"
          fill="black"
        />
        <path
          d="M219.157 201.664C215.4 199.5 210.373 198.309 205.023 198.309C199.672 198.309 194.661 199.5 190.896 201.664C187.297 203.739 185.313 206.435 185.313 209.281C185.313 212.128 187.297 214.817 190.896 216.891C194.653 219.048 199.672 220.247 205.023 220.247C210.373 220.247 215.392 219.048 219.157 216.891C222.756 214.817 224.74 212.113 224.74 209.281C224.74 206.45 222.756 203.739 219.157 201.664ZM214.52 214.592C211.534 215.925 208.299 216.614 205.026 216.614C201.754 216.614 198.519 215.925 195.532 214.592C192.835 213.214 191.347 211.326 191.347 209.281C191.347 207.237 192.85 205.334 195.532 203.956C198.52 202.627 201.755 201.94 205.026 201.94C208.298 201.94 211.533 202.627 214.52 203.956C217.21 205.334 218.698 207.229 218.698 209.281C218.698 211.334 217.21 213.214 214.52 214.592Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.2"
          d="M205.022 215.898C212.181 215.898 217.984 212.93 217.984 209.269C217.984 205.608 212.181 202.641 205.022 202.641C197.864 202.641 192.061 205.608 192.061 209.269C192.061 212.93 197.864 215.898 205.022 215.898Z"
          fill="black"
        />
        <path
          d="M205.022 215.898C212.181 215.898 217.984 212.93 217.984 209.269C217.984 205.608 212.181 202.641 205.022 202.641C197.864 202.641 192.061 205.608 192.061 209.269C192.061 212.93 197.864 215.898 205.022 215.898Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.6"
          d="M205.022 215.898C212.181 215.898 217.984 212.93 217.984 209.269C217.984 205.608 212.181 202.641 205.022 202.641C197.864 202.641 192.061 205.608 192.061 209.269C192.061 212.93 197.864 215.898 205.022 215.898Z"
          fill="black"
        />
        <path
          d="M214.19 206.342C209.133 203.751 200.92 203.751 195.856 206.342C193.767 207.413 192.542 208.754 192.174 210.147C192.542 211.548 193.767 212.888 195.856 213.959C200.92 216.551 209.133 216.551 214.198 213.959C216.287 212.888 217.511 211.548 217.872 210.155C217.511 208.754 216.287 207.413 214.19 206.342Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.5"
          d="M214.19 206.342C209.133 203.751 200.92 203.751 195.856 206.342C193.767 207.413 192.542 208.754 192.174 210.147C192.542 211.548 193.767 212.888 195.856 213.959C200.92 216.551 209.133 216.551 214.198 213.959C216.287 212.888 217.511 211.548 217.872 210.155C217.511 208.754 216.287 207.413 214.19 206.342Z"
          fill="black"
        />
        <path
          d="M206.255 204.688V206.582L205.601 207.264L204.406 208.515V206.545L206.255 204.688Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.3"
          d="M206.255 204.688V206.582L205.601 207.264L204.406 208.515V206.545L206.255 204.688Z"
          fill="black"
        />
        <path
          d="M204.384 206.541V207.657C203.784 207.885 203.166 208.061 202.535 208.181C202.275 208.235 202.007 208.235 201.746 208.181C201.616 208.143 201.49 208.09 201.371 208.024C200.912 207.754 200.837 207.35 201.709 206.848C202.1 206.593 202.546 206.432 203.01 206.379C203.474 206.326 203.945 206.381 204.384 206.541Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.45"
          d="M204.384 206.541V207.657C203.784 207.885 203.166 208.061 202.535 208.181C202.275 208.235 202.007 208.235 201.746 208.181C201.616 208.143 201.49 208.09 201.371 208.024C200.912 207.754 200.837 207.35 201.709 206.848C202.1 206.593 202.546 206.432 203.01 206.379C203.474 206.326 203.945 206.381 204.384 206.541Z"
          fill="black"
        />
        <path
          d="M213.01 211.923V213.555L210.929 214.754L209.426 213.87C207.553 214.738 205.412 214.835 203.467 214.14V212.499L204.414 211.563L203.467 211.825C202.698 212.046 201.892 212.113 201.096 212.022C200.3 211.93 199.53 211.683 198.831 211.293C197.899 210.762 197.464 210.17 197.464 209.571V207.975L197.636 207.279L197.065 206.949V205.316L198.568 206.2C198.256 206.387 197.99 206.643 197.792 206.948C197.595 207.253 197.469 207.6 197.426 207.96C197.426 208.552 197.884 209.121 198.794 209.646C200.815 210.807 202.821 210.395 204.655 209.84C206.36 209.279 207.6 208.627 208.585 209.196C209.201 209.548 209.013 209.945 208.329 210.372C207.884 210.615 207.39 210.757 206.883 210.787C206.376 210.817 205.869 210.734 205.399 210.544L203.43 212.484C203.783 212.62 204.148 212.725 204.519 212.799C206.164 213.115 207.866 212.911 209.389 212.215L210.891 213.106L213.01 211.923Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.35"
          d="M197.434 207.963C197.434 208.555 197.892 209.124 198.801 209.648C200.822 210.809 202.829 210.397 204.662 209.843C206.368 209.281 207.608 208.629 208.592 209.199C209.208 209.551 209.02 209.948 208.337 210.375C207.891 210.618 207.398 210.76 206.891 210.789C206.384 210.819 205.877 210.736 205.406 210.547L203.437 212.487C203.791 212.623 204.155 212.728 204.527 212.801C206.171 213.118 207.874 212.914 209.396 212.217L210.899 213.108L212.98 211.91V213.543L210.899 214.741L209.396 213.857C207.523 214.725 205.382 214.822 203.437 214.127V212.502L204.384 211.566L203.437 211.828C202.668 212.049 201.862 212.115 201.066 212.024C200.27 211.933 199.5 211.685 198.801 211.296C197.869 210.764 197.434 210.172 197.434 209.573V207.978L197.606 207.281"
          fill="black"
        />
        <path
          opacity="0.45"
          d="M197.035 205.316V206.949L197.606 207.279C197.848 206.85 198.182 206.479 198.583 206.193L197.035 205.316Z"
          fill="black"
        />
        <path
          d="M212.649 210.853C212.65 211.111 212.596 211.366 212.492 211.602L211.455 211.01C211.768 210.868 212.04 210.649 212.246 210.374C212.452 210.099 212.586 209.777 212.634 209.438C212.649 209.917 212.649 210.808 212.649 210.853Z"
          fill="#42CE8F"
        />
        <g opacity="0.3">
          <path
            d="M212.649 210.849C212.65 211.107 212.596 211.362 212.492 211.598L211.455 211.007C211.768 210.864 212.04 210.645 212.246 210.37C212.452 210.096 212.586 209.773 212.634 209.434C212.649 209.913 212.649 210.804 212.649 210.849Z"
            fill="black"
          />
        </g>
        <path
          d="M212.649 211.727L211.454 211.038C211.807 210.879 212.107 210.624 212.318 210.301C212.53 209.979 212.645 209.603 212.649 209.218C212.653 209.165 212.653 209.113 212.649 209.06C212.575 208.705 212.415 208.372 212.183 208.092C211.951 207.811 211.655 207.591 211.319 207.45C210.48 206.962 209.526 206.703 208.554 206.701H208.291H208.043H207.833H207.742H207.577C207.123 206.765 206.674 206.858 206.232 206.978L205.601 207.165L204.737 207.442L204.406 207.562C203.808 207.792 203.189 207.965 202.558 208.079C202.298 208.137 202.029 208.137 201.769 208.079C201.637 208.047 201.511 207.996 201.393 207.929C200.935 207.66 200.86 207.255 201.731 206.753C202.126 206.506 202.573 206.352 203.036 206.304C203.5 206.256 203.969 206.315 204.406 206.476L206.255 204.619C204.418 204.041 202.433 204.15 200.672 204.926L199.124 204.109L197.027 205.315L198.53 206.199C198.218 206.386 197.952 206.642 197.754 206.947C197.556 207.252 197.431 207.598 197.388 207.959C197.388 208.551 197.839 209.12 198.756 209.645C200.777 210.813 202.783 210.394 204.617 209.832C206.322 209.277 207.562 208.626 208.546 209.195C209.163 209.547 208.975 209.944 208.291 210.371C207.847 210.617 207.352 210.759 206.845 210.788C206.337 210.816 205.83 210.73 205.36 210.536L203.392 212.483C203.744 212.617 204.106 212.725 204.474 212.805C205.099 212.934 205.738 212.98 206.375 212.94C207.414 212.872 208.432 212.618 209.381 212.191L210.883 213.082L212.965 211.884L212.649 211.727Z"
          fill="#42CE8F"
        />
        <path
          d="M209.396 212.23V213.871L210.928 214.755V213.122L209.396 212.23Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.45"
          d="M209.396 212.23V213.871L210.928 214.755V213.122L209.396 212.23Z"
          fill="black"
        />
        <path
          d="M197.035 205.315L199.132 204.109L200.672 204.993L199.147 204.357L197.035 205.315Z"
          fill="#F5F5F5"
        />
        <path
          d="M201.731 206.822C202.126 206.575 202.572 206.421 203.036 206.373C203.5 206.325 203.969 206.384 204.406 206.545L206.254 204.688L204.383 206.35C203.937 206.2 203.46 206.165 202.997 206.248C202.533 206.33 202.098 206.528 201.731 206.822Z"
          fill="#F5F5F5"
        />
        <path
          d="M203.437 212.494C203.789 212.628 204.151 212.736 204.519 212.816C205.144 212.946 205.783 212.991 206.42 212.951C205.501 212.957 204.591 212.776 203.745 212.419L205.406 210.547L203.437 212.494Z"
          fill="#F5F5F5"
        />
        <path
          d="M209.396 212.229L210.928 212.926L213.01 211.922L210.928 213.12L209.396 212.229Z"
          fill="#F5F5F5"
        />
        <path
          d="M191.054 201.941C196.517 198.631 205.023 198.309 205.023 198.309C199.672 198.309 194.661 199.5 190.896 201.664C187.297 203.739 185.313 206.435 185.313 209.281C185.313 209.281 185.508 205.304 191.054 201.941Z"
          fill="#F5F5F5"
        />
        <path
          d="M203.437 221.102C209.23 221.357 215.174 220.211 219.6 217.657C224.206 215.005 226.182 211.417 225.513 207.957C225.513 207.957 226.378 213.59 219.254 217.447C212.131 221.304 203.437 221.102 203.437 221.102Z"
          fill="#F5F5F5"
        />
        <path
          opacity="0.3"
          d="M191.354 209.219C191.355 208.988 191.375 208.758 191.414 208.53C191.414 208.463 191.414 208.395 191.467 208.336C191.504 208.161 191.551 207.988 191.609 207.819C191.609 207.736 191.685 207.646 191.722 207.564C191.782 207.418 191.85 207.276 191.925 207.137C191.978 207.04 192.038 206.95 192.09 206.86C192.171 206.728 192.259 206.601 192.353 206.478L192.586 206.186L192.887 205.849C192.992 205.752 193.09 205.647 193.202 205.549L193.518 205.272C193.653 205.16 193.789 205.047 193.939 204.943L194.217 204.748C194.397 204.621 194.585 204.501 194.788 204.373L191.647 201.25C191.377 201.4 191.121 201.535 190.896 201.684C189.403 202.503 188.078 203.592 186.988 204.898L191.346 209.234L191.354 209.219Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M207.044 216.543H206.428H206.293C205.872 216.543 205.451 216.58 205.023 216.58H204.888C204.354 216.58 203.821 216.58 203.295 216.52L202.806 216.483C202.25 216.438 201.702 216.378 201.161 216.296C200.597 216.206 200.056 216.093 199.522 215.974L199.169 215.884C198.643 215.756 198.14 215.614 197.667 215.449L202.348 220.115C203.242 220.19 204.144 220.228 205.068 220.228C206.852 220.23 208.633 220.092 210.395 219.816L207.097 216.528L207.044 216.543Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M197.433 207.958C197.433 208.55 197.884 209.119 198.801 209.643C199.428 210.017 200.127 210.255 200.852 210.34L197.666 207.164C197.509 207.399 197.428 207.676 197.433 207.958Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M224.74 209.267C224.74 206.436 222.756 203.732 219.157 201.657C217.526 200.737 215.79 200.016 213.987 199.508L224.672 210.166C224.717 209.869 224.74 209.568 224.74 209.267Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M205.023 201.932C205.308 201.932 205.586 201.932 205.872 201.932H206.375L207.029 201.984C207.262 201.984 207.502 201.984 207.735 202.044C207.968 202.104 208.104 202.089 208.284 202.112C208.464 202.134 208.81 202.179 209.035 202.224L209.509 202.306L210.32 202.471H210.448L206.225 198.262C205.812 198.262 205.406 198.262 204.993 198.262C203.591 198.262 202.191 198.347 200.8 198.516L204.204 201.909L205.023 201.932Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M218.458 210.655C218.458 210.73 218.406 210.797 218.383 210.872C218.308 211.059 218.218 211.239 218.12 211.426C218.12 211.486 218.045 211.546 218.007 211.606C217.97 211.666 217.797 211.935 217.677 212.1L217.564 212.243C217.422 212.421 217.268 212.591 217.106 212.752L216.963 212.894C216.775 213.074 216.58 213.246 216.362 213.418L216.234 213.516L216.016 213.688L219.202 216.864C220.379 216.202 221.457 215.377 222.403 214.415L218.503 210.527L218.458 210.655Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M212.649 209.218C212.654 209.166 212.654 209.113 212.649 209.061C212.575 208.705 212.415 208.373 212.183 208.092C211.951 207.812 211.655 207.592 211.319 207.45C210.628 207.056 209.859 206.818 209.065 206.754L212.461 210.139C212.598 209.852 212.662 209.536 212.649 209.218Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M199.387 202.58H199.432L196.306 199.457C195.223 199.758 194.161 200.131 193.127 200.573L196.216 203.651C197.237 203.196 198.298 202.837 199.387 202.58Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M212.259 215.514C211.823 215.664 211.373 215.791 210.907 215.903L210.523 216.001C210.103 216.098 209.674 216.181 209.231 216.256L208.938 216.315L212.131 219.499C213.265 219.254 214.381 218.938 215.475 218.555L212.394 215.477L212.259 215.514Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M205.399 210.547L204.271 211.663L205.556 212.944C205.844 212.962 206.133 212.962 206.421 212.944C207.377 212.88 208.316 212.66 209.201 212.292L207.585 210.682C206.861 210.872 206.094 210.825 205.399 210.547Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M206.21 209.322L204.505 207.622L204.407 207.66C203.807 207.888 203.189 208.064 202.558 208.184C202.298 208.238 202.03 208.238 201.769 208.184C201.639 208.146 201.513 208.093 201.394 208.027C200.935 207.757 200.86 207.353 201.732 206.851C202.188 206.577 202.703 206.418 203.235 206.386L201.551 204.709C201.258 204.798 200.973 204.903 200.68 205.023L199.124 204.109L197.622 205.001L202.942 210.304C203.537 210.192 204.124 210.042 204.7 209.854C205.226 209.667 205.737 209.472 206.21 209.322Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M207.698 198.402L212.372 203.061C213.108 203.307 213.826 203.605 214.521 203.952C217.211 205.331 218.698 207.226 218.698 209.278V209.368L223.034 213.697C223.375 213.296 223.669 212.859 223.913 212.394L210.162 198.679C209.358 198.552 208.532 198.492 207.698 198.402Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M190.904 216.888C191.482 217.218 192.091 217.532 192.73 217.817L185.456 210.566C185.952 212.918 187.853 215.128 190.904 216.888Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M185.32 209.078L194.961 218.718C195.947 219.045 196.95 219.318 197.967 219.534L185.658 207.258C185.456 207.844 185.342 208.458 185.32 209.078Z"
          fill="white"
        />
        <g opacity="0.15">
          <path
            d="M186.996 204.873C188.086 203.568 189.411 202.478 190.903 201.66C191.159 201.51 191.414 201.375 191.655 201.226L190.994 200.566C190.798 200.671 190.61 200.769 190.423 200.881C188.863 201.743 187.473 202.88 186.32 204.237L186.966 204.881L186.996 204.873Z"
            fill="white"
          />
          <path
            d="M210.35 219.828C208.588 220.104 206.806 220.242 205.022 220.24C204.098 220.24 203.196 220.203 202.302 220.128L203.257 221.086C205.891 221.222 208.533 221.059 211.131 220.6L210.38 219.851L210.35 219.828Z"
            fill="white"
          />
          <path
            d="M192.151 210.014C191.753 208.194 192.82 206.321 195.345 204.868L194.826 204.352C194.623 204.471 194.435 204.599 194.255 204.726L193.977 204.921C193.827 205.026 193.691 205.138 193.556 205.25C193.421 205.363 193.338 205.43 193.241 205.527C193.143 205.625 193.03 205.73 192.925 205.827L192.624 206.164L192.391 206.456C192.297 206.579 192.209 206.706 192.128 206.838C192.076 206.928 192.016 207.018 191.963 207.115C191.888 207.254 191.82 207.397 191.76 207.542C191.723 207.625 191.678 207.715 191.648 207.797C191.59 207.966 191.542 208.139 191.505 208.314C191.505 208.374 191.467 208.441 191.452 208.509C191.414 208.739 191.393 208.972 191.392 209.205L192.151 210.014Z"
            fill="white"
          />
          <path
            d="M206.375 215.869C202.939 216.102 199.497 215.522 196.329 214.176L197.621 215.464C198.102 215.629 198.613 215.771 199.124 215.898L199.477 215.988C200.011 216.108 200.552 216.221 201.115 216.31C201.656 216.393 202.205 216.445 202.761 216.498L203.249 216.535C203.775 216.535 204.309 216.588 204.842 216.595H204.977C205.406 216.595 205.826 216.595 206.247 216.595H206.383L206.991 216.55L206.375 215.869Z"
            fill="white"
          />
          <path
            d="M219.157 201.662C222.756 203.737 224.74 206.441 224.74 209.272C224.743 209.573 224.723 209.873 224.68 210.171L225.431 210.92C226.303 207.355 224.349 203.61 219.6 200.876C217.449 199.68 215.136 198.799 212.732 198.262L213.98 199.505C215.786 200.015 217.524 200.739 219.157 201.662Z"
            fill="white"
          />
          <path
            d="M205.022 198.295C205.436 198.295 205.841 198.295 206.255 198.295L205.345 197.389C203.571 197.369 201.797 197.484 200.041 197.734L200.837 198.528C202.226 198.366 203.624 198.289 205.022 198.295Z"
            fill="white"
          />
          <path
            d="M219.202 216.863L219.848 217.507C221.02 216.838 222.092 216.008 223.034 215.043L222.403 214.414C221.457 215.376 220.379 216.201 219.202 216.863Z"
            fill="white"
          />
          <path
            d="M217.909 209.945C217.549 211.289 216.682 212.443 215.49 213.166L216.001 213.675C216.068 213.615 216.151 213.57 216.226 213.511C216.272 213.482 216.315 213.45 216.354 213.413C216.572 213.241 216.767 213.069 216.955 212.889L217.098 212.747C217.261 212.586 217.414 212.416 217.556 212.237L217.669 212.095C217.789 211.93 217.902 211.765 217.999 211.601C218.097 211.436 218.082 211.481 218.112 211.421C218.21 211.234 218.3 211.054 218.375 210.867C218.375 210.792 218.428 210.724 218.45 210.649C218.473 210.574 218.45 210.56 218.45 210.515L217.909 209.945Z"
            fill="white"
          />
          <path
            d="M204.241 201.953L204.933 202.642C207.145 202.625 209.349 202.923 211.477 203.526L210.485 202.537H210.35L209.539 202.373L209.065 202.29C208.81 202.245 208.547 202.215 208.314 202.178C208.081 202.14 207.953 202.125 207.765 202.11C207.577 202.095 207.292 202.065 207.059 202.05L206.405 201.998H205.887C205.601 201.998 205.323 201.998 205.038 201.998L204.241 201.953Z"
            fill="white"
          />
          <path
            d="M212.131 219.499L212.883 220.248C214.002 219.985 215.104 219.652 216.181 219.252L215.483 218.562C214.386 218.944 213.267 219.256 212.131 219.499Z"
            fill="white"
          />
          <path
            d="M196.306 199.44L195.555 198.691C194.483 199.004 193.433 199.389 192.414 199.845L193.098 200.526C194.142 200.093 195.214 199.73 196.306 199.44Z"
            fill="white"
          />
          <path
            d="M196.749 204.169C197.807 203.73 198.904 203.389 200.025 203.151L199.439 202.566H199.387C198.299 202.818 197.237 203.171 196.216 203.623L196.749 204.169Z"
            fill="white"
          />
          <path
            d="M211.822 214.906C210.672 215.256 209.492 215.507 208.298 215.655L208.937 216.292L209.23 216.232C209.673 216.157 210.102 216.075 210.523 215.977L210.906 215.88C211.372 215.768 211.822 215.633 212.258 215.49L212.386 215.453L211.822 214.906Z"
            fill="white"
          />
          <path
            d="M207.697 198.4C208.532 198.46 209.358 198.55 210.162 198.678L209.125 197.651C208.336 197.554 207.54 197.487 206.743 197.449L207.697 198.4Z"
            fill="white"
          />
          <path
            d="M224.574 213.046L223.913 212.395C223.669 212.858 223.377 213.295 223.042 213.698L223.688 214.334C224.016 213.928 224.312 213.498 224.574 213.046Z"
            fill="white"
          />
          <path
            d="M214.521 203.958C213.834 203.608 213.12 203.313 212.387 203.074L213.642 204.325C213.829 204.407 214.025 204.49 214.198 204.58C216.392 205.703 217.632 207.126 217.955 208.594L218.706 209.343C218.706 209.343 218.706 209.306 218.706 209.291V209.253C218.699 207.224 217.211 205.329 214.521 203.958Z"
            fill="white"
          />
          <path
            d="M190.904 216.883C187.845 215.122 185.952 212.913 185.456 210.561L184.419 209.527C184.532 212.478 186.545 215.407 190.43 217.654C191.668 218.359 192.969 218.948 194.315 219.414L192.707 217.811C192.091 217.527 191.482 217.212 190.904 216.883Z"
            fill="white"
          />
          <path
            d="M194.961 218.715L196.306 220.048C197.216 220.295 198.155 220.49 199.102 220.662L197.967 219.531C196.951 219.315 195.947 219.043 194.961 218.715Z"
            fill="white"
          />
          <path
            d="M185.658 207.232L184.967 206.543C184.736 207.087 184.577 207.658 184.494 208.243L185.32 209.075C185.339 208.447 185.453 207.826 185.658 207.232Z"
            fill="white"
          />
        </g>
        <path
          d="M256.937 242.34C255.716 240.43 254.021 238.867 252.016 237.801C243.968 233.165 230.916 233.165 222.861 237.801C220.856 238.867 219.161 240.43 217.939 242.34H216.827V246.535C216.97 249.456 218.976 252.347 222.838 254.579C230.894 259.208 243.946 259.208 251.993 254.579C255.863 252.332 257.884 249.456 258.004 246.535V242.34H256.937Z"
          fill="#42CE8F"
        />
        <g opacity="0.25">
          <path
            d="M256.937 242.336C255.716 240.427 254.021 238.863 252.016 237.798C243.968 233.161 230.916 233.161 222.861 237.798C220.856 238.863 219.161 240.427 217.939 242.336H216.827V246.531C216.97 249.452 218.976 252.343 222.838 254.575C230.894 259.204 243.946 259.204 251.993 254.575C255.863 252.328 257.884 249.452 258.004 246.531V242.336H256.937Z"
            fill="black"
          />
        </g>
        <path
          opacity="0.1"
          d="M237.446 234.324C232.186 234.324 226.926 235.478 222.868 237.8C220.863 238.865 219.168 240.429 217.947 242.339H216.834V246.533C216.977 249.454 218.984 252.345 222.846 254.577C226.926 256.914 232.186 258.053 237.446 258.053V234.324Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M226.174 236.25C225.027 236.677 223.917 237.196 222.853 237.8C220.848 238.866 219.153 240.429 217.931 242.339H216.819V246.534C216.962 249.455 218.968 252.346 222.831 254.578C223.9 255.197 225.019 255.728 226.174 256.166V236.25Z"
          fill="black"
        />
        <path
          opacity="0.5"
          d="M248.717 236.25C249.865 236.676 250.976 237.194 252.039 237.8C254.044 238.866 255.739 240.429 256.96 242.339H258.08V246.534C257.93 249.455 255.908 252.346 252.069 254.578C250.997 255.199 249.876 255.73 248.717 256.166V236.25Z"
          fill="#42CE8F"
        />
        <path
          d="M252.016 233.948C243.968 229.319 230.916 229.319 222.861 233.948C214.806 238.577 214.814 246.097 222.861 250.726C230.909 255.355 243.968 255.362 252.016 250.726C260.064 246.089 260.064 238.584 252.016 233.948Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.2"
          d="M252.016 233.948C243.968 229.319 230.916 229.319 222.861 233.948C214.806 238.577 214.814 246.097 222.861 250.726C230.909 255.355 243.968 255.362 252.016 250.726C260.064 246.089 260.064 238.584 252.016 233.948Z"
          fill="black"
        />
        <path
          d="M246.929 237.018C243.944 235.685 240.709 234.996 237.438 234.996C234.167 234.996 230.933 235.685 227.948 237.018C225.25 238.397 223.763 240.292 223.763 242.336C223.763 244.381 225.266 246.276 227.948 247.654C230.933 248.987 234.167 249.677 237.438 249.677C240.709 249.677 243.944 248.987 246.929 247.654C249.626 246.276 251.114 244.389 251.114 242.336C251.114 240.284 249.626 238.397 246.929 237.018Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.4"
          d="M246.929 237.018C243.944 235.685 240.709 234.996 237.438 234.996C234.167 234.996 230.933 235.685 227.948 237.018C225.25 238.397 223.763 240.292 223.763 242.336C223.763 244.381 225.266 246.276 227.948 247.654C230.933 248.987 234.167 249.677 237.438 249.677C240.709 249.677 243.944 248.987 246.929 247.654C249.626 246.276 251.114 244.389 251.114 242.336C251.114 240.284 249.626 238.397 246.929 237.018Z"
          fill="black"
        />
        <path
          d="M251.565 234.73C247.808 232.566 242.789 231.367 237.439 231.367C232.089 231.367 227.069 232.566 223.312 234.73C219.705 236.805 217.722 239.501 217.722 242.34C217.722 245.179 219.705 247.883 223.312 249.957C227.069 252.115 232.089 253.313 237.439 253.313C242.789 253.313 247.808 252.115 251.565 249.957C255.172 247.883 257.156 245.179 257.156 242.34C257.156 239.501 255.172 236.805 251.565 234.73ZM246.929 247.658C243.944 248.991 240.71 249.68 237.439 249.68C234.168 249.68 230.934 248.991 227.948 247.658C225.251 246.28 223.763 244.392 223.763 242.34C223.763 240.288 225.266 238.4 227.948 237.022C230.934 235.689 234.168 235 237.439 235C240.71 235 243.944 235.689 246.929 237.022C249.627 238.4 251.114 240.295 251.114 242.34C251.114 244.385 249.627 246.28 246.929 247.658Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.2"
          d="M237.439 248.964C244.598 248.964 250.401 245.997 250.401 242.336C250.401 238.675 244.598 235.707 237.439 235.707C230.28 235.707 224.477 238.675 224.477 242.336C224.477 245.997 230.28 248.964 237.439 248.964Z"
          fill="black"
        />
        <path
          d="M237.439 248.964C244.598 248.964 250.401 245.997 250.401 242.336C250.401 238.675 244.598 235.707 237.439 235.707C230.28 235.707 224.477 238.675 224.477 242.336C224.477 245.997 230.28 248.964 237.439 248.964Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.6"
          d="M237.439 248.964C244.598 248.964 250.401 245.997 250.401 242.336C250.401 238.675 244.598 235.707 237.439 235.707C230.28 235.707 224.477 238.675 224.477 242.336C224.477 245.997 230.28 248.964 237.439 248.964Z"
          fill="black"
        />
        <path
          d="M246.605 239.403C241.541 236.819 233.335 236.819 228.271 239.403C226.174 240.474 224.95 241.822 224.589 243.215C224.95 244.608 226.174 245.957 228.271 247.028C233.335 249.619 241.541 249.619 246.605 247.028C248.702 245.957 249.926 244.608 250.287 243.215C249.926 241.822 248.717 240.474 246.605 239.403Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.5"
          d="M246.605 239.403C241.541 236.819 233.335 236.819 228.271 239.403C226.174 240.474 224.95 241.822 224.589 243.215C224.95 244.608 226.174 245.957 228.271 247.028C233.335 249.619 241.541 249.619 246.605 247.028C248.702 245.957 249.926 244.608 250.287 243.215C249.926 241.822 248.717 240.474 246.605 239.403Z"
          fill="black"
        />
        <path
          d="M238.671 237.746V239.641L238.018 240.33L236.815 241.581V239.604L238.671 237.746Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.3"
          d="M238.671 237.746V239.641L238.018 240.33L236.815 241.581V239.604L238.671 237.746Z"
          fill="black"
        />
        <path
          d="M236.815 239.606V240.729C236.219 240.96 235.603 241.133 234.974 241.246C234.726 241.295 234.471 241.295 234.223 241.246C234.091 241.215 233.964 241.165 233.847 241.096C233.388 240.819 233.313 240.415 234.185 239.92C234.57 239.671 235.007 239.513 235.463 239.459C235.919 239.404 236.382 239.455 236.815 239.606Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.45"
          d="M236.815 239.606V240.729C236.219 240.96 235.603 241.133 234.974 241.246C234.726 241.295 234.471 241.295 234.223 241.246C234.091 241.215 233.964 241.165 233.847 241.096C233.388 240.819 233.313 240.415 234.185 239.92C234.57 239.671 235.007 239.513 235.463 239.459C235.919 239.404 236.382 239.455 236.815 239.606Z"
          fill="black"
        />
        <path
          d="M245.426 244.993V246.625L243.345 247.824L241.804 246.94C239.933 247.806 237.794 247.9 235.853 247.202V245.569L236.8 244.633L235.853 244.895C234.296 245.338 232.626 245.147 231.209 244.363C230.278 243.832 229.849 243.24 229.849 242.641V241.045L230.015 240.349L229.451 240.019V238.379L230.992 239.27C230.679 239.456 230.414 239.71 230.216 240.014C230.018 240.318 229.893 240.663 229.849 241.023C229.849 241.615 230.3 242.184 231.209 242.708C233.238 243.869 235.237 243.457 237.078 242.895C238.784 242.341 240.024 241.689 241.008 242.259C241.617 242.611 241.436 243.008 240.745 243.435C240.3 243.679 239.806 243.82 239.298 243.849C238.791 243.877 238.284 243.792 237.814 243.599L235.853 245.547C236.206 245.678 236.567 245.783 236.935 245.861C238.58 246.175 240.281 245.971 241.804 245.277L243.345 246.161L245.426 244.993Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.35"
          d="M229.849 241.021C229.849 241.612 230.3 242.182 231.209 242.706C233.238 243.867 235.237 243.455 237.078 242.893C238.783 242.339 240.023 241.687 241.008 242.257C241.616 242.609 241.436 243.006 240.745 243.433C240.3 243.677 239.805 243.818 239.298 243.847C238.791 243.875 238.284 243.79 237.814 243.597L235.853 245.545C236.205 245.676 236.567 245.781 236.935 245.859C238.579 246.173 240.281 245.969 241.804 245.275L243.344 246.159L245.426 244.968V246.601L243.344 247.799L241.804 246.915C239.932 247.781 237.794 247.875 235.853 247.178V245.545L236.8 244.609L235.853 244.871C234.295 245.313 232.625 245.122 231.209 244.339C230.277 243.807 229.849 243.215 229.849 242.616V241.021L230.014 240.324L229.849 241.021Z"
          fill="black"
        />
        <path
          opacity="0.45"
          d="M229.451 238.379V240.019L230.015 240.349C230.254 239.918 230.588 239.547 230.992 239.263L229.451 238.379Z"
          fill="black"
        />
        <path
          d="M245.08 243.919C245.08 244.177 245.026 244.432 244.923 244.668L243.886 244.076C244.2 243.933 244.473 243.714 244.679 243.437C244.885 243.161 245.018 242.837 245.065 242.496C245.073 242.975 245.08 243.874 245.08 243.919Z"
          fill="#42CE8F"
        />
        <g opacity="0.3">
          <path
            d="M245.08 243.919C245.08 244.177 245.026 244.432 244.923 244.668L243.886 244.076C244.2 243.933 244.473 243.714 244.679 243.437C244.885 243.161 245.018 242.837 245.065 242.496C245.073 242.975 245.08 243.874 245.08 243.919Z"
            fill="black"
          />
        </g>
        <path
          d="M245.073 244.787L243.886 244.098C244.236 243.939 244.534 243.685 244.744 243.364C244.954 243.043 245.068 242.668 245.073 242.285C245.077 242.23 245.077 242.175 245.073 242.12C245.02 241.558 244.607 241.012 243.743 240.517C242.905 240.027 241.95 239.768 240.978 239.768H240.715H240.467H240.249H240.166C240.111 239.764 240.056 239.764 240.001 239.768C239.547 239.832 239.098 239.924 238.656 240.045C238.445 240.098 238.235 240.165 238.025 240.233L237.153 240.51L236.822 240.622C236.228 240.858 235.611 241.033 234.981 241.147C234.719 241.204 234.447 241.204 234.185 241.147C234.058 241.107 233.935 241.057 233.817 240.997C233.351 240.727 233.283 240.323 234.155 239.821C234.548 239.572 234.993 239.417 235.456 239.369C235.919 239.321 236.387 239.381 236.822 239.544L238.678 237.679C236.841 237.109 234.859 237.218 233.095 237.986L231.555 237.109L229.458 238.315L230.999 239.199C230.685 239.384 230.418 239.639 230.219 239.945C230.021 240.25 229.897 240.598 229.857 240.959C229.857 241.551 230.308 242.12 231.217 242.645C233.246 243.805 235.252 243.394 237.085 242.832C238.791 242.277 240.031 241.626 241.015 242.188C241.624 242.547 241.444 242.974 240.76 243.364C240.314 243.61 239.819 243.754 239.31 243.783C238.802 243.813 238.293 243.729 237.822 243.536L235.861 245.483C236.213 245.615 236.575 245.72 236.943 245.798C237.568 245.925 238.206 245.973 238.844 245.94C239.869 245.865 240.874 245.612 241.812 245.191L243.352 246.082L245.426 244.884L245.073 244.787Z"
          fill="#42CE8F"
        />
        <path
          d="M241.804 245.297V246.937L243.344 247.821V246.188L241.804 245.297Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.45"
          d="M241.804 245.297V246.937L243.344 247.821V246.188L241.804 245.297Z"
          fill="black"
        />
        <path
          d="M229.451 238.382L231.548 237.176L233.088 238.052L231.563 237.423L229.451 238.382Z"
          fill="#F5F5F5"
        />
        <path
          d="M234.147 239.888C234.54 239.639 234.986 239.485 235.448 239.437C235.911 239.389 236.379 239.448 236.815 239.611L238.671 237.746L236.815 239.416C236.367 239.262 235.887 239.225 235.42 239.308C234.953 239.39 234.515 239.59 234.147 239.888Z"
          fill="#F5F5F5"
        />
        <path
          d="M235.854 245.569C236.206 245.7 236.568 245.805 236.936 245.883C237.561 246.011 238.199 246.059 238.837 246.025C237.918 246.031 237.008 245.85 236.162 245.494L237.815 243.621L235.854 245.569Z"
          fill="#F5F5F5"
        />
        <path
          d="M241.804 245.295L243.344 245.992L245.418 244.988L243.344 246.187L241.804 245.295Z"
          fill="#F5F5F5"
        />
        <path
          d="M223.47 235C228.925 231.697 237.439 231.367 237.439 231.367C232.089 231.367 227.069 232.566 223.312 234.73C219.705 236.805 217.722 239.501 217.722 242.34C217.722 242.34 217.91 238.363 223.47 235Z"
          fill="#F5F5F5"
        />
        <path
          d="M235.845 254.165C241.639 254.427 247.582 253.273 252.016 250.727C256.622 248.075 258.59 244.48 257.929 241.027C257.929 241.027 258.786 246.66 251.67 250.517C244.554 254.375 235.845 254.165 235.845 254.165Z"
          fill="#F5F5F5"
        />
        <path
          opacity="0.3"
          d="M223.763 242.286C223.77 242.055 223.793 241.825 223.83 241.597C223.83 241.529 223.868 241.462 223.883 241.394C223.92 241.222 223.968 241.052 224.026 240.885C224.026 240.803 224.101 240.713 224.138 240.631C224.195 240.481 224.263 240.336 224.341 240.196C224.391 240.103 224.446 240.013 224.507 239.926C224.587 239.792 224.675 239.662 224.77 239.537C224.837 239.44 224.92 239.35 225.003 239.252L225.303 238.915C225.403 238.81 225.508 238.71 225.619 238.616C225.719 238.518 225.824 238.425 225.934 238.339C226.07 238.226 226.205 238.114 226.348 238.009L226.626 237.814C226.813 237.687 227.001 237.56 227.204 237.44L224.063 234.316L223.274 234.751C221.783 235.567 220.459 236.657 219.375 237.964L223.725 242.308L223.763 242.286Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M239.46 249.601L238.844 249.638H238.709C238.288 249.638 237.86 249.638 237.439 249.638H237.304C236.763 249.638 236.237 249.638 235.711 249.578L235.222 249.541C234.666 249.488 234.118 249.428 233.577 249.346C233.021 249.256 232.48 249.144 231.946 249.024L231.585 248.934C231.067 248.807 230.556 248.657 230.083 248.492L234.764 253.158C235.655 253.233 236.565 253.271 237.491 253.271C239.273 253.273 241.052 253.135 242.811 252.859L239.513 249.563L239.46 249.601Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M229.849 241.024C229.849 241.609 230.3 242.185 231.217 242.71C231.845 243.081 232.543 243.318 233.268 243.406L230.075 240.23C229.922 240.467 229.843 240.743 229.849 241.024Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M257.156 242.338C257.156 239.506 255.172 236.802 251.565 234.728C249.937 233.808 248.204 233.086 246.403 232.578L257.088 243.236C257.133 242.939 257.155 242.638 257.156 242.338Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M237.447 234.998C237.732 234.998 238.01 234.998 238.288 234.998H238.792L239.445 235.05L240.197 235.103L240.745 235.178L241.497 235.283L241.97 235.372L242.782 235.53H242.909L238.686 231.32C238.273 231.32 237.867 231.32 237.454 231.32C236.053 231.321 234.652 231.406 233.261 231.575L236.665 234.968L237.447 234.998Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M250.874 243.725C250.874 243.792 250.821 243.867 250.791 243.942C250.717 244.129 250.631 244.312 250.536 244.489L250.423 244.676C250.322 244.844 250.212 245.007 250.092 245.163L249.98 245.313C249.837 245.485 249.687 245.657 249.521 245.822L249.378 245.964C249.191 246.144 248.988 246.316 248.777 246.488L248.65 246.586L248.432 246.751L251.618 249.926C252.79 249.257 253.867 248.433 254.819 247.477L250.919 243.59L250.874 243.725Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M245.08 242.285C245.084 242.23 245.084 242.175 245.08 242.12C245.028 241.558 244.614 241.011 243.75 240.517C243.06 240.122 242.29 239.884 241.496 239.82L244.885 243.198C245.029 242.916 245.096 242.601 245.08 242.285Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M231.802 235.643H231.847L228.722 232.52C227.635 232.814 226.57 233.185 225.536 233.628L228.631 236.714C229.654 236.262 230.715 235.903 231.802 235.643Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M244.675 248.584C244.239 248.727 243.788 248.854 243.322 248.974L242.939 249.064C242.518 249.161 242.09 249.251 241.647 249.326L241.354 249.386L244.539 252.562C245.676 252.323 246.796 252.011 247.891 251.625L244.802 248.547L244.675 248.584Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M237.815 243.621L236.688 244.737L237.972 246.018C238.26 246.037 238.549 246.037 238.837 246.018C239.793 245.955 240.732 245.734 241.617 245.366L240.001 243.748C239.278 243.944 238.51 243.9 237.815 243.621Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M238.626 242.394L236.92 240.694L236.815 240.731C236.219 240.961 235.603 241.134 234.974 241.248C234.726 241.297 234.471 241.297 234.222 241.248C234.09 241.217 233.964 241.167 233.847 241.098C233.388 240.821 233.313 240.417 234.185 239.922C234.639 239.644 235.156 239.485 235.688 239.458L234.005 237.78C233.712 237.87 233.426 237.975 233.125 238.095L231.585 237.211L230.037 238.102L235.35 243.398C235.948 243.289 236.538 243.139 237.115 242.948C237.634 242.739 238.152 242.536 238.626 242.394Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M240.114 231.449L244.788 236.108C245.525 236.351 246.244 236.649 246.937 236.999C249.634 238.377 251.122 240.272 251.122 242.317V242.415L255.458 246.736C255.796 246.334 256.09 245.897 256.337 245.433L242.586 231.726C241.774 231.621 240.948 231.524 240.114 231.449Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M223.312 249.954C223.907 250.293 224.519 250.6 225.145 250.876L217.864 243.633C218.368 245.985 220.261 248.194 223.312 249.954Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M217.736 242.143L227.377 251.753C228.361 252.088 229.365 252.36 230.383 252.569L218.074 240.301C217.866 240.893 217.751 241.515 217.736 242.143Z"
          fill="white"
        />
        <g opacity="0.15">
          <path
            d="M219.412 237.943C220.497 236.637 221.82 235.546 223.312 234.73L224.101 234.296L223.439 233.637C223.244 233.734 223.056 233.839 222.861 233.951C221.303 234.813 219.915 235.95 218.766 237.307L219.412 237.943Z"
            fill="white"
          />
          <path
            d="M242.759 252.898C240.999 253.174 239.22 253.312 237.439 253.31C236.512 253.31 235.603 253.273 234.711 253.198L235.673 254.157C238.305 254.289 240.944 254.125 243.54 253.67L242.789 252.921L242.759 252.898Z"
            fill="white"
          />
          <path
            d="M224.567 243.077C224.168 241.264 225.235 239.384 227.76 237.938L227.242 237.414H227.204C227.001 237.534 226.813 237.661 226.625 237.789L226.347 237.983C226.205 238.088 226.069 238.201 225.934 238.313C225.824 238.399 225.718 238.492 225.619 238.59C225.508 238.684 225.403 238.784 225.303 238.89L225.002 239.227C224.92 239.324 224.837 239.414 224.769 239.511C224.675 239.636 224.587 239.766 224.506 239.901C224.446 239.987 224.391 240.077 224.341 240.17C224.263 240.31 224.195 240.455 224.138 240.605C224.101 240.687 224.056 240.777 224.026 240.859C223.968 241.026 223.92 241.196 223.883 241.369C223.883 241.436 223.845 241.504 223.83 241.571C223.791 241.799 223.771 242.029 223.77 242.26L224.567 243.077Z"
            fill="white"
          />
          <path
            d="M238.79 248.931C235.352 249.17 231.907 248.592 228.736 247.246L230.029 248.534C230.51 248.699 231.021 248.841 231.532 248.976L231.892 249.066C232.426 249.186 232.967 249.298 233.523 249.388C234.064 249.471 234.612 249.531 235.168 249.583L235.657 249.62C236.183 249.62 236.709 249.673 237.25 249.68H237.445C237.873 249.68 238.294 249.68 238.715 249.68H238.85L239.459 249.635L238.79 248.931Z"
            fill="white"
          />
          <path
            d="M251.565 234.733C255.171 236.807 257.155 239.511 257.155 242.342C257.155 242.643 257.133 242.944 257.088 243.241L257.839 243.99C258.703 240.425 256.757 236.68 252.001 233.946C249.852 232.75 247.541 231.869 245.14 231.332L246.387 232.575C248.194 233.084 249.932 233.809 251.565 234.733Z"
            fill="white"
          />
          <path
            d="M237.446 231.366C237.859 231.366 238.265 231.366 238.679 231.366L237.769 230.459C235.995 230.439 234.221 230.554 232.464 230.804L233.261 231.598C234.65 231.436 236.048 231.359 237.446 231.366Z"
            fill="white"
          />
          <path
            d="M251.617 249.934L252.263 250.578C253.433 249.908 254.503 249.079 255.442 248.114L254.811 247.484C253.861 248.44 252.787 249.264 251.617 249.934Z"
            fill="white"
          />
          <path
            d="M250.333 243.016C249.971 244.361 249.105 245.516 247.913 246.244L248.417 246.746L248.649 246.588L248.777 246.491C248.988 246.319 249.19 246.146 249.378 245.967L249.521 245.824C249.686 245.66 249.837 245.487 249.979 245.315L250.092 245.165C250.212 245.01 250.322 244.847 250.423 244.678L250.536 244.491C250.631 244.314 250.717 244.131 250.791 243.944C250.791 243.869 250.851 243.795 250.874 243.727C250.896 243.66 250.874 243.637 250.911 243.592L250.333 243.016Z"
            fill="white"
          />
          <path
            d="M236.694 235.017L237.386 235.706C239.599 235.685 241.803 235.983 243.93 236.59L242.939 235.601L242.803 235.564C242.54 235.504 242.262 235.459 241.992 235.407L241.518 235.317L240.767 235.212L240.218 235.137L239.467 235.085L238.813 235.032H238.31C238.032 235.032 237.754 235.032 237.468 235.032C237.183 235.032 236.92 235.01 236.694 235.017Z"
            fill="white"
          />
          <path
            d="M244.539 252.561L245.29 253.31C246.408 253.048 247.507 252.716 248.582 252.314L247.89 251.625C246.795 252.01 245.676 252.323 244.539 252.561Z"
            fill="white"
          />
          <path
            d="M228.722 232.511L227.97 231.762C226.901 232.075 225.854 232.46 224.837 232.915L225.513 233.589C226.556 233.155 227.628 232.795 228.722 232.511Z"
            fill="white"
          />
          <path
            d="M229.18 237.24C230.236 236.793 231.333 236.452 232.456 236.221L231.863 235.637H231.817C230.73 235.893 229.668 236.249 228.646 236.7L229.18 237.24Z"
            fill="white"
          />
          <path
            d="M244.208 247.977C243.057 248.327 241.878 248.577 240.684 248.726L241.315 249.355L241.616 249.302C242.059 249.227 242.487 249.138 242.908 249.04L243.291 248.95C243.757 248.83 244.208 248.703 244.644 248.561L244.772 248.523L244.208 247.977Z"
            fill="white"
          />
          <path
            d="M240.113 231.448C240.947 231.5 241.774 231.597 242.578 231.725L241.541 230.699C240.752 230.609 239.955 230.541 239.159 230.504L240.105 231.455L240.113 231.448Z"
            fill="white"
          />
          <path
            d="M256.982 246.116L256.321 245.457C256.075 245.921 255.78 246.358 255.442 246.76L256.088 247.404C256.419 246.999 256.718 246.568 256.982 246.116Z"
            fill="white"
          />
          <path
            d="M246.929 237.021C246.243 236.675 245.532 236.383 244.802 236.145L246.057 237.395L246.613 237.658C248.807 238.781 250.047 240.204 250.37 241.665L251.122 242.414C251.122 242.414 251.122 242.376 251.122 242.354C251.114 240.294 249.626 238.399 246.929 237.021Z"
            fill="white"
          />
          <path
            d="M223.312 249.953C220.261 248.193 218.367 245.983 217.864 243.631L216.834 242.598C216.947 245.549 218.961 248.477 222.846 250.724C224.085 251.426 225.385 252.015 226.731 252.484L225.13 250.874C224.509 250.598 223.902 250.291 223.312 249.953Z"
            fill="white"
          />
          <path
            d="M227.377 251.75L228.722 253.091C229.624 253.338 230.563 253.533 231.517 253.705L230.383 252.566C229.365 252.357 228.361 252.085 227.377 251.75Z"
            fill="white"
          />
          <path
            d="M218.074 240.302L217.376 239.605C217.147 240.152 216.991 240.726 216.91 241.313L217.736 242.145C217.751 241.517 217.865 240.895 218.074 240.302Z"
            fill="white"
          />
        </g>
        <path
          d="M258.17 236.561C256.945 234.655 255.251 233.092 253.249 232.022C245.194 227.394 232.142 227.394 224.094 232.022C222.092 233.092 220.398 234.655 219.172 236.561H218.06V240.763C218.203 243.684 220.209 246.576 224.071 248.8C232.119 253.436 245.178 253.436 253.226 248.8C257.096 246.553 259.117 243.684 259.237 240.763V236.561H258.17Z"
          fill="#42CE8F"
        />
        <g opacity="0.25">
          <path
            d="M258.17 236.561C256.945 234.655 255.251 233.092 253.249 232.022C245.194 227.394 232.142 227.394 224.094 232.022C222.092 233.092 220.398 234.655 219.172 236.561H218.06V240.763C218.203 243.684 220.209 246.576 224.071 248.8C232.119 253.436 245.178 253.436 253.226 248.8C257.096 246.553 259.117 243.684 259.237 240.763V236.561H258.17Z"
            fill="black"
          />
        </g>
        <path
          opacity="0.1"
          d="M238.671 228.555C233.411 228.555 228.152 229.708 224.094 232.023C222.092 233.093 220.398 234.655 219.172 236.561H218.06V240.763C218.21 243.685 220.217 246.576 224.071 248.8C228.099 251.122 233.374 252.276 238.649 252.276L238.671 228.555Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M227.415 230.48C226.268 230.905 225.158 231.421 224.094 232.023C222.092 233.093 220.398 234.656 219.172 236.562H218.06V240.764C218.21 243.685 220.217 246.577 224.071 248.801C225.133 249.409 226.244 249.927 227.393 250.351L227.415 230.48Z"
          fill="black"
        />
        <path
          opacity="0.5"
          d="M249.927 230.48C251.074 230.905 252.184 231.421 253.248 232.023C255.25 233.093 256.944 234.656 258.17 236.562H259.282V240.764C259.139 243.685 257.118 246.577 253.271 248.801C252.209 249.409 251.098 249.927 249.949 250.351L249.927 230.48Z"
          fill="#42CE8F"
        />
        <path
          d="M253.225 228.184C245.17 223.548 232.118 223.548 224.07 228.184C216.023 232.821 216.023 240.326 224.07 244.962C232.118 249.598 245.178 249.591 253.225 244.962C261.273 240.333 261.295 232.813 253.225 228.184Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.2"
          d="M253.225 228.184C245.17 223.548 232.118 223.548 224.07 228.184C216.023 232.821 216.023 240.326 224.07 244.962C232.118 249.598 245.178 249.591 253.225 244.962C261.273 240.333 261.295 232.813 253.225 228.184Z"
          fill="black"
        />
        <path
          d="M248.161 231.257C245.176 229.924 241.942 229.234 238.671 229.234C235.4 229.234 232.166 229.924 229.18 231.257C226.483 232.635 224.995 234.522 224.995 236.575C224.995 238.627 226.498 240.514 229.18 241.893C232.166 243.226 235.4 243.915 238.671 243.915C241.942 243.915 245.176 243.226 248.161 241.893C250.859 240.514 252.346 238.627 252.346 236.575C252.346 234.522 250.859 232.635 248.161 231.257Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.4"
          d="M248.161 231.257C245.176 229.924 241.942 229.234 238.671 229.234C235.4 229.234 232.166 229.924 229.18 231.257C226.483 232.635 224.995 234.522 224.995 236.575C224.995 238.627 226.498 240.514 229.18 241.893C232.166 243.226 235.4 243.915 238.671 243.915C241.942 243.915 245.176 243.226 248.161 241.893C250.859 240.514 252.346 238.627 252.346 236.575C252.346 234.522 250.859 232.635 248.161 231.257Z"
          fill="black"
        />
        <path
          d="M252.798 228.953C249.041 226.789 244.021 225.598 238.671 225.598C233.321 225.598 228.302 226.789 224.545 228.953C220.938 231.028 218.954 233.732 218.954 236.571C218.954 239.409 220.938 242.113 224.545 244.188C228.302 246.345 233.321 247.536 238.671 247.536C244.021 247.536 249.041 246.345 252.798 244.188C256.404 242.113 258.388 239.402 258.388 236.571C258.388 233.739 256.404 231.028 252.798 228.953ZM248.161 241.888C245.176 243.222 241.942 243.911 238.671 243.911C235.4 243.911 232.166 243.222 229.181 241.888C226.483 240.51 224.995 238.623 224.995 236.571C224.995 234.518 226.498 232.631 229.181 231.253C232.166 229.919 235.4 229.23 238.671 229.23C241.942 229.23 245.176 229.919 248.161 231.253C250.859 232.631 252.347 234.518 252.347 236.571C252.347 238.623 250.859 240.51 248.161 241.888Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.2"
          d="M238.67 243.203C245.829 243.203 251.632 240.235 251.632 236.574C251.632 232.913 245.829 229.945 238.67 229.945C231.512 229.945 225.708 232.913 225.708 236.574C225.708 240.235 231.512 243.203 238.67 243.203Z"
          fill="black"
        />
        <path
          d="M238.67 243.203C245.829 243.203 251.632 240.235 251.632 236.574C251.632 232.913 245.829 229.945 238.67 229.945C231.512 229.945 225.708 232.913 225.708 236.574C225.708 240.235 231.512 243.203 238.67 243.203Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.6"
          d="M238.67 243.203C245.829 243.203 251.632 240.235 251.632 236.574C251.632 232.913 245.829 229.945 238.67 229.945C231.512 229.945 225.708 232.913 225.708 236.574C225.708 240.235 231.512 243.203 238.67 243.203Z"
          fill="black"
        />
        <path
          d="M247.838 233.635C242.773 231.044 234.568 231.044 229.503 233.635C227.407 234.706 226.182 236.054 225.821 237.447C226.182 238.841 227.407 240.189 229.503 241.26C234.568 243.851 242.773 243.851 247.838 241.26C249.934 240.189 251.159 238.841 251.52 237.447C251.159 236.054 249.934 234.706 247.838 233.635Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.5"
          d="M247.838 233.635C242.773 231.044 234.568 231.044 229.503 233.635C227.407 234.706 226.182 236.054 225.821 237.447C226.182 238.841 227.407 240.189 229.503 241.26C234.568 243.851 242.773 243.851 247.838 241.26C249.934 240.189 251.159 238.841 251.52 237.447C251.159 236.054 249.934 234.706 247.838 233.635Z"
          fill="black"
        />
        <path
          d="M239.895 231.977V233.872L239.249 234.553L238.047 235.804V233.834L239.895 231.977Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.3"
          d="M239.895 231.977V233.872L239.249 234.553L238.047 235.804V233.834L239.895 231.977Z"
          fill="black"
        />
        <path
          d="M238.047 233.839V234.955C237.451 235.187 236.835 235.362 236.206 235.479C235.946 235.533 235.678 235.533 235.417 235.479C235.284 235.441 235.156 235.388 235.034 235.322C234.576 235.052 234.508 234.648 235.38 234.146C235.771 233.895 236.215 233.737 236.678 233.684C237.14 233.631 237.609 233.684 238.047 233.839Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.45"
          d="M238.047 233.839V234.955C237.451 235.187 236.835 235.362 236.206 235.479C235.946 235.533 235.678 235.533 235.417 235.479C235.284 235.441 235.156 235.388 235.034 235.322C234.576 235.052 234.508 234.648 235.38 234.146C235.771 233.895 236.215 233.737 236.678 233.684C237.14 233.631 237.609 233.684 238.047 233.839Z"
          fill="black"
        />
        <path
          d="M246.658 239.224V240.857L244.577 242.055L243.036 241.186C241.166 242.054 239.027 242.151 237.085 241.456V239.816L238.024 238.887L237.085 239.142C236.315 239.364 235.508 239.432 234.711 239.342C233.914 239.252 233.142 239.006 232.441 238.617C231.51 238.078 231.081 237.494 231.081 236.887V235.299L231.247 234.603L230.683 234.273V232.633L232.223 233.517C231.911 233.704 231.646 233.96 231.448 234.265C231.251 234.57 231.125 234.916 231.081 235.277C231.081 235.861 231.532 236.43 232.441 236.955C234.463 238.123 236.469 237.704 238.302 237.149C240.016 236.595 241.255 235.943 242.24 236.505C242.848 236.857 242.668 237.254 241.977 237.681C241.532 237.926 241.038 238.069 240.531 238.098C240.024 238.128 239.517 238.044 239.046 237.853L237.085 239.793C237.436 239.931 237.798 240.039 238.167 240.115C239.812 240.425 241.512 240.221 243.036 239.531L244.577 240.415L246.658 239.224Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.35"
          d="M231.082 235.253C231.082 235.837 231.532 236.407 232.442 236.931C234.463 238.099 236.469 237.68 238.303 237.126C240.016 236.571 241.256 235.92 242.24 236.482C242.849 236.834 242.668 237.231 241.977 237.658C241.532 237.902 241.038 238.045 240.531 238.075C240.024 238.105 239.517 238.021 239.047 237.83L237.085 239.77C237.436 239.908 237.798 240.016 238.167 240.092C239.812 240.402 241.513 240.198 243.037 239.508L244.577 240.391L246.658 239.2V240.833L244.577 242.032L243.037 241.185C241.166 242.053 239.027 242.15 237.085 241.455V239.815L238.025 238.886L237.085 239.141C236.315 239.363 235.508 239.431 234.711 239.341C233.914 239.251 233.143 239.005 232.442 238.616C231.51 238.077 231.082 237.493 231.082 236.886V235.298L231.247 234.602"
          fill="black"
        />
        <path
          opacity="0.45"
          d="M230.683 232.609V234.25L231.247 234.579C231.488 234.15 231.822 233.779 232.223 233.493L230.683 232.609Z"
          fill="black"
        />
        <path
          d="M246.313 238.189C246.312 238.446 246.258 238.701 246.155 238.938L245.118 238.339C245.431 238.196 245.704 237.977 245.91 237.702C246.116 237.428 246.249 237.105 246.298 236.766C246.305 237.208 246.313 238.106 246.313 238.189Z"
          fill="#42CE8F"
        />
        <g opacity="0.3">
          <path
            d="M246.313 238.189C246.312 238.446 246.258 238.701 246.155 238.938L245.118 238.339C245.431 238.196 245.704 237.977 245.91 237.702C246.116 237.428 246.249 237.105 246.298 236.766C246.305 237.208 246.313 238.106 246.313 238.189Z"
            fill="black"
          />
        </g>
        <path
          d="M246.305 239.021L245.118 238.332C245.469 238.172 245.767 237.917 245.977 237.594C246.187 237.272 246.301 236.896 246.305 236.512C246.309 236.46 246.309 236.407 246.305 236.355C246.229 236 246.069 235.67 245.837 235.391C245.606 235.111 245.31 234.892 244.975 234.752C244.137 234.261 243.182 234.002 242.21 234.003H241.954H241.706H241.488H241.406H241.24C240.786 234.069 240.337 234.164 239.895 234.287C239.685 234.34 239.467 234.4 239.264 234.467L238.393 234.752L238.062 234.864C237.466 235.096 236.85 235.271 236.221 235.388C235.958 235.442 235.687 235.442 235.425 235.388C235.296 235.35 235.173 235.297 235.056 235.231C234.59 234.969 234.523 234.557 235.387 234.055C235.782 233.808 236.228 233.655 236.692 233.607C237.156 233.559 237.624 233.617 238.062 233.778L239.91 231.92C238.076 231.343 236.094 231.452 234.335 232.228L232.832 231.344L230.683 232.61L232.223 233.493C231.91 233.68 231.644 233.935 231.446 234.241C231.248 234.546 231.123 234.892 231.081 235.254C231.081 235.845 231.532 236.414 232.441 236.939C234.47 238.107 236.469 237.688 238.31 237.134C240.008 236.579 241.255 235.928 242.24 236.489C242.848 236.841 242.668 237.238 241.977 237.665C241.532 237.91 241.038 238.053 240.531 238.083C240.024 238.112 239.517 238.029 239.046 237.838L237.078 239.778C238.034 240.147 239.06 240.303 240.083 240.234C241.109 240.16 242.114 239.906 243.051 239.485L244.592 240.369L246.666 239.178L246.305 239.021Z"
          fill="#42CE8F"
        />
        <path
          d="M243.037 239.527V241.16L244.577 242.044V240.411L243.037 239.527Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.45"
          d="M243.037 239.527V241.16L244.577 242.044V240.411L243.037 239.527Z"
          fill="black"
        />
        <path
          d="M230.683 232.608L232.78 231.402L234.32 232.286L232.795 231.65L230.683 232.608Z"
          fill="#F5F5F5"
        />
        <path
          d="M235.372 234.111C235.767 233.864 236.214 233.711 236.677 233.663C237.141 233.615 237.61 233.673 238.047 233.834L239.896 231.977L238.047 233.647C237.598 233.493 237.117 233.454 236.65 233.536C236.182 233.617 235.742 233.815 235.372 234.111Z"
          fill="#F5F5F5"
        />
        <path
          d="M237.078 239.791C238.034 240.161 239.06 240.317 240.084 240.248C239.165 240.259 238.253 240.078 237.409 239.717L239.062 237.852L237.078 239.791Z"
          fill="#F5F5F5"
        />
        <path
          d="M243.037 239.53L244.577 240.219L246.651 239.223L244.577 240.414L243.037 239.53Z"
          fill="#F5F5F5"
        />
        <path
          d="M224.672 229.199C230.127 225.896 238.64 225.566 238.64 225.566C233.29 225.566 228.271 226.757 224.514 228.922C220.907 230.997 218.923 233.701 218.923 236.539C218.953 236.569 219.149 232.592 224.672 229.199Z"
          fill="#F5F5F5"
        />
        <path
          d="M237.078 248.398C242.872 248.652 248.815 247.506 253.248 244.96C257.855 242.308 259.823 238.713 259.162 235.223C259.162 235.223 260.019 240.863 252.903 244.712C245.787 248.562 237.078 248.398 237.078 248.398Z"
          fill="#F5F5F5"
        />
        <path
          opacity="0.3"
          d="M224.996 236.52C225.003 236.289 225.025 236.059 225.063 235.831C225.077 235.762 225.095 235.695 225.116 235.629C225.152 235.456 225.2 235.286 225.259 235.119C225.259 235.029 225.334 234.947 225.371 234.857C225.409 234.767 225.491 234.572 225.567 234.43L225.739 234.153C225.822 234.026 225.905 233.898 226.002 233.771C226.1 233.644 226.153 233.584 226.235 233.486C226.318 233.389 226.431 233.262 226.536 233.149L226.844 232.842L227.167 232.565L227.58 232.243L227.858 232.048C228.046 231.921 228.234 231.794 228.437 231.674L225.304 228.543L224.552 228.977C223.059 229.791 221.735 230.882 220.652 232.191L225.011 236.535L224.996 236.52Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M240.692 243.836H240.076H239.941C239.52 243.836 239.092 243.873 238.671 243.873H238.536C237.995 243.873 237.469 243.873 236.943 243.813L236.454 243.776C235.898 243.731 235.342 243.671 234.809 243.589C234.252 243.506 233.711 243.394 233.178 243.266L232.817 243.184C232.299 243.049 231.788 242.907 231.314 242.742L235.996 247.408C236.89 247.476 237.799 247.513 238.723 247.513C240.505 247.519 242.284 247.381 244.043 247.101L240.745 243.813L240.692 243.836Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M231.081 235.259C231.081 235.843 231.532 236.42 232.449 236.944C233.075 237.314 233.771 237.551 234.493 237.641L231.307 234.465C231.153 234.701 231.074 234.978 231.081 235.259Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M258.388 236.561C258.388 233.729 256.404 231.033 252.797 228.958C251.171 228.035 249.437 227.313 247.635 226.809L258.32 237.437C258.365 237.147 258.387 236.854 258.388 236.561Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M238.671 229.201C238.956 229.201 239.234 229.201 239.512 229.201H240.016C240.241 229.201 240.452 229.201 240.669 229.246L241.383 229.306L241.932 229.381C242.187 229.381 242.45 229.441 242.683 229.486L243.157 229.576L243.968 229.733H244.088L239.873 225.524H238.641C237.239 225.52 235.839 225.605 234.448 225.778L237.852 229.171L238.671 229.201Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M252.106 237.948C252.106 238.023 252.054 238.098 252.023 238.165C251.949 238.355 251.864 238.54 251.768 238.719L251.655 238.907C251.555 239.075 251.444 239.238 251.325 239.394L251.212 239.543C251.069 239.716 250.919 239.88 250.754 240.045L250.611 240.195C250.423 240.375 250.22 240.547 250.01 240.719C249.964 240.747 249.921 240.78 249.882 240.817L249.664 240.981L252.85 244.157C254.029 243.497 255.106 242.672 256.051 241.708L252.159 237.828L252.106 237.948Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M246.313 236.519C246.317 236.464 246.317 236.409 246.313 236.354C246.26 235.785 245.847 235.245 244.983 234.751C244.292 234.354 243.523 234.113 242.729 234.047L246.087 237.44C246.243 237.158 246.321 236.84 246.313 236.519Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M233.035 229.877H233.08L229.932 226.746C228.848 227.042 227.786 227.412 226.753 227.855L229.849 230.933C230.876 230.483 231.943 230.129 233.035 229.877Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M245.907 242.815C245.471 242.957 245.021 243.084 244.555 243.204L244.171 243.294C243.751 243.392 243.322 243.481 242.879 243.556L242.586 243.616L245.772 246.792C246.908 246.55 248.028 246.235 249.123 245.848L246.035 242.777L245.907 242.815Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M239.046 237.844L237.919 238.967L239.204 240.248C239.489 240.267 239.775 240.267 240.06 240.248C241.02 240.183 241.962 239.96 242.848 239.589L241.203 237.986C240.487 238.171 239.731 238.121 239.046 237.844Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M239.858 236.616L238.152 234.915L238.047 234.953C237.451 235.185 236.835 235.36 236.206 235.477C235.946 235.531 235.677 235.531 235.417 235.477C235.284 235.439 235.155 235.386 235.034 235.32C234.575 235.05 234.508 234.646 235.379 234.144C235.834 233.868 236.351 233.709 236.882 233.68L235.192 231.972C234.899 232.062 234.605 232.174 234.312 232.294L232.81 231.41L231.262 232.294L236.574 237.597C237.173 237.489 237.763 237.338 238.34 237.147C238.866 236.968 239.384 236.766 239.858 236.616Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M241.338 225.699L246.012 230.358C246.75 230.603 247.469 230.904 248.161 231.257C250.859 232.635 252.347 234.522 252.347 236.575V236.695L256.69 241.016C257.028 240.614 257.322 240.177 257.569 239.713L243.811 226.006C242.999 225.857 242.172 225.759 241.338 225.699Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M224.544 244.181C225.137 244.526 225.749 244.836 226.378 245.11L219.097 237.859C219.6 240.219 221.494 242.428 224.544 244.181Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M218.961 236.373L228.609 245.983C229.594 246.315 230.597 246.588 231.615 246.8L219.307 234.523C219.096 235.118 218.979 235.743 218.961 236.373Z"
          fill="white"
        />
        <g opacity="0.15">
          <path
            d="M220.644 232.193C221.727 230.885 223.051 229.794 224.544 228.98L225.295 228.546L224.634 227.887L224.056 228.201C222.498 229.063 221.11 230.2 219.96 231.557L220.599 232.201L220.644 232.193Z"
            fill="white"
          />
          <path
            d="M243.99 247.125C242.231 247.404 240.452 247.542 238.67 247.537C237.746 247.537 236.837 247.5 235.943 247.432L236.905 248.383C239.537 248.518 242.176 248.357 244.772 247.904L244.021 247.155L243.99 247.125Z"
            fill="white"
          />
          <path
            d="M225.791 237.311C225.393 235.498 226.467 233.618 228.992 232.165L228.474 231.648H228.436C228.233 231.768 228.045 231.896 227.858 232.023L227.58 232.218L227.166 232.54L226.843 232.817L226.535 233.124C226.43 233.236 226.325 233.349 226.234 233.461C226.144 233.573 226.069 233.648 226.002 233.746C225.934 233.843 225.821 234 225.739 234.128L225.566 234.405C225.491 234.547 225.43 234.689 225.37 234.832C225.31 234.974 225.288 235.004 225.258 235.094C225.199 235.26 225.151 235.43 225.115 235.603C225.094 235.67 225.076 235.737 225.062 235.805C225.02 236.033 224.999 236.263 225.002 236.494L225.791 237.311Z"
            fill="white"
          />
          <path
            d="M240.023 243.162C236.585 243.397 233.14 242.82 229.969 241.477L231.261 242.765C231.742 242.93 232.253 243.072 232.764 243.207L233.125 243.289C233.658 243.416 234.199 243.529 234.755 243.611C235.289 243.694 235.845 243.754 236.401 243.798L236.889 243.836C237.415 243.873 237.941 243.896 238.482 243.896H238.618C239.046 243.896 239.467 243.896 239.887 243.858H240.023L240.631 243.806L240.023 243.162Z"
            fill="white"
          />
          <path
            d="M252.797 228.963C256.404 231.038 258.388 233.734 258.388 236.565C258.388 236.866 258.366 237.167 258.32 237.464L259.072 238.213C259.943 234.655 257.997 230.91 253.241 228.169C251.093 226.972 248.782 226.094 246.38 225.562L247.628 226.806C249.433 227.312 251.169 228.036 252.797 228.963Z"
            fill="white"
          />
          <path
            d="M238.67 225.597H239.903L238.993 224.699C237.219 224.674 235.445 224.789 233.688 225.043L234.485 225.837C235.874 225.669 237.272 225.589 238.67 225.597Z"
            fill="white"
          />
          <path
            d="M252.842 244.176L253.496 244.828C254.666 244.158 255.735 243.329 256.674 242.364L256.043 241.734C255.098 242.696 254.02 243.518 252.842 244.176Z"
            fill="white"
          />
          <path
            d="M251.565 237.246C251.201 238.591 250.332 239.744 249.138 240.467L249.649 240.976L249.882 240.819C249.921 240.782 249.964 240.75 250.009 240.721C250.22 240.549 250.423 240.369 250.61 240.197L250.753 240.047C250.919 239.883 251.069 239.718 251.212 239.546L251.324 239.396C251.444 239.24 251.554 239.077 251.655 238.909L251.768 238.722C251.864 238.542 251.949 238.357 252.023 238.167C252.023 238.1 252.076 238.025 252.106 237.95C252.136 237.875 252.106 237.86 252.143 237.823L251.565 237.246Z"
            fill="white"
          />
          <path
            d="M237.889 229.251L238.58 229.932C240.793 229.916 242.997 230.213 245.125 230.816L244.126 229.827H243.998C243.735 229.768 243.457 229.715 243.187 229.67L242.713 229.58C242.45 229.535 242.187 229.505 241.962 229.475L241.413 229.401L240.699 229.341C240.482 229.341 240.271 229.341 240.046 229.296H239.542C239.264 229.296 238.986 229.296 238.701 229.296C238.415 229.296 238.145 229.243 237.889 229.251Z"
            fill="white"
          />
          <path
            d="M245.771 246.792L246.522 247.541C247.64 247.285 248.74 246.954 249.814 246.552L249.122 245.855C248.027 246.24 246.907 246.553 245.771 246.792Z"
            fill="white"
          />
          <path
            d="M229.932 226.741L229.18 225.992C228.11 226.304 227.063 226.69 226.047 227.146L226.723 227.82C227.766 227.386 228.838 227.026 229.932 226.741Z"
            fill="white"
          />
          <path
            d="M230.397 231.451C231.452 231.011 232.547 230.673 233.666 230.44L233.08 229.855H233.035C231.946 230.108 230.884 230.464 229.864 230.919L230.397 231.451Z"
            fill="white"
          />
          <path
            d="M245.463 242.207C244.315 242.558 243.138 242.809 241.946 242.956L242.577 243.585L242.878 243.533C243.321 243.45 243.75 243.368 244.17 243.271L244.554 243.181C245.02 243.061 245.47 242.934 245.906 242.791L246.034 242.746L245.463 242.207Z"
            fill="white"
          />
          <path
            d="M241.338 225.694C242.172 225.754 242.998 225.851 243.803 225.978L242.773 224.945C242.022 224.847 241.188 224.78 240.391 224.75L241.345 225.694H241.338Z"
            fill="white"
          />
          <path
            d="M258.215 240.343L257.561 239.684C257.315 240.148 257.02 240.584 256.682 240.987L257.321 241.631C257.653 241.226 257.952 240.795 258.215 240.343Z"
            fill="white"
          />
          <path
            d="M248.161 231.251C247.472 230.905 246.759 230.609 246.027 230.367L247.282 231.618L247.838 231.873C250.032 232.996 251.272 234.427 251.595 235.887L252.346 236.636V236.539C252.339 234.517 250.858 232.629 248.161 231.251Z"
            fill="white"
          />
          <path
            d="M224.544 244.18C221.493 242.427 219.6 240.218 219.096 237.858L218.067 236.832C218.172 239.783 220.193 242.712 224.078 244.959C225.316 245.661 226.617 246.248 227.963 246.711L226.362 245.108C225.739 244.835 225.131 244.524 224.544 244.18Z"
            fill="white"
          />
          <path
            d="M228.609 245.984L229.932 247.325C230.841 247.565 231.78 247.767 232.735 247.932L231.6 246.801C230.588 246.589 229.589 246.316 228.609 245.984Z"
            fill="white"
          />
          <path
            d="M219.307 234.525L218.608 233.836C218.38 234.383 218.221 234.957 218.135 235.544L218.961 236.375C218.979 235.744 219.096 235.12 219.307 234.525Z"
            fill="white"
          />
        </g>
        <path
          d="M221.824 271.642L139.417 224.223L98.2168 247.929L180.624 295.355L221.824 271.642Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.4"
          d="M102.553 247.926L180.624 292.866L217.489 271.647L139.425 226.707L102.553 247.926ZM104.724 247.926L139.425 227.965L215.317 271.64L180.617 291.608L104.724 247.926Z"
          fill="black"
        />
        <g opacity="0.4">
          <path
            d="M161.553 272.068C155.94 272.068 150.177 270.773 145.774 268.218C141.445 265.732 138.966 262.339 138.966 258.901C138.966 255.755 141.002 252.856 144.692 250.737C152.566 246.205 165.859 246.482 174.335 251.358C178.655 253.845 181.135 257.238 181.135 260.676C181.135 263.822 179.106 266.72 175.417 268.84C171.727 270.96 166.663 272.068 161.553 272.068ZM158.48 248.235C153.513 248.235 148.667 249.269 145.022 251.373C141.573 253.358 139.672 256.032 139.672 258.863C139.672 262.024 142.017 265.192 146.104 267.544C154.37 272.3 167.339 272.585 175.011 268.166C178.46 266.181 180.361 263.507 180.361 260.639C180.361 257.478 178.017 254.309 173.929 251.958C169.593 249.501 163.965 248.235 158.487 248.235H158.48Z"
            fill="black"
          />
        </g>
        <g opacity="0.2">
          <path
            d="M161.554 269.417C156.219 269.417 150.937 268.144 147.074 265.912C143.595 263.912 141.604 261.358 141.604 258.901C141.604 256.751 143.159 254.662 145.985 253.036C149.208 251.179 153.649 250.16 158.481 250.16C163.816 250.16 169.091 251.433 172.96 253.665C176.439 255.665 178.431 258.219 178.431 260.676C178.431 262.826 176.875 264.908 174.042 266.541C170.826 268.391 166.393 269.417 161.554 269.417Z"
            fill="black"
          />
        </g>
        <path
          opacity="0.2"
          d="M176.289 287.224L153.634 274.147L155.452 274.379C157.072 274.589 158.703 274.694 160.336 274.694C165.852 274.594 171.264 273.176 176.116 270.559C180.129 268.252 184.171 264.125 184.171 260.073C184.164 258.672 183.852 257.289 183.254 256.021L182.766 254.965L207.563 269.323L204.842 270.889C204.636 270.941 204.452 271.061 204.321 271.229C204.19 271.398 204.119 271.604 204.119 271.817C204.119 272.03 204.19 272.237 204.321 272.405C204.452 272.573 204.636 272.693 204.842 272.746L207.563 274.312L184.968 287.232L182.616 285.884C182.001 285.572 181.322 285.409 180.632 285.409C179.942 285.409 179.263 285.572 178.648 285.884L176.289 287.224Z"
          fill="black"
        />
        <path
          d="M186.26 278.053C184.951 278.066 183.659 277.752 182.503 277.139C182.035 276.923 181.631 276.589 181.331 276.17C181.032 275.751 180.848 275.261 180.797 274.749C180.826 274.297 180.976 273.86 181.232 273.486C181.489 273.111 181.842 272.813 182.255 272.622C183.368 272.089 184.592 271.825 185.826 271.849C187.061 271.874 188.273 272.188 189.363 272.765C189.832 272.98 190.236 273.314 190.535 273.733C190.834 274.152 191.018 274.642 191.069 275.154C191.041 275.607 190.891 276.043 190.634 276.418C190.377 276.792 190.024 277.09 189.611 277.281C188.58 277.822 187.425 278.088 186.26 278.053Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.2"
          d="M137.359 264.594L112.562 250.363L115.282 248.797C115.472 248.718 115.638 248.591 115.763 248.428C115.888 248.265 115.969 248.072 115.996 247.869C115.996 247.531 115.74 247.209 115.282 246.947L112.562 245.367L135.104 232.566L137.456 233.922C138.074 234.224 138.752 234.381 139.44 234.381C140.128 234.381 140.806 234.224 141.424 233.922L143.776 232.566L166.423 245.562L164.59 245.322C162.968 245.112 161.334 245.007 159.698 245.007C154.333 245.007 147.916 246.625 143.746 249.029C138.531 252.025 135.886 255.254 135.886 258.602C135.904 260.29 136.225 261.962 136.833 263.538L137.359 264.594Z"
          fill="black"
        />
        <path
          d="M134.397 248.334C133.087 248.35 131.794 248.033 130.64 247.413C130.171 247.198 129.766 246.865 129.467 246.445C129.167 246.026 128.984 245.536 128.935 245.023C128.964 244.571 129.115 244.135 129.372 243.761C129.628 243.387 129.981 243.088 130.392 242.896C131.505 242.364 132.727 242.101 133.961 242.127C135.194 242.153 136.404 242.468 137.493 243.046C137.965 243.258 138.372 243.591 138.673 244.01C138.974 244.43 139.158 244.922 139.206 245.435C139.177 245.886 139.026 246.321 138.769 246.694C138.513 247.067 138.16 247.365 137.749 247.555C136.718 248.098 135.563 248.367 134.397 248.334Z"
          fill="#42CE8F"
        />
        <path
          d="M221.824 271.645V281.629L180.625 305.342V295.358L221.824 271.645Z"
          fill="#42CE8F"
        />
        <g opacity="0.1">
          <path
            d="M221.824 271.645V281.629L180.625 305.342V295.358L221.824 271.645Z"
            fill="black"
          />
        </g>
        <g opacity="0.1">
          <path
            d="M180.625 297.576L193.631 290.363L180.625 298.183V297.576Z"
            fill="black"
          />
          <path
            d="M221.824 276.383L217.489 279.132L221.824 276.892V276.383Z"
            fill="black"
          />
          <path
            d="M221.824 278.762L200.146 291.607L221.824 279.496V278.762Z"
            fill="black"
          />
          <path
            d="M180.625 299.952L202.31 287.863L180.625 300.739V299.952Z"
            fill="black"
          />
        </g>
        <path
          d="M180.624 305.337L98.2168 257.917V247.926L180.624 295.353V305.337Z"
          fill="#42CE8F"
        />
        <g opacity="0.2">
          <path
            d="M180.624 305.337L98.2168 257.917V247.926L180.624 295.353V305.337Z"
            fill="black"
          />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.3">
          <path
            d="M180.624 297.58L176.281 295.355L180.624 298.187V297.58Z"
            fill="black"
          />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.3">
          <path
            d="M180.625 299.953L158.819 287.805L180.625 300.74V299.953Z"
            fill="black"
          />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.3">
          <path
            d="M98.2168 252.629L113.403 261.654L98.2168 253.213V252.629Z"
            fill="black"
          />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.3">
          <path
            d="M98.2168 255.141L106.896 260.414L98.2168 255.702V255.141Z"
            fill="black"
          />
        </g>
        <path
          d="M187.8 250.404L145.165 274.941L132.158 267.459L174.793 242.914L187.8 250.404Z"
          fill="#455A64"
        />
        <path
          d="M132.158 267.465V278.325L145.165 285.815V274.947L132.158 267.465Z"
          fill="#263238"
        />
        <path
          d="M145.165 285.81L145.924 285.368L145.165 284.926V285.81Z"
          fill="#E0E0E0"
        />
        <path
          d="M187.8 250.406V252.062L186.365 251.23L187.8 250.406Z"
          fill="#E0E0E0"
        />
        <g style={{ mixBlendMode: "multiply" }} opacity="0.3">
          <path
            d="M145.924 285.364V275.387L145.165 274.945V284.929L145.924 285.364Z"
            fill="black"
          />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.2">
          <path
            d="M145.924 275.386L187.124 251.672L186.365 251.23L145.165 274.944L145.924 275.386Z"
            fill="black"
          />
        </g>
        <path
          d="M186.402 249.598L143.76 274.136L142.678 273.514L185.313 248.977L186.402 249.598Z"
          fill="#FAFAFA"
        />
        <path
          d="M177.288 244.356L134.645 268.901L133.563 268.272L176.206 243.734L177.288 244.356Z"
          fill="#FAFAFA"
        />
        <path
          d="M143.76 274.137V285.005L142.678 284.376V273.516L143.76 274.137Z"
          fill="#E0E0E0"
        />
        <path
          d="M134.645 268.899V279.752L133.563 279.13V268.27L134.645 268.899Z"
          fill="#E0E0E0"
        />
        <path
          d="M219.983 32.9258L193 48.4601L219.983 63.9943L246.974 48.4676L219.983 32.9258Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.3"
          d="M219.983 32.9258L193 48.4601L219.983 63.9943L246.974 48.4676L219.983 32.9258Z"
          fill="black"
        />
        <path
          d="M246.981 48.4102L246.974 138.215L219.99 153.742L219.983 63.9969L246.981 48.4102Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.4"
          d="M246.981 48.4102L246.974 138.215L219.99 153.742L219.983 63.9969L246.981 48.4102Z"
          fill="black"
        />
        <path
          d="M219.983 63.9913L219.991 153.737L193 138.202V48.457L219.983 63.9913Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.55"
          d="M219.983 63.9913L219.991 153.737L193 138.202V48.457L219.983 63.9913Z"
          fill="black"
        />
        <path
          d="M169.008 110.59L142.017 126.124L169.008 141.658L195.998 126.124L169.008 110.59Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.3"
          d="M169.008 110.59L142.017 126.124L169.008 141.658L195.998 126.124L169.008 110.59Z"
          fill="white"
        />
        <path
          d="M196.006 126.125L195.998 167.545L169.007 183.079V141.659L196.006 126.125Z"
          fill="#42CE8F"
        />
        <path
          d="M169.008 141.659V183.079L142.017 167.545V126.125L169.008 141.659Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.1"
          d="M169.008 141.659V183.079L142.017 167.545V126.125L169.008 141.659Z"
          fill="black"
        />
        <path
          d="M118.024 88.1562L91.0332 103.691L118.024 119.217L145.015 103.691L118.024 88.1562Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.7"
          d="M118.024 88.1562L91.0332 103.691L118.024 119.217L145.015 103.691L118.024 88.1562Z"
          fill="white"
        />
        <path
          d="M145.023 103.691L145.015 196.92L118.024 212.424V119.226L145.023 103.691Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.6"
          d="M145.023 103.691L145.015 196.92L118.024 212.424V119.226L145.023 103.691Z"
          fill="white"
        />
        <path
          d="M118.024 119.226V212.424L91.0407 196.89L91.0332 103.691L118.024 119.226Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.4"
          d="M118.024 119.226V212.424L91.0407 196.89L91.0332 103.691L118.024 119.226Z"
          fill="white"
        />
        <path
          d="M67.0483 138.195L40.0576 153.737L67.0483 169.279L94.039 153.745L67.0483 138.195Z"
          fill="#455A64"
        />
        <path
          d="M94.047 153.684L94.0395 226.224L67.0488 241.751V169.278L94.047 153.684Z"
          fill="#37474F"
        />
        <path
          d="M67.0483 169.278V241.751L40.0651 226.217L40.0576 153.691L67.0483 169.278Z"
          fill="#263238"
        />
        <path
          d="M222.989 17.3992V24.305L213.559 18.8673C213.095 18.5643 212.71 18.157 212.433 17.6782C212.156 17.1995 211.996 16.6626 211.966 16.1109V10.6133L222.989 17.3992Z"
          fill="#B4ABBB"
        />
        <path
          d="M243.743 27.967V34.386C243.743 35.4046 243.029 35.8166 242.15 35.3147L231.983 29.48V22.5742L243.743 27.967Z"
          fill="#B4ABBB"
        />
        <path
          d="M122.773 79.7032L124.028 77.8008L173.621 123.153L175.936 122.816V122.876C175.693 123.281 175.3 123.575 174.842 123.694C174.384 123.814 173.897 123.748 173.486 123.512L166.919 119.73C166.324 119.392 165.769 118.987 165.266 118.524L122.773 79.7032Z"
          fill="#B4ABBB"
        />
        <path
          d="M222.989 17.3984L231.984 22.574L175.004 117.495L167.189 110.349L222.989 17.3984Z"
          fill="white"
        />
        <path
          d="M231.981 22.5742V29.4725L175.956 122.843C175.848 123.043 175.695 123.215 175.509 123.345C175.322 123.475 175.108 123.56 174.882 123.592C174.657 123.624 174.427 123.603 174.212 123.53C173.996 123.457 173.801 123.335 173.642 123.173L125.386 79.0639C125.224 78.8986 125.026 78.7728 124.808 78.6964C124.589 78.6199 124.356 78.5949 124.126 78.6231C123.896 78.6514 123.676 78.7323 123.483 78.8594C123.289 78.9865 123.128 79.1565 123.011 79.356L79.0464 146.324L74.0495 153.927C73.8134 154.281 73.4637 154.545 73.0576 154.676V147.77L74.0645 147.021L79.0539 139.426L123.011 72.4352C123.128 72.2357 123.289 72.0658 123.483 71.9386C123.676 71.8115 123.896 71.7306 124.126 71.7024C124.356 71.6741 124.589 71.6992 124.808 71.7756C125.026 71.8521 125.224 71.9778 125.386 72.1431L173.642 116.252C173.801 116.414 173.996 116.536 174.212 116.609C174.427 116.682 174.657 116.703 174.882 116.671C175.108 116.639 175.322 116.554 175.509 116.424C175.695 116.294 175.848 116.122 175.956 115.922L231.981 22.5742Z"
          fill="#F2F7F6"
        />
        <path
          d="M73.0447 147.79V154.696C72.7832 154.784 72.5059 154.815 72.2312 154.788C71.9566 154.761 71.6909 154.676 71.4517 154.539L66.3647 151.617H66.3196C65.8678 151.312 65.4924 150.908 65.2227 150.435C64.953 149.962 64.7961 149.434 64.7642 148.891V143.004L73.0447 147.79Z"
          fill="#B4ABBB"
        />
        <path
          d="M74.0594 147.02L123.014 72.4348C123.231 72.1049 123.566 71.869 123.951 71.7746C124.335 71.6801 124.741 71.734 125.088 71.9255L116.597 66.6825C116.176 66.4281 115.674 66.3453 115.193 66.4513C114.713 66.5574 114.293 66.8441 114.019 67.2517L65.0575 141.845C64.9253 142.042 64.8354 142.264 64.7934 142.497C64.7513 142.73 64.758 142.97 64.813 143.201C64.868 143.431 64.9702 143.648 65.1131 143.837C65.256 144.027 65.4366 144.185 65.6436 144.301L71.4445 147.642C71.8786 147.886 72.3894 147.958 72.8743 147.843C73.3592 147.727 73.7825 147.434 74.0594 147.02Z"
          fill="white"
        />
        <path
          d="M212.59 11.4143L242.383 28.5589C243.262 29.0682 243.886 28.6637 243.728 27.6601L240.061 1.28776C240.044 1.06383 239.973 0.847324 239.854 0.656693C239.735 0.466062 239.571 0.306956 239.377 0.192932C239.183 0.0789084 238.964 0.0133462 238.739 0.00183239C238.514 -0.00968143 238.29 0.0331944 238.085 0.126806L212.717 9.86382C211.763 10.2009 211.711 10.9049 212.59 11.4143Z"
          fill="white"
        />
        <path
          d="M297.965 143.667L288.73 138.342L306.599 123.145L315.841 128.462L297.965 143.667Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.3"
          d="M297.965 143.667L288.73 138.342L306.599 123.145L315.841 128.462L297.965 143.667Z"
          fill="black"
        />
        <path
          d="M303.646 147.175C298.919 143.834 290.714 146.351 283.771 148.538C282.095 149.092 280.585 149.594 279.217 150.036C278.783 151.05 278.553 152.139 278.541 153.242C278.541 158.612 283.102 157.144 293.021 153.894C296.363 152.745 299.786 151.846 303.262 151.205C303.571 153.931 305.472 156.41 312.888 152.515L303.646 147.175Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.3"
          d="M303.646 147.175C298.919 143.834 290.714 146.351 283.771 148.538C282.095 149.092 280.585 149.594 279.217 150.036C278.783 151.05 278.553 152.139 278.541 153.242C278.541 158.612 283.102 157.144 293.021 153.894C296.363 152.745 299.786 151.846 303.262 151.205C303.571 153.931 305.472 156.41 312.888 152.515L303.646 147.175Z"
          fill="black"
        />
        <path
          d="M280.758 217.01L271.515 211.692V200.816L280.758 206.149V217.01Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.3"
          d="M280.758 217.01L271.515 211.692V200.816L280.758 206.149V217.01Z"
          fill="black"
        />
        <path
          d="M296.409 175.738C296.409 180.053 293.141 183.91 288.317 186.711C280.622 191.168 277.624 187.543 276.97 183.992L275.34 183.049C276.656 182.648 277.918 182.087 279.097 181.378C282.749 179.259 285.499 176.532 286.611 173.454C292.299 171.596 296.409 170.87 296.409 175.738Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.3"
          d="M296.409 175.738C296.409 180.053 293.141 183.91 288.317 186.711C280.622 191.168 277.624 187.543 276.97 183.992L275.34 183.049C276.656 182.648 277.918 182.087 279.097 181.378C282.749 179.259 285.499 176.532 286.611 173.454C292.299 171.596 296.409 170.87 296.409 175.738Z"
          fill="black"
        />
        <path
          d="M262.055 202.377C261.032 201.786 259.861 201.503 258.681 201.56C258.501 201.021 258.328 200.452 258.185 199.837L248.95 194.52C249.867 198.519 251.7 201.006 254.127 202.384L263.347 207.702C263.347 207.702 264.903 204.234 262.055 202.377Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.3"
          d="M262.055 202.377C261.032 201.786 259.861 201.503 258.681 201.56C258.501 201.021 258.328 200.452 258.185 199.837L248.95 194.52C249.867 198.519 251.7 201.006 254.127 202.384L263.347 207.702C263.347 207.702 264.903 204.234 262.055 202.377Z"
          fill="black"
        />
        <path
          d="M293.051 153.877C283.132 157.127 278.571 158.595 278.571 153.225C278.583 152.123 278.813 151.033 279.247 150.019C272.485 152.109 269.329 152.364 269.329 147.907C269.329 144.634 271.545 140.597 277.669 137.047C284.199 133.302 287.851 134.156 288.76 138.342L306.576 123.145C306.501 122.921 306.411 122.711 306.328 122.494C307.44 122.246 308.509 121.834 309.499 121.273L302.128 116.906C301.902 116.756 301.662 116.621 301.421 116.487L301.316 116.427C299.138 115.374 296.699 114.976 294.298 115.281C291.106 115.638 287.99 116.493 285.063 117.812V117.603L294.306 112.247L285.063 106.922L278.804 110.555L270.321 115.468V126.328C257.81 135.414 249.98 147.301 249.98 160.123C249.98 165.973 251.565 169.291 254.12 170.976L254.248 171.044C254.463 171.179 254.689 171.306 254.924 171.426L262.656 175.763C262.873 174.584 262.71 173.367 262.19 172.287C264.643 171.969 267.064 171.443 269.426 170.714C278.819 167.883 287.16 163.434 287.16 170.377C287.158 171.418 286.969 172.451 286.604 173.426C292.299 171.568 296.41 170.842 296.41 175.71C296.41 180.024 293.141 183.882 288.317 186.683C280.622 191.14 277.624 187.514 276.971 183.964L275.34 183.02L267.728 178.631L248.943 194.495L258.178 199.813C258.479 201.172 258.957 202.486 259.598 203.723C263.205 203.783 267.345 202.607 271.508 200.787V205.236L280.75 206.367L286.115 203.243V192.383C298.769 183.088 307.5 170.557 307.5 157.472C307.568 155.236 307.214 153.008 306.456 150.903C302.278 150.971 297.386 152.491 293.051 153.877Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.4"
          d="M293.051 153.877C283.132 157.127 278.571 158.595 278.571 153.225C278.583 152.123 278.813 151.033 279.247 150.019C272.485 152.109 269.329 152.364 269.329 147.907C269.329 144.634 271.545 140.597 277.669 137.047C284.199 133.302 287.851 134.156 288.76 138.342L306.576 123.145C306.501 122.921 306.411 122.711 306.328 122.494C307.44 122.246 308.509 121.834 309.499 121.273L302.128 116.906C301.902 116.756 301.662 116.621 301.421 116.487L301.316 116.427C299.138 115.374 296.699 114.976 294.298 115.281C291.106 115.638 287.99 116.493 285.063 117.812V117.603L294.306 112.247L285.063 106.922L278.804 110.555L270.321 115.468V126.328C257.81 135.414 249.98 147.301 249.98 160.123C249.98 165.973 251.565 169.291 254.12 170.976L254.248 171.044C254.463 171.179 254.689 171.306 254.924 171.426L262.656 175.763C262.873 174.584 262.71 173.367 262.19 172.287C264.643 171.969 267.064 171.443 269.426 170.714C278.819 167.883 287.16 163.434 287.16 170.377C287.158 171.418 286.969 172.451 286.604 173.426C292.299 171.568 296.41 170.842 296.41 175.71C296.41 180.024 293.141 183.882 288.317 186.683C280.622 191.14 277.624 187.514 276.971 183.964L275.34 183.02L267.728 178.631L248.943 194.495L258.178 199.813C258.479 201.172 258.957 202.486 259.598 203.723C263.205 203.783 267.345 202.607 271.508 200.787V205.236L280.75 206.367L286.115 203.243V192.383C298.769 183.088 307.5 170.557 307.5 157.472C307.568 155.236 307.214 153.008 306.456 150.903C302.278 150.971 297.386 152.491 293.051 153.877Z"
          fill="white"
        />
        <path
          d="M306.463 150.877C302.277 150.967 297.386 152.487 293.02 153.873C283.101 157.123 278.54 158.591 278.54 153.221C278.552 152.118 278.782 151.029 279.217 150.015C280.276 147.506 282.643 144.825 286.888 142.36C293.403 138.615 297.055 139.484 297.964 143.664L315.84 128.459C314.12 123.321 310.641 120.647 305.463 120.535C302.36 120.452 298.626 121.284 294.312 123.134V112.266L285.078 117.599L279.585 120.782V131.65C267.059 140.743 259.229 152.622 259.229 165.437C259.151 167.819 259.531 170.194 260.349 172.433C263.114 179.316 270.688 178.372 278.661 176.036C281.463 175.197 284.176 174.215 286.603 173.429C292.299 171.572 296.409 170.845 296.409 175.714C296.409 180.028 293.14 183.885 288.316 186.686C280.622 191.143 277.624 187.518 276.97 183.967L258.185 199.816C258.486 201.175 258.964 202.49 259.605 203.726C262.054 208.295 266.42 209.561 271.515 208.887C274.708 208.391 277.818 207.461 280.757 206.123V216.983L295.364 208.58V197.674C308.011 188.387 316.75 175.856 316.75 162.763C316.75 153.341 312.264 150.742 306.463 150.877Z"
          fill="#42CE8F"
        />
        <path
          d="M279.578 131.674L270.335 126.349V115.488L279.578 120.806V131.674Z"
          fill="#42CE8F"
        />
        <path
          opacity="0.3"
          d="M279.578 131.674L270.335 126.349V115.488L279.578 120.806V131.674Z"
          fill="black"
        />
        <path
          d="M270.335 115.486C271.906 116.333 273.476 117.164 275.024 118.033C276.572 118.902 278.128 119.763 279.668 120.647H279.525C280.758 119.935 281.967 119.201 283.207 118.505L286.904 116.385L290.616 114.303C291.856 113.614 293.111 112.947 294.373 112.266C293.163 113.015 291.961 113.764 290.744 114.513L287.039 116.685L283.372 118.834C282.148 119.583 280.908 120.25 279.676 120.962L279.585 121.014L279.495 120.962C277.992 120.07 276.429 119.171 274.904 118.25C273.379 117.329 271.853 116.408 270.335 115.486Z"
          fill="white"
        />
        <path
          d="M279.578 131.673L279.382 120.805H279.781L279.578 131.673Z"
          fill="white"
        />
        <path
          d="M279.586 131.676L277.955 132.949C277.429 133.346 276.828 133.863 276.129 134.447C275.43 135.031 274.626 135.728 273.837 136.492C273.048 137.256 272.169 138.14 271.252 139.046C270.336 139.952 269.457 140.993 268.585 142.094C268.134 142.634 267.676 143.18 267.262 143.772C266.849 144.364 266.376 144.926 265.985 145.555C265.594 146.184 265.166 146.783 264.775 147.42L263.663 149.367C263.333 150.034 262.994 150.7 262.656 151.367C262.318 152.034 262.07 152.73 261.777 153.404C261.246 154.741 260.799 156.109 260.44 157.501C260.349 157.846 260.252 158.175 260.177 158.512C260.101 158.85 260.049 159.187 259.981 159.516C259.836 160.159 259.725 160.809 259.651 161.464C259.583 162.1 259.478 162.714 259.44 163.321C259.403 163.928 259.388 164.512 259.358 165.066C259.32 166.088 259.345 167.111 259.433 168.13C259.493 168.915 259.606 169.696 259.771 170.467C259.872 170.974 260.005 171.474 260.169 171.965C260.282 172.294 260.349 172.474 260.349 172.474L260.147 171.972C259.973 171.493 259.825 171.006 259.703 170.511C259.516 169.742 259.378 168.962 259.29 168.175C259.167 167.153 259.117 166.125 259.14 165.096C259.14 164.534 259.14 163.943 259.185 163.336C259.23 162.729 259.305 162.1 259.365 161.456C259.426 160.794 259.526 160.136 259.666 159.486C259.767 158.798 259.916 158.117 260.109 157.449C260.461 156.038 260.905 154.652 261.439 153.299C261.725 152.618 261.995 151.914 262.311 151.232L263.318 149.21L264.452 147.255C264.85 146.611 265.279 145.997 265.684 145.375C266.09 144.753 266.556 144.184 266.977 143.592C267.398 143.001 267.864 142.446 268.322 141.907C269.201 140.799 270.163 139.825 271.042 138.874C271.921 137.922 272.823 137.091 273.687 136.365C274.551 135.638 275.31 134.926 276.039 134.365C276.768 133.803 277.376 133.286 277.917 132.912L279.586 131.676Z"
          fill="white"
        />
        <path
          d="M306.463 150.877H306.118C305.892 150.877 305.554 150.877 305.133 150.967C303.971 151.102 302.818 151.299 301.677 151.558C300.257 151.865 298.626 152.307 296.883 152.839C295.14 153.371 293.299 153.948 291.458 154.562L288.693 155.431C287.783 155.715 286.874 155.977 285.995 156.18C285.145 156.414 284.282 156.596 283.41 156.726C282.626 156.862 281.827 156.887 281.036 156.801C280.348 156.745 279.709 156.429 279.247 155.917L278.984 155.558C278.924 155.438 278.879 155.303 278.827 155.183C278.732 154.969 278.669 154.742 278.639 154.509C278.576 154.186 278.546 153.857 278.549 153.528C278.564 153.854 278.612 154.178 278.691 154.494C278.732 154.721 278.805 154.94 278.909 155.146C278.958 155.266 279.013 155.384 279.075 155.498L279.338 155.82C279.798 156.269 280.407 156.536 281.051 156.569C281.82 156.627 282.594 156.579 283.35 156.427C283.756 156.352 284.177 156.277 284.597 156.165C285.018 156.052 285.454 155.962 285.89 155.835C286.761 155.603 287.663 155.333 288.572 155.041L291.323 154.15C293.163 153.551 295.027 152.974 296.778 152.472C298.529 151.97 300.189 151.581 301.617 151.304C302.774 151.085 303.943 150.937 305.118 150.862C305.539 150.862 305.87 150.824 306.103 150.824L306.463 150.877Z"
          fill="white"
        />
        <path
          d="M296.41 175.742V176.027C296.414 176.33 296.394 176.633 296.349 176.933C296.301 177.402 296.211 177.866 296.079 178.319C295.916 178.912 295.692 179.487 295.41 180.034C295.087 180.687 294.705 181.308 294.268 181.891C293.779 182.54 293.236 183.146 292.645 183.704C291.362 184.92 289.939 185.981 288.407 186.865L287.227 187.494C286.829 187.696 286.401 187.861 286.002 188.041C285.194 188.377 284.353 188.628 283.493 188.79C282.678 188.944 281.842 188.944 281.028 188.79C280.654 188.712 280.289 188.597 279.939 188.445C279.618 188.286 279.313 188.095 279.029 187.876C278.543 187.487 278.135 187.009 277.827 186.468C277.595 186.056 277.407 185.622 277.264 185.172C277.166 184.886 277.091 184.593 277.038 184.296L276.978 183.981C276.978 183.981 276.978 184.086 277.053 184.288C277.125 184.581 277.216 184.868 277.324 185.15C277.492 185.584 277.703 186.001 277.955 186.393C278.278 186.898 278.69 187.339 279.172 187.696C279.442 187.901 279.735 188.074 280.044 188.213C280.379 188.351 280.73 188.449 281.088 188.505C281.866 188.627 282.659 188.614 283.433 188.468C284.262 188.298 285.072 188.047 285.852 187.719C286.25 187.531 286.656 187.382 287.047 187.172L288.227 186.535C288.978 186.086 289.729 185.622 290.436 185.097C291.133 184.604 291.794 184.061 292.412 183.472C293.534 182.457 294.481 181.265 295.215 179.944C295.516 179.419 295.767 178.867 295.966 178.296C296.118 177.855 296.234 177.401 296.312 176.941C296.367 176.644 296.402 176.343 296.417 176.042L296.41 175.742Z"
          fill="white"
        />
        <path
          d="M248.95 194.522L253.624 197.091L258.268 199.697H258.08L262.754 195.713L267.503 191.751C269.065 190.432 270.651 189.129 272.229 187.833C273.807 186.537 275.415 185.249 277.008 183.961C275.468 185.309 273.942 186.68 272.387 188.013C270.831 189.346 269.291 190.694 267.728 192.02L262.994 196.027L258.298 199.99L258.2 200.072L258.102 200.012L253.511 197.301L248.95 194.522Z"
          fill="white"
        />
        <path
          d="M295.365 197.679L296.792 196.54C297.251 196.188 297.799 195.746 298.408 195.207C299.017 194.668 299.723 194.106 300.444 193.417C301.166 192.728 301.947 192.031 302.766 191.17L303.991 189.919L305.223 188.571C305.652 188.121 306.065 187.642 306.463 187.148C306.861 186.653 307.297 186.166 307.695 185.65C308.447 184.616 309.281 183.567 309.995 182.451C310.709 181.335 311.4 180.204 312.069 179.103C312.737 178.002 313.271 176.856 313.744 175.673C314.218 174.489 314.638 173.426 315.052 172.355L315.51 170.767L315.736 170.018L315.893 169.269C316.107 168.392 316.275 167.504 316.397 166.61C316.517 165.924 316.597 165.231 316.637 164.535C316.705 163.382 316.75 162.723 316.75 162.723C316.75 162.723 316.75 163.382 316.705 164.543C316.69 165.242 316.635 165.94 316.54 166.632C316.453 167.534 316.31 168.43 316.111 169.314C316.066 169.561 316.014 169.808 315.968 170.063C315.923 170.318 315.826 170.572 315.758 170.812L315.322 172.422C314.932 173.493 314.571 174.669 314.06 175.785C313.549 176.901 312.993 178.092 312.392 179.253C311.791 180.414 311.092 181.56 310.325 182.646C309.559 183.732 308.777 184.818 307.981 185.859C307.583 186.376 307.147 186.855 306.734 187.357C306.32 187.859 305.915 188.338 305.479 188.788L304.224 190.129L302.969 191.357C302.158 192.166 301.331 192.855 300.595 193.529C299.858 194.203 299.092 194.75 298.498 195.267C297.905 195.784 297.303 196.203 296.83 196.54L295.365 197.679Z"
          fill="white"
        />
        <path
          d="M271.515 208.911C272.312 208.776 273.101 208.604 273.875 208.394C274.649 208.185 275.422 207.96 276.189 207.705C277.692 207.188 279.195 206.619 280.697 205.967L280.968 205.848V206.147C280.968 207.054 280.968 207.96 280.968 208.859V211.578C280.968 213.39 280.885 215.195 280.81 217.008C280.727 215.195 280.682 213.39 280.652 211.578V208.859C280.652 207.96 280.652 207.054 280.652 206.147L280.923 206.32C279.439 206.968 277.915 207.523 276.362 207.982C274.782 208.455 273.158 208.766 271.515 208.911Z"
          fill="white"
        />
        <path
          d="M286.889 142.363C287.378 142.086 287.851 141.786 288.354 141.531C288.858 141.277 289.361 141.022 289.857 140.782C290.114 140.662 290.377 140.557 290.646 140.468C290.907 140.359 291.176 140.269 291.45 140.198C291.728 140.131 291.991 140.041 292.277 139.996C292.562 139.951 292.833 139.884 293.118 139.861C293.406 139.839 293.695 139.839 293.982 139.861C294.271 139.863 294.558 139.896 294.839 139.958C295.127 140.013 295.406 140.101 295.673 140.221C295.938 140.341 296.19 140.49 296.425 140.662C296.658 140.843 296.867 141.052 297.048 141.284C297.239 141.513 297.401 141.765 297.529 142.033C297.667 142.289 297.785 142.554 297.882 142.827C297.972 143.097 298.04 143.374 298.115 143.651L297.83 143.554L302.286 139.741L306.794 135.959L311.303 132.214L315.849 128.469L311.453 132.356L307.019 136.206L302.571 140.018L298.063 143.808L297.845 143.988L297.77 143.711C297.694 143.449 297.634 143.179 297.552 142.962C297.46 142.706 297.352 142.456 297.229 142.213C297.108 141.971 296.959 141.745 296.785 141.539C296.62 141.329 296.433 141.136 296.229 140.962C296.018 140.797 295.788 140.656 295.545 140.543C295.307 140.423 295.055 140.335 294.794 140.281C294.529 140.213 294.256 140.176 293.982 140.168C293.707 140.149 293.431 140.149 293.156 140.168C292.881 140.188 292.608 140.223 292.337 140.273C292.059 140.31 291.788 140.393 291.518 140.453C291.247 140.513 290.984 140.61 290.714 140.692C290.459 140.772 290.208 140.864 289.962 140.97C289.436 141.164 288.925 141.389 288.415 141.636C287.904 141.883 287.385 142.108 286.889 142.363Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default ImageBanner;
