import "./WindowLoader.css";
const WindowLoader = () => {
  return (
    <>
      <div className="loader-wrapper hidden">
      <span className="loader"></span>
      </div>
    </>
  );
};

export default WindowLoader;
