import React, { createContext, useState, useContext, useEffect } from "react";

const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // GET THE DARK MODE FROM LOCAL STORGE
    const storedMode = localStorage.getItem("isDarkMode");
    return storedMode ? JSON.parse(storedMode) : true;
  });

  useEffect(() => {
    // UPDATE LOCAL STORAGE MODE
    localStorage.setItem("isDarkMode", JSON.stringify(isDarkMode));
    // CHANGE CLASSIN BODY
    document.body.classList.toggle("dark-mode", isDarkMode);
    document.body.classList.toggle("light-mode", !isDarkMode);
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export const useDarkMode = () => useContext(DarkModeContext);
