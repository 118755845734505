import ContainerMedia from "../../Components/ContainerMedia/ContainerMedia";
import { useContext, useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import DownloadIcon from "../../Assets/Icons/DownloadIcon";
import "./NavBarMenu.css";
import FormAuth from "Components/Auth/FormAuth";
import ModalDownloadApp from "Components/Ui/ModalDownloadApp/ModalDownloadApp";
import ApplicationManagePortfolio from "Components/ApplicationManagePortfolio/ApplicationManagePortfolio";
import LogoInfo from "Components/LogoInfo/LogoInfo";
import DarkLightToggle from "Components/DarkLightToggle/DarkLightToggle";
import { UserAuthContext } from "Context/UserAuthProvider/UserAuthProvider";
import UserDropMenu from "Components/Ui/UserDropMenu/UserDropMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
const NavBarMenu = () => {
  // FIXED TOP NAVBAR
  const [isMenuFixed, setMenuFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      // Check the scroll position
      const scrollTop = window.scrollY;
      const shouldBeFixed = scrollTop > 100; // Change 100 to the scroll position where you want the menu to become fixed
      // Update state to trigger the fixed
      setMenuFixed(shouldBeFixed);
    };
    window.addEventListener("scroll", handleScroll);
  }, []);
  //  SHOW LOGIN FORM
  const [showModalForm, setShowLogin] = useState(false);
  const buttonShowLogin = () => {
    setShowLogin(true);
  };
  const hideModalForm = () => {
    setShowLogin(false);
  };
  //  SHOW DOWNLOAD APP
  const [showModalDownloadApp, setShowModalDownloadApp] = useState(false);
  const buttonShowDownloadApp = () => {
    setShowModalDownloadApp(true);
  };
  const hideModalDownloadApp = () => {
    setShowModalDownloadApp(false);
  };

  //  SHOW MODAL APP
  const [showModalApplication, setShowModalApplication] = useState(false);
  const buttonShowModalApplication = () => {
    setShowModalApplication(true);
  };
  const hideModalApplication = () => {
    setShowModalApplication(false);
  };

  // CONTEXT FOR LOGIN
  let { userIsLoginToken } = useContext(UserAuthContext);

  const [showSidebar, setShowSidebar] = useState(false);

  // Function to toggle sidebar
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  // Function to close sidebar
  const closeSidebar = () => {
    setShowSidebar(false);
  };

  useEffect(() => {
    window.onscroll = () => {
      setShowSidebar(false);
    };
  }, []);

  return (
    <>
      <FormAuth showModalForm={showModalForm} hideModalForm={hideModalForm} />
      <ModalDownloadApp
        showModalDownloadApp={showModalDownloadApp}
        hideModalDownloadApp={hideModalDownloadApp}
      />
      <ApplicationManagePortfolio
        showModalApplication={showModalApplication}
        hideModalApplication={hideModalApplication}
      />
      <Navbar
        expand="lg"
        className={`navbar-menu z-3  ${isMenuFixed ? "menu-fixed" : ""}`}
      >
        <ContainerMedia>
          <Navbar.Brand data-aos="fade-left">
            <LogoInfo />
          </Navbar.Brand>

          {/* ========= START MAIN LEFT ========= */}
          <div
            className="main-info-left d-flex align-items-center gap-3"
            data-aos="fade-right"
          >
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={toggleSidebar}
            />
            {userIsLoginToken !== null ? (
              <div className="mobile-screen">
                <UserDropMenu />
              </div>
            ) : (
              ""
            )}
            <DarkLightToggle />
          </div>
          {/* ========= END MAIN LEFT ========= */}

          <div
            onClick={closeSidebar}
            className={`all-main-navbar ${
              showSidebar === true ? "show-main" : ""
            }`}
          >
            <div
              className={`overlay-menu   ${
                showSidebar === true ? "show-over" : ""
              }`}
              onClick={closeSidebar}
            ></div>

            <Navbar.Collapse
              id="basic-navbar-nav"
              className={`nav-menu ${showSidebar === true ? "show--1" : ""}`}
            >
              <div onClick={closeSidebar} className="icon-close-menu">
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <Nav className="me-auto gap-3" data-aos="fade-right">
                <NavLink className={"nav-link "} to="/recommendationsPage">
                  {" "}
                  التوصيات
                </NavLink>
                <NavLink className={"nav-link "} to="/newsPage">
                  {" "}
                  الأخبار
                </NavLink>
                <NavLink className={"nav-link "} to="/blogsPage">
                  {" "}
                  المدونات
                </NavLink>
                {userIsLoginToken !== null && (
                  <button
                    onClick={buttonShowModalApplication}
                    className="btn-main btn-main--2 btn-transparent"
                  >
                    طلب إدارة محفظتك
                  </button>
                )}
               
              </Nav>
              <div className="left-nav-menu d-flex align-items-center  gap-3">
                <button onClick={buttonShowDownloadApp} className="btn-main">
                  حمل التطبيق <DownloadIcon />
                </button>
                {userIsLoginToken !== null ? (
                  <div className="mb-screen">
                    <UserDropMenu />
                  </div>
                ) : (
                  <button
                    onClick={buttonShowLogin}
                    className="btn-main btn-transparent"
                  >
                    تسجيل الدخول
                  </button>
                )}

                <DarkLightToggle />
              </div>
            </Navbar.Collapse>
          </div>
        </ContainerMedia>
      </Navbar>
    </>
  );
};

export default NavBarMenu;
