import { useRef, useState, useEffect } from "react";
import CustomModal from "Components/CustomModal/CustomModal";
import "./OtpForm.css";
import { toast } from "react-toastify";

const OtpForm = ({
  showOtpForm,
  hideOtpForm,
  email,
  otp,
  setChangePasswordModal
}) => {
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [inputValue, setInputValue] = useState(["", "", "", ""]);

  useEffect(() => {
    if (showOtpForm) {
      focusInput(0);
    }
  }, [showOtpForm]);

  const handleInputChange = (index, e) => {
    const value = e.target.value;
    if (value.length > 1) return;  

    setInputValue((prev) => {
      const newValue = [...prev];
      newValue[index] = value;
      return newValue;
    });

    const nextIndex = index + 1;
    if (value.length === 1 && nextIndex <= 3) {
      focusInput(nextIndex);
    }
  };

  const handleKeyDown = (index, e) => {
    const value = e.target.value;
    const prevIndex = index - 1;

    if (e.key === "Backspace" && index > 0 && value.length === 0) {
      focusInput(prevIndex);
    }
  };

  const focusInput = (index) => {
    if (inputRefs[index] && inputRefs[index].current) {
      inputRefs[index].current.focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputValue.join("") === otp.toString()) {
      setChangePasswordModal(true);
      hideOtpForm();
      toast.success("تم التاكيد بنجاح.");
      setInputValue(["", "", "", ""]);
    } else {
      toast.error(".رمز الامان غير صحيح");
    }
  };

  return (
    <>
      <CustomModal
        show={showOtpForm}
        onHide={hideOtpForm}
        title={" رمز الامان"}
        newClass={"otp-form-modal"}
      >
        <div className="info-otp-form">
          <div className="otp-form">
            <form action="">
              <div
                className="all-input-otp d-flex align-items-center justify-content-center gap-2"
                style={{ direction: 'ltr', textAlign: 'left' }}
              >
                {[0, 1, 2, 3].map((index) => (
                  <input
                    key={index}
                    type="number" 
                    name={`digit${index + 1}`}
                    className="form-control"
                    maxLength={1}
                    value={inputValue[index]}
                    onChange={(e) => handleInputChange(index, e)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    ref={inputRefs[index]}
                  />
                ))}
              </div>
              <button
                onClick={(e) => {
                  handleSubmit(e);
                }}
                className="btn-main btn-page-otp w-100"
              >
                تفعيل رمز الامان
              </button>
            </form>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default OtpForm;
