import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import BreadcrumbsPage from "Components/Ui/BreadcrumbsPage/BreadcrumbsPage";
import RecommendationsCards from "./Components/RecommendationsCards";

const RecommendationsPage = () => {
  return (
    <>
      <HelmetInfo titlePage={"التوصيات"} />
      <header>
        <BreadcrumbsPage
          newClassBread={"breadcrumb-page-route"}
          routeTitleTwoBread={"/recommendationsPage"}
          titleTwoBread={"التوصيات"}
          textBreadActive={false}
          isTruePageRoute={false}
        />
      </header>
      <main>
        <RecommendationsCards />
      </main>
    </>
  );
};

export default RecommendationsPage;
