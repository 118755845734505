import { useState, useEffect, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UseFetchProfile from "./Components/UseFetchProfile/UseFetchProfile";
import ErrorFetch from "Components/Ui/LoaderErrorFetch/ErrorFetch/ErrorFetch";
import LoaderFetch from "Components/Ui/LoaderErrorFetch/LoaderFetch/LoaderFetch";
import LogOutFunction from "Components/Ui/UserDropMenu/LogOutFunction";
import UserIcon from "Assets/Icons/UserIcon";
import BreadcrumbsPage from "Components/Ui/BreadcrumbsPage/BreadcrumbsPage";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import LogOutIcon from "Assets/Icons/LogOutIcon";
import UserDetailsInfo from "./Components/UserDetailsInfo/UserDetailsInfo";
import "./UserAccount.css";
import CustomModal from "Components/CustomModal/CustomModal";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import axios from "axios";
import { UserAuthContext } from "Context/UserAuthProvider/UserAuthProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useUserImage } from "Context/UserDetailsProvider/UserImageContextProvider";
import BASE_URL from "config";
import { toast } from "react-toastify";

registerPlugin(FilePondPluginImagePreview, FilePondPluginImageExifOrientation);
const UserAccount = () => {
  const [tabs, setTabs] = useState([
    { id: 1, title: "المعلومات الشخصية", icon: <UserIcon />, active: true }
  ]);
  let { userIsLoginToken, setUserIsLoginToken } = useContext(UserAuthContext);
  const { setUserImage } = useUserImage();

  const handleTabClick = (id) => {
    setTabs(
      tabs.map((tab) => ({
        ...tab,
        active: tab.id === id
      }))
    );
  };

  // FETCH DATA NEWS CARDS
  const { data, error, isLoading } = UseFetchProfile("getProfileData");
  const [userData, setUserData] = useState(null);
  const [ImageEdit, setImageEdit] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [ImageLoading, setImageLoading] = useState(false);
  const handleSave = async () => {
    setImageLoading(true);
    const formData = new FormData();
    formData.append("image", uploadImage);
    axios
      .post(`${BASE_URL}/updateprofile`, formData, {
        headers: {
          Authorization: `Bearer ${userIsLoginToken}`,
          Accept: "multipart/form-data"
        }
      })
      .then(async (res) => {
        try {
          if (userIsLoginToken) {
            const response = await axios.get(`${BASE_URL}/profile`, {
              headers: {
                Authorization: `Bearer ${userIsLoginToken}`
              }
            });
            setImageSrc(response.data.data.image);
            setUserImage(response.data.data.image);
            setImageLoading(false);
            setImageEdit(false);
            setTimeout(() => {
              toast.success("تم تغير الصورة بنجاح.");
            }, 1000);
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            setUserIsLoginToken(null);
            localStorage.removeItem("token");
            logOut();
          }
          throw error;
        }
      });
  };

  const handleImageChange = (fileItems) => {
    // Check if fileItems array is not empty
    if (fileItems.length > 0) {
      setUploadImage(fileItems[0].file);
    } else {
      // If fileItems array is empty, clear the uploadImage state
      setUploadImage(null);
    }
  };
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (data && data.data) {
      setUserData(data.data);
      setImageSrc(data.data.image); // Update image
      setUserImage(data.data.image);
    }
  }, [data]);

  //update user data
  const updateUser = (newUserData) => {
    setUserData(newUserData);
  };

  const logOut = LogOutFunction();

  // ERROR
  if (error) return <ErrorFetch />;
  // LOADING
  if (isLoading) return <LoaderFetch />;

  return (
    <>
      <HelmetInfo titlePage={"حسابى"} />
      <BreadcrumbsPage
        newClassBread={"breadcrumb-page-route"}
        routeTitleTwoBread={"/userAccount"}
        titleTwoBread={"الحساب"}
        textBreadActive={false}
        isTruePageRoute={false}
      />

      {/* ============== START MODAL EDIT IMAGE ================= */}
      <CustomModal
        show={ImageEdit}
        onHide={() => setImageEdit(false)}
        title={"تعديل الصورة"}
        newClass={"modal-edit-image"}
      >
        <div className="file-pond-upload">
          <label
            htmlFor="exampleFormControlInputPicFile"
            className="form-label"
          >
            أضف صورة
          </label>
          <FilePond
            allowMultiple={true}
            acceptedFileTypes={["image/*"]}
            onupdatefiles={handleImageChange}
            maxFiles={3}
            server={false}
            name="files"
            labelIdle={`أضف الصورة الشخصية`}
            className="file-upload--1"
          />
        </div>
        <button
          type="submit"
          className={`btn-main btn-height  w-100 mt-4 ${
            ImageLoading ? "bttDisabled" : ""
          }`}
          onClick={handleSave}
          disabled={ImageLoading || !uploadImage ? "disabled" : ""}
        >
          {ImageLoading ? (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            "حفظ"
          )}
        </button>
      </CustomModal>
      {/* ============== END MODAL EDIT IMAGE ================= */}

      <main>
        <div className="account-user-content padding-top-30">
          <ContainerMedia>
            <div className="all-tabs-content-account">
              {/* START ROW */}
              <div className="row g-3 align-items-start ">
                {/* START COL */}
                <div className="col-12 col-md-4 col-lg-3">
                  <ul
                    data-aos="fade-down"
                    className="nav nav-pills flex-column nav-pills border-account-user h-100"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <div className="header-user-info-profile">
                        <div
                          className="image-user"
                          onClick={() => setImageEdit(true)}
                        >
                          {imageSrc ? (
                            <img
                              src={imageSrc}
                              alt="imgUser"
                              className="object-fit-cover"
                              width={"82px"}
                              height={"82px"}
                            />
                          ) : (
                            <div
                              className="spinner-border border-danger border-2"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}
                          <div className="edit-icon-image">
                            <FontAwesomeIcon icon={faEdit} />
                          </div>
                        </div>

                        <h2 className="name-user-info">{userData?.name}</h2>
                      </div>
                    </li>

                    {tabs.map((tab) => (
                      <li
                        key={tab.id}
                        className="nav-item nav-item-info"
                        role="presentation"
                      >
                        <button
                          className={`nav-link ${
                            tab.active ? "active" : ""
                          } position-relative`}
                          id={`pills-${tab.title}-tab`}
                          data-bs-toggle="pill"
                          data-bs-target={`#pills-${tab.title.toLowerCase()}`}
                          type="button"
                          role="tab"
                          aria-controls={`pills-${tab.title.toLowerCase()}`}
                          aria-selected={tab.active ? "true" : "false"}
                          onClick={() => handleTabClick(tab.id)}
                        >
                          {tab.icon} {tab.title}
                        </button>
                      </li>
                    ))}
                    <li className="nav-item" role="presentation">
                      <div
                        onClick={logOut}
                        className="logout-button d-flex  align-items-center  gap-2 "
                      >
                        <LogOutIcon /> تسجيل الخروج
                      </div>
                    </li>
                  </ul>
                </div>
                {/* END COL */}
                {/* START COL */}
                <div className="col-12 col-md-8 col-lg-9">
                  {/* START CONTENT INFO TABS ACCOUNT */}
                  <div
                    className="tab-content w-100 border-account-user h-100"
                    id="pills-tabContent"
                    data-aos="fade-up"
                  >
                    {tabs.map((tab) => (
                      <div
                        key={tab.id}
                        className={`tab-pane fade ${
                          tab.active ? "show active" : ""
                        }`}
                        id={`pills-${tab.title.toLowerCase()}`}
                        role="tabpanel"
                        aria-labelledby={`pills-${tab.title.toLowerCase()}-tab`}
                      >
                        {/* Content for each tab */}
                        {tab.title === "المعلومات الشخصية" && (
                          <>
                            <UserDetailsInfo
                              updateUser={updateUser}
                              userData={userData}
                            />
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                  {/* END CONTENT INFO TABS ACCOUNT */}
                </div>
                {/* END COL */}
              </div>
              {/* END ROW */}
            </div>
          </ContainerMedia>
        </div>
      </main>
    </>
  );
};

export default UserAccount;
