import React, { useState } from "react";
import "./UserDetailsInfo.css";
import "react-datepicker/dist/react-datepicker.css";
import ModalEditInfoUser from "../ModalEditInfoUser/ModalEditInfoUser";
import LoaderFetch from "Components/Ui/LoaderErrorFetch/LoaderFetch/LoaderFetch";

const UserDetailsInfo = ({ updateUser, userData }) => {
  const [showModalEdit, setShowModalEdit] = useState(false);

  const handleEditClick = () => {
    setShowModalEdit(true);
  };

  const hideModalEditAccount = () => {
    setShowModalEdit(false);
  };

  if (!userData) {
    return (
      <div>
        <LoaderFetch />
      </div>
    );
  }

  return (
    <>
      <ModalEditInfoUser
        showModalEditAccount={showModalEdit}
        hideModalEditAccount={hideModalEditAccount}
        userData={userData}
        setUserData={updateUser}
      />

      <div className="user-details-info">
        <div className="header-title">
          <h2 className="title">المعلومات الشخصية</h2>
        </div>
        <div className="user-content-info-details padding-top-30">
          <form>
            <div className="row g-3">
              <div className="col-12 col-sm-6">
                <div className="input-user-one">
                  <label htmlFor="nameUser_1" className="form-label">
                    إسم المستخدم
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="nameUser_1"
                    value={userData.name}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-12 col-sm-6">
                <div className="input-user-one">
                  <label htmlFor="emailUser_2" className="form-label">
                    البريد الإلكتروني
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="emailUser_2"
                    value={userData.email}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="input-user-one">
                  <label htmlFor="phoneUser_2" className="form-label">
                    رقم الهاتف
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phoneUser_2"
                    value={userData.phone}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-12 col-sm-6">
                <div className="input-user-one">
                  <label htmlFor="countryUser_2" className="form-label">
                    الدولة
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="countryUser_2"
                    value={userData.country}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </form>
          <button
            className="btn-main btn-edit-info btn-transparent"
            onClick={handleEditClick}
          >
            تعديل
          </button>
        </div>
      </div>
    </>
  );
};

export default UserDetailsInfo;
