import React from "react";

const MoonIcon = () => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.7068 21.2405C28.4934 20.8805 27.8934 20.3205 26.4001 20.5871C25.5734 20.7338 24.7334 20.8005 23.8934 20.7605C20.7868 20.6271 17.9734 19.2005 16.0134 17.0005C14.2801 15.0671 13.2134 12.5471 13.2001 9.82715C13.2001 8.30715 13.4934 6.84048 14.0934 5.45381C14.6801 4.10715 14.2668 3.40048 13.9734 3.10715C13.6668 2.80048 12.9468 2.37381 11.5334 2.96048C6.0801 5.25381 2.70677 10.7205 3.10677 16.5738C3.50677 22.0805 7.37343 26.7871 12.4934 28.5605C13.7201 28.9871 15.0134 29.2405 16.3468 29.2938C16.5601 29.3071 16.7734 29.3205 16.9868 29.3205C21.4534 29.3205 25.6401 27.2138 28.2801 23.6271C29.1734 22.3871 28.9334 21.6005 28.7068 21.2405Z"
          fill="#121212"
        />
      </svg>
    </>
  );
};

export default MoonIcon;
