import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import PaginationPage from "Components/Pagination/Pagination";
import CardBoxBlog from "Components/Ui/CardBoxBlog/CardBoxBlog";
import { useNavigate } from "react-router-dom";
import UseImageLoading from "Components/Ui/UseImageLoading/UseImageLoading";
import ErrorFetch from "Components/Ui/LoaderErrorFetch/ErrorFetch/ErrorFetch";
import LoaderFetch from "Components/Ui/LoaderErrorFetch/LoaderFetch/LoaderFetch";
import useFetchBlogs from "./GetCardsBlogs/GetCardsBlogs";
import usePagination from "Components/Pagination/usePagination";
import { formatDistanceToNow } from "date-fns";
import { ar } from "date-fns/locale";
import NotFoundDataPage from "Components/Ui/NotFoundDataPage/NotFoundDataPage";
const AllCardsBlogs = () => {
  // NAVIGATE TO THE PAGE DETAILS USING ID OF CARD
  const navigate = useNavigate();
  // FETCHH DATA NEWS CARDS
  const { data, error, isLoading } = useFetchBlogs("allBlogsCards");
  // PAGINATION CARDS
  const { setCurrentPage, totalPages, currentPageData } = usePagination(
    data?.data.data, // THIS DATA OF ARRAY
    12, // THIS HOW MANY CARDS TO SHOW IN PAGE I'M ADD "12" CARDS
    3 // THIS "3" FOR START FROM NUMBER CARD 3 BECAUSE I USE FROM "0" TO "3" IN HEADER
  );

  // Pagination
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // LOADING WHEN GET IMAGE
  const { handleImageLoad } = UseImageLoading(data, isLoading);

  // ERROR
  if (error) return <ErrorFetch />;
  // LOADING
  if (isLoading) return <LoaderFetch />;

  return (
    <div className="all-cards-blogs-page padding-top-30">
      {/* ================= START CONTAINER =================== */}
      <ContainerMedia>
        {/* =============== START MAIN CARDS NEWS ============== */}
        <div className="main-cards-blogs-page" data-aos="fade-up">
          {/* ============== START ROW ================ */}
          <div className="row g-3">
            {currentPageData && currentPageData.length > 0 ? (
              currentPageData.map((item) => (
                <div
                  key={item.id}
                  onClick={() => navigate(`/detailsPageBlog/${item.id}`)}
                  className="col-12 col-sm-6 col-lg-4"
                >
                  <CardBoxBlog
                    image={item.image}
                    onImageLoad={() => handleImageLoad(item.id)}
                    badgeText={item.category.title}
                    title={item.title.split(" ").slice(0, 20).join(" ")}
                    imagePerson={item.admin.image}
                    nameUser={item.admin.name}
                    timeAdd={formatDistanceToNow(
                      new Date(item.admin.created_at),
                      {
                        addSuffix: true,
                        locale: ar
                      }
                    )}
                    isTrueTextContent={false}
                    textContent={false}
                  />
                </div>
              ))
            ) : (
              <NotFoundDataPage />
            )}
          </div>
          {/* ============== END ROW ================ */}
          {currentPageData.length > 0 ? (
            <>
              {/* ============ START PAGINATION ============= */}
              <PaginationPage
                itemCount={totalPages}
                onPageChange={handlePageChange}
              />
              {/* ============ END PAGINATION ============= */}
            </>
          ) : (
            ""
          )}
        </div>
        {/* =============== END MAIN CARDS NEWS ============== */}
      </ContainerMedia>
      {/* ================= END CONTAINER =================== */}
    </div>
  );
};

export default AllCardsBlogs;
