import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import BreadcrumbsPage from "Components/Ui/BreadcrumbsPage/BreadcrumbsPage";
import HeaderDetailsPage from "./HeaderDetailsPage";
import SliderNewsCards from "./SliderNewsCards";

const DetailsPageNews = () => {
  return (
    <>
      <HelmetInfo titlePage={"تفاصيل الخبر"} />
      <header>
        <BreadcrumbsPage
          newClassBread={"bread-crumbs"}
          titleTwoBread={"الأخبار"}
          routeTitleTwoBread={"/newsPage"}
          textBreadActive={"تفاصيل الخبر"}
          isTruePageRoute={true}
        />
      </header>
      <main>
        <HeaderDetailsPage /> <SliderNewsCards />
      </main>
    </>
  );
};

export default DetailsPageNews;
