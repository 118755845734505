import React from 'react'

const TextIcon = () => {
  return (
    <><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29.3334 13.3346V20.0013C29.3334 26.668 26.6667 29.3346 20.0001 29.3346H12.0001C5.33341 29.3346 2.66675 26.668 2.66675 20.0013V12.0013C2.66675 5.33464 5.33341 2.66797 12.0001 2.66797H18.6667" stroke="#42CE8F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M29.3334 13.3346H24.0001C20.0001 13.3346 18.6667 12.0013 18.6667 8.0013V2.66797L29.3334 13.3346Z" stroke="#42CE8F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.33325 17.332H17.3333" stroke="#42CE8F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.33325 22.668H14.6666" stroke="#42CE8F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    </>
  )
}

export default TextIcon