import React from 'react'

const TradeIcon = () => {
  return (
    <><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 29.332H28" stroke="#42CE8F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.46663 11.1758H5.33333C4.6 11.1758 4 11.7758 4 12.5091V24.0024C4 24.7358 4.6 25.3358 5.33333 25.3358H7.46663C8.19997 25.3358 8.79997 24.7358 8.79997 24.0024V12.5091C8.79997 11.7758 8.19997 11.1758 7.46663 11.1758Z" stroke="#42CE8F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.0667 6.92188H14.9334C14.2001 6.92188 13.6001 7.52188 13.6001 8.25521V24.0019C13.6001 24.7352 14.2001 25.3352 14.9334 25.3352H17.0667C17.8001 25.3352 18.4001 24.7352 18.4001 24.0019V8.25521C18.4001 7.52188 17.8001 6.92188 17.0667 6.92188Z" stroke="#42CE8F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M26.6666 2.66797H24.5333C23.8 2.66797 23.2 3.26797 23.2 4.0013V24.0013C23.2 24.7346 23.8 25.3346 24.5333 25.3346H26.6666C27.3999 25.3346 27.9999 24.7346 27.9999 24.0013V4.0013C27.9999 3.26797 27.3999 2.66797 26.6666 2.66797Z" stroke="#42CE8F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    </>
  )
}

export default TradeIcon