import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import "./BannerHome.css";
import ImageBanner from "Assets/images/main/ImageBanner";
import { useState } from "react";
import ApplicationManagePortfolio from "Components/ApplicationManagePortfolio/ApplicationManagePortfolio";
const BannerHome = () => {
  //  SHOW MODAL APP
  const [showModalApplication, setShowModalApplication] = useState(false);
  const buttonShowModalApplication = () => {
    setShowModalApplication(true);
  };
  const hideModalApplication = () => {
    setShowModalApplication(false);
  };
  return (
    <>
      <ApplicationManagePortfolio
        showModalApplication={showModalApplication}
        hideModalApplication={hideModalApplication}
      />
      <div className="banner-home padding-top">
        {/* ============== START CONTAINER =================== */}
        <ContainerMedia>
          {/* ================= START ALL BANNER HOME ============= */}
          <div className="all-banner-home">
            {/* ============== START ROW ============== */}
            <div className="row g-4 g-md-3 align-items-center">
              {/* ============== START COL ============ */}
              <div className="col-12 col-md-7">
                {/* =========== START CONTENT BANNER =========== */}
                <div className="content-banner" data-aos="fade-left">
                  <h2 className="title">اجعل أموالك تعمل لأجلك!</h2>
                  <p className="text">
                    قدم طلب لإدارة محفظتك وإستفد من خبراتنا في عالم التداول
                  </p>
                  <button
                    className="btn-main"
                    onClick={buttonShowModalApplication}
                  >
                    تقديم طلب
                  </button>
                </div>
                {/* =========== END CONTENT BANNER =========== */}
              </div>
              {/* ============== END COL ============ */}
              {/* ============== START COL ============ */}
              <div className="col-12 col-md-5">
                {/* =========== START IMAGE BANNER =========== */}
                <div className="image-banner" data-aos="fade-right">
                  <ImageBanner />
                </div>
                {/* =========== END IMAGE BANNER =========== */}
              </div>
              {/* ============== END COL ============ */}
            </div>
            {/* ============== END ROW ============== */}
          </div>
          {/* ================= END ALL BANNER HOME ============= */}
        </ContainerMedia>
        {/* ============== END CONTAINER =================== */}
      </div>
    </>
  );
};

export default BannerHome;
