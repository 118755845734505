import { UserAuthContext } from 'Context/UserAuthProvider/UserAuthProvider';
import  { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const LogOutFunction = () => {
      // CONTEXT FOR LOGIN
  let { setUserIsLoginToken } = useContext(UserAuthContext);
  const navigate = useNavigate();
  const logOut = () => {
    // HERE I REMOVE TOKEN FROM LOCALSTROGE IF USER LOGOUT
    localStorage.removeItem("token");
    setUserIsLoginToken(null);
    navigate("/");
    toast.success("تم تسجيل الخروج بنجاح.");
  };

  return logOut;
}

export default LogOutFunction