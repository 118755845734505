import CustomModal from "Components/CustomModal/CustomModal";
import React, { useState } from "react";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import "./Auth.css";
import ForgotPassword from "./ForgotPassword/ForgotPassword";

const FormAuth = ({ showModalForm, hideModalForm }) => {
  const [isLogin, setIsLogin] = useState(false);
  const buttonLogin = () => {
    setIsLogin(!isLogin);
  };




  // MODAL FOROGT PASSWORD
  const [showModalPassword, setShowModalPassword] = useState(false);
  const buttonShowForgotPassword = () => {
    hideModalForm();
    setShowModalPassword(true);
  };

  const buttonHideForgotPassword = () => {
    setShowModalPassword(false);
  };

  return (
    <>
      <ForgotPassword
        showModalForgotPassword={showModalPassword}
        hideModalForgotPassword={buttonHideForgotPassword}
      />
  
   
      <CustomModal
        show={showModalForm}
        onHide={hideModalForm}
        title={`${!isLogin ? "تسجيل الدخول" : "إنشاء حساب"}`}
        newClass={`form-auth form-modal-width`}
      >
        {!isLogin ? (
          <>
            <LoginForm
              buttonLogin={buttonLogin}
              hideModalForm={hideModalForm}
              buttonShowForgotPassword={buttonShowForgotPassword}
            />
          </>
        ) : (
          <>
            <RegisterForm
              buttonLogin={buttonLogin}
              hideModalForm={hideModalForm}
            />
          </>
        )}
      </CustomModal>
    </>
  );
};

export default FormAuth;
