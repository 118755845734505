import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import BreadcrumbsPage from "Components/Ui/BreadcrumbsPage/BreadcrumbsPage";
import HeaderDetailsPageBlog from "./HeaderDetailsPageBlog";
import SliderBlogsCards from "./SliderBlogsCards";

const DetailsPageBlogs = () => {
  return (
    <>
      <HelmetInfo titlePage={"تفاصيل المدونة"} />
      <header>
        <BreadcrumbsPage
          newClassBread={"bread-crumbs"}
          titleTwoBread={"المدونات"}
          routeTitleTwoBread={"/blogsPage"}
          textBreadActive={"تفاصيل المدونة"}
          isTruePageRoute={true}
        />
      </header>
      <main>
        <HeaderDetailsPageBlog />
        <SliderBlogsCards />
      </main>
    </>
  );
};

export default DetailsPageBlogs;
