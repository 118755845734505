import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import PaginationPage from "Components/Pagination/Pagination";
import CardTradingRecommendation from "Components/Ui/CardTradingRecommendation/CardTradingRecommendation";
import { useContext, useState } from "react";
import ModalDetailsRecommend from "./ModalDetailsRecommend";
import ErrorFetch from "Components/Ui/LoaderErrorFetch/ErrorFetch/ErrorFetch";
import LoaderFetch from "Components/Ui/LoaderErrorFetch/LoaderFetch/LoaderFetch";
import useFetchRecommendations from "./GetAllRecommendations/GetAllRecommendations";
import usePagination from "Components/Pagination/usePagination";
import { formatDateTimeRecommend } from "./GetAllRecommendations/FormateDateTimeRecommend";
import { toast } from "react-toastify";
import { UserAuthContext } from "Context/UserAuthProvider/UserAuthProvider";
import NotFoundDataPage from "Components/Ui/NotFoundDataPage/NotFoundDataPage";

const RecommendationsCards = () => {
  // CONTEXT FOR LOGIN
  let { userIsLoginToken } = useContext(UserAuthContext);
  // DETAILS MODAL
  const [showModal, setShowModal] = useState(false); // SHOW MODAL
  const [selectedCardId, setSelectedCardId] = useState(null); // GET ID CARD ONCLIK TO SHOW detAILS IN MODAL

  const showModalDetails = (cardId) => {
    // CHECK IF USER IS LOGGED IN
    if (!userIsLoginToken) {
      // DISPLAY TOAST ERROR MESSAGE
      toast.error("سجل الدخول لرؤية التفاصيل.");
      return;
    } else {
      // SHOW MODAL AND SHOW DETAILS CARD FROM ID
      setSelectedCardId(cardId);
      setShowModal(true);
    }
  };

  const hideModalDetails = () => {
    // HIDE MODAL
    setShowModal(false);
  };

  // FETCHH DATA NEWS CARDS
  const { data, error, isLoading } = useFetchRecommendations(
    "allRecommendationsCards"
  );
  // PAGINATION CARDS
  const { setCurrentPage, totalPages, currentPageData } = usePagination(
    data?.data.data, // THIS DATA OF ARRAY
    6, // THIS HOW MANY CARDS TO SHOW IN PAGE I'M ADD "12" CARDS
    0 // THIS "0" FOR START FROM NUMBER CARD
  );

  // Pagination
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // ERROR
  if (error) return <ErrorFetch />;
  // LOADING
  if (isLoading) return <LoaderFetch />;

  return (
    <>
      <ModalDetailsRecommend
        showModalRecommend={showModal}
        hideModalRecommend={hideModalDetails}
        selectedCardId={selectedCardId}
      />
      <div className="all-recommend-cards padding-top-30">
        {/* ================ START CONTAINER =============== */}
        <ContainerMedia>
          {/* ============== START MAIN CARDS RECOMMEND ============ */}
          <div className="main-cards-recommend" data-aos="fade-up">
            {/* ================ START ROW =================== */}
            <div className="row g-3">
              {currentPageData.length > 0 ? (
                <>
                  {" "}
                  {currentPageData.map((item) => {
                    return (
                      <>
                        {/* =============== START COL =============== */}
                        <div
                          onClick={() => showModalDetails(item.id)}
                          key={item.id}
                          className="col-12 col-md-6"
                        >
                          <CardTradingRecommendation
                            imageSrc={item.image}
                            isTrueEye={true}
                            title={item.title}
                            times={formatDateTimeRecommend(item.created_at)}
                            valueopening={Number(item.opening)}
                            valueClosing={Number(item.closing)}
                            valueTarget={Number(item.target)}
                            isTrueCardContent={false}
                            textContentCard={false}
                          />
                        </div>
                        {/* =============== END COL =============== */}
                      </>
                    );
                  })}
                </>
              ) : (
                <NotFoundDataPage />
              )}
            </div>
            {/* ================ END ROW =================== */}
            {currentPageData.length > 0 ? (
              <>
                {/* ============ START PAGINATION ============= */}
                <PaginationPage
                  itemCount={totalPages}
                  onPageChange={handlePageChange}
                />
                {/* ============ END PAGINATION ============= */}
              </>
            ) : (
              ""
            )}
          </div>
          {/* ============== END MAIN CARDS RECOMMEND ============ */}
        </ContainerMedia>
        {/* ================ END CONTAINER =============== */}
      </div>
    </>
  );
};

export default RecommendationsCards;
