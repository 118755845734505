import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import "./NewsCards.css";
import SectionTitle from "Components/Ui/SectionTitle/SectionTitle";

import { Link } from "react-router-dom";
import SliderCardNewsContent from "./SliderCardNewsContent";

const NewsCards = () => {
  return (
    <div className="news-cards-info padding-top">
      {/* ============== START CONTAINER ================ */}
      <ContainerMedia>
        <SectionTitle title={"آخر أخبار السوق"} />
        {/* ============== START ALL CARDS NEW INFO ============== */}
        <div className="all-cards-news-info" data-aos="fade-up">
          <SliderCardNewsContent />
          <Link
            to="/newsPage"
            className="btn-main btn-main-full btn-transparent mt-4 m-auto"
          >
            الجميع
          </Link>
        </div>
        {/* ============== END ALL CARDS NEW INFO ============== */}
      </ContainerMedia>
      {/* ============== END CONTAINER ================ */}
    </div>
  );
};

export default NewsCards;
