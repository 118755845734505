import { useParams } from "react-router-dom";
import DetailsPageContent from "Components/Ui/DetailsPageContent/DetailsPageContent";
import ErrorFetch from "Components/Ui/LoaderErrorFetch/ErrorFetch/ErrorFetch";
import LoaderFetch from "Components/Ui/LoaderErrorFetch/LoaderFetch/LoaderFetch";
import useFetch from "../GetCardsApi/GetCardsNewsApi";
import { FormatDateTime } from "Components/Ui/FormatDateTime/FormatDateTime";
import DOMPurify from "dompurify";
import UseImageLoading from "Components/Ui/UseImageLoading/UseImageLoading";
import NotFoundDataPage from "Components/Ui/NotFoundDataPage/NotFoundDataPage";
const HeaderDetailsPage = () => {
  const { id } = useParams();
  // FETCHH DATA NEWS CARDS
  const { data, error, isLoading } = useFetch("allNewsCards");

  const newsItem = data?.data.data.find((item) => item.id === parseInt(id));
  // LOADING WHEN GET IMAGE
  const { handleImageLoad } = UseImageLoading(newsItem, isLoading);
  if (error)
    return (
      <>
        <ErrorFetch />
      </>
    );
  // LOADER WHEN GET ALL CARDS
  if (isLoading) {
    return (
      <>
        <LoaderFetch />
      </>
    );
  }

  if (!newsItem) {
    return <NotFoundDataPage />;
  }

  const ContentInfo = DOMPurify.sanitize(newsItem.content);

  return (
    <>
      <DetailsPageContent
        image={newsItem.image}
        onImageLoad={() => handleImageLoad(newsItem.id)}
        BadgeTitle={newsItem.category.title}
        titleInfo={newsItem.title}
        timeDay={FormatDateTime(newsItem.created_at)}
        isTrueUserInfo={false}
        imageUser={false}
        nameUser={false}
        timeAdd={false}
        isRenderHtml={true}
        renderTextContent={
          <div dangerouslySetInnerHTML={{ __html: ContentInfo }} />
        }
        isTrueTextContentDetails={false}
        textInfoDetails={false}
      />
    </>
  );
};

export default HeaderDetailsPage;
