import React from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import CardBoxBlog from "Components/Ui/CardBoxBlog/CardBoxBlog";
import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import SectionTitle from "Components/Ui/SectionTitle/SectionTitle";
import UseImageLoading from "Components/Ui/UseImageLoading/UseImageLoading";
import useFetchBlogs from "../GetCardsBlogs/GetCardsBlogs";
import ErrorFetch from "Components/Ui/LoaderErrorFetch/ErrorFetch/ErrorFetch";
import LoaderFetch from "Components/Ui/LoaderErrorFetch/LoaderFetch/LoaderFetch";
import { formatDistanceToNow } from "date-fns";
import { ar } from "date-fns/locale";
import NotFoundDataPage from "Components/Ui/NotFoundDataPage/NotFoundDataPage";
const SliderBlogsCards = () => {
  // NAVIGATE TO THE PAGE DETAILS USING ID OF CARD
  const navigate = useNavigate();
  // FETCHH DATA NEWS CARDS
  const { data, error, isLoading } = useFetchBlogs("allBlogsCards");

  // LOADING WHEN GET IMAGE
  const { handleImageLoad } = UseImageLoading(data, isLoading);

  // ERROR
  if (error) return <ErrorFetch />;
  // LOADING
  if (isLoading) return <LoaderFetch />;
  return (
    <div className="slider-blog-cards padding-top pb-2" data-aos="fade-up">
      <ContainerMedia>
        <SectionTitle title={"مدونات مشابهه"} />
        <Swiper
          slidesPerView={3}
          spaceBetween={15}
          loop={true}
          pagination={false}
          className="mySwiper"
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          modules={[Autoplay]}
          breakpoints={{
            0: {
              slidesPerView: 1
            },
            480: {
              slidesPerView: 2
            },
            768: {
              slidesPerView: 3
            }
          }}
        >
          {data?.data.data.length > 0 ? (
            data?.data.data.map((item) => {
              return (
                <>
                  <SwiperSlide
                    onClick={() => {
                      navigate(`/detailsPageBlog/${item.id}`);
                    }}
                    key={item.id}
                  >
                    {" "}
                    <CardBoxBlog
                      image={item.image}
                      onImageLoad={() => handleImageLoad(item.id)}
                      badgeText={item.category.title}
                      title={item.title}
                      imagePerson={item.admin.image}
                      nameUser={item.admin.name}
                      timeAdd={formatDistanceToNow(
                        new Date(item.admin.created_at),
                        {
                          addSuffix: true,
                          locale: ar
                        }
                      )}
                      isTrueTextContent={false}
                      textContent={false}
                    />
                  </SwiperSlide>
                </>
              );
            })
          ) : (
            <NotFoundDataPage />
          )}
        </Swiper>
      </ContainerMedia>
    </div>
  );
};

export default SliderBlogsCards;
