import TextIcon from "Assets/images/MainCardIcons/TextIcon";
import TradeIcon from "Assets/images/MainCardIcons/TradeIcon";
import UsersIcon1 from "Assets/images/MainCardIcons/UsersIcon1";
import WalletIcon from "Assets/images/MainCardIcons/WalletIcon";

export const DataCard = [
    {id:0, icon:<UsersIcon1/>,title:"فريق خبراء في التداول",text:"نقدم لك فريقًا من الخبراء الماليين ذوي الخبرة الواسعة في سوق التداول لمساعدتك في إدارة محفظتك بنجاح."},
    {id:1, icon:<TextIcon/>,title:"تحليلات دقيقة",text:"نقدم لك فريقًا من الخبراء الماليين ذوي الخبرة الواسعة في سوق التداول لمساعدتك في إدارة محفظتك بنجاح."},
    {id:2, icon:<WalletIcon/>,title:"إدارة محترفة لأموالك",text:"نقدم لك فريقًا من الخبراء الماليين ذوي الخبرة الواسعة في سوق التداول لمساعدتك في إدارة محفظتك بنجاح."},
    {id:3, icon:<TradeIcon/>,title:"سجل حافل بالنجاح",text:"نقدم لك فريقًا من الخبراء الماليين ذوي الخبرة الواسعة في سوق التداول لمساعدتك في إدارة محفظتك بنجاح."},

]