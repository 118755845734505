import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import CardBoxNews from "Components/Ui/CardBoxNews/CardBoxNews";
import { useNavigate } from "react-router-dom";
import ErrorFetch from "../../../Components/Ui/LoaderErrorFetch/ErrorFetch/ErrorFetch";
import LoaderFetch from "../../../Components/Ui/LoaderErrorFetch/LoaderFetch/LoaderFetch";
import { FormatDateTime } from "../../../Components/Ui/FormatDateTime/FormatDateTime";
import useFetch from "./GetCardsApi/GetCardsNewsApi";
import UseImageLoading from "Components/Ui/UseImageLoading/UseImageLoading";

const HeaderNewsCards = () => {
  // NAVIGATION ROUTE
  const navigate = useNavigate();
  const handleCardClick = (id) => {
    navigate(`/detailsPageNews/${id}`);
  };
  // FETCHH DATA NEWS CARDS
  const { data, error, isLoading } = useFetch("allNewsCards");
  // LOADING WHEN GET IMAGE FROM API
  const {  handleImageLoad } = UseImageLoading(
    data,
    isLoading
  );

  if (error)
    return (
      <>
        <ErrorFetch />
      </>
    );
  // LOADER WHEN GET ALL CARDS
  if (isLoading) {
    return (
      <>
        <LoaderFetch />
      </>
    );
  }

  return (
    <div className="boxs-cards-content padding-top-30">
      <ContainerMedia>
        <div className="all-cards-boxs">
          <div className="row g-3">
            <div className="col-12 col-md-6">
              {data?.data.data.slice(0, 1).map((item) => (
                <div
                  key={item.id}
                  onClick={() => handleCardClick(item.id)}
                  className="h-100"
                  data-aos="fade-left"
                >
                  <CardBoxNews
                    image={item.image}
                    onImageLoad={() => handleImageLoad(item.id)}
                    badgeText={item.category.title}
                    title={item.title}
                    timeDay={FormatDateTime(item.created_at)}
                  />
                </div>
              ))}
            </div>
            <div className="col-12 col-md-6">
              <div className="row g-3">
                {data?.data.data.slice(1, 3).map((item) => (
                  <div
                    onClick={() => handleCardClick(item.id)}
                    className="col-12"
                    key={item.id}
                    data-aos="fade-right"
                  >
                    <CardBoxNews
                      image={item.image}
                      onImageLoad={() => handleImageLoad(item.id)}
                      badgeText={item.category.title}
                      title={item.title}
                      timeDay={FormatDateTime(item.created_at)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </ContainerMedia>
    </div>
  );
};

export default HeaderNewsCards;
