import "./MainCardTrade.css";

const MainCardTrade = ({iconCard,titleCard,textCard}) => {
  return (
    <div className="box-card-trade h-100">
      {/* ========== START ICON CARD ============== */}
      <div className="icon-card">{iconCard}</div>
      {/* ========== END ICON CARD ============== */}
      {/* ========== START INFO CONTENT CARD =========== */}
      <div className="info-content-card">
        <h2 className="title">{titleCard}</h2>
        <p className="text">{textCard}</p>
      </div>
      {/* ========== END INFO CONTENT CARD =========== */}
    </div>
  );
};

export default MainCardTrade;
