import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import "./FinalBanner.css";
import AppsInfo from "Components/Ui/AppsInfo/AppsInfo";
import imageMobile from "../../../../Assets/images/finalBanner/01.png";
import image1 from "../../../../Assets/images/finalBanner/02.svg";
import image2 from "../../../../Assets/images/finalBanner/03.svg";
import image3 from "../../../../Assets/images/finalBanner/04.svg";
import image4 from "../../../../Assets/images/finalBanner/05.svg";
import { useEffect, useState } from "react";

const FinalBanner = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredImage, setIsisHoveredImage] = useState(false);

  useEffect(() => {
    let timer;
    if (isHovered) {
      timer = setTimeout(() => {
        setIsisHoveredImage(true);
      }, 1600);
    } else {
      clearTimeout(timer);
      setIsisHoveredImage(false);
    }
    return () => clearTimeout(timer);
  }, [isHovered]);

  return (
    <div className="final-banner padding-top">
      {/* =============== START CONTAINER ================ */}
      <ContainerMedia>
        {/* ============== START ALL FINAL BANNER ============== */}
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className="all-final-banner"
        >
          {/* ================ START CONTENT TOP BANNER =============== */}
          <div className="content-top-banner" data-aos="fade-up">
            <h2 className="title">إنتظروا الإطلاق قريبا</h2>
            <p className="text">
              إحصل على آخر الأخبار المالية والتحليلات السوقية وفرص تداول حصرية
              وتوصيات دقيقة
            </p>
            <AppsInfo />
          </div>
          {/* ================ END CONTENT TOP BANNER =============== */}

          {/* ================ START CONTENT BOTTOM BANNER ============ */}
          <div className="content-banner-bottom">
            {/* ================ START IMAGE BANNER MOBILE =============== */}
            <div className="image-banner-mobile">
              <img
                src={imageMobile}
                alt="imageMobile"
                className="w-100  object-fit-cover"
              />
            </div>
            {/* ================ END IMAGE BANNER MOBILE =============== */}
            {/* ================ START IMAGE CONTENT ONE =============== */}
            <div
              className={`image-content--one image--1 ${
                isHoveredImage ? "hoverd" : ""
              }`}
            >
              <img
                src={image1}
                alt="img"
                className="w-100 h-100 object-fit-cover"
              />
            </div>
            {/* ================ END IMAGE CONTENT ONE =============== */}
            {/* ================ START IMAGE CONTENT ONE =============== */}
            <div    className={`image-content--one image--2 ${
                isHoveredImage ? "hoverd-2" : ""
              }`}>
              <img
                src={image2}
                alt="img"
                className="w-100 h-100 object-fit-cover"
              />
            </div>
            {/* ================ END IMAGE CONTENT ONE =============== */}
            {/* ================ START IMAGE CONTENT ONE =============== */}
            <div className={`image-content--one image--3 ${
                isHoveredImage ? "hoverd-3" : ""
              }`}>
              <img
                src={image3}
                alt="img"
                className="w-100 h-100 object-fit-cover"
              />
            </div>
            {/* ================ END IMAGE CONTENT ONE =============== */}
            {/* ================ START IMAGE CONTENT ONE =============== */}
            <div className={`image-content--one image--4 ${
                isHoveredImage ? "hoverd-4" : ""
              }`}>
              <img
                src={image4}
                alt="img"
                className="w-100 h-100 object-fit-cover"
              />
            </div>
            {/* ================ END IMAGE CONTENT ONE =============== */}
          </div>
          {/* ================ END CONTENT BOTTOM BANNER ============ */}
        </div>
        {/* ============== END ALL FINAL BANNER ============== */}
      </ContainerMedia>
      {/* =============== END CONTAINER ================ */}
    </div>
  );
};

export default FinalBanner;
