import React from 'react'

const LogOutIcon = () => {
  return (
    <>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5332 12.1832L16.6665 10.0498L14.5332 7.9165" stroke="#FF2B2B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.1333 10.0498H16.6083" stroke="#FF2B2B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.79997 16.6668C6.11663 16.6668 3.1333 14.1668 3.1333 10.0002C3.1333 5.8335 6.11663 3.3335 9.79997 3.3335" stroke="#FF2B2B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
     
    </>
  )
}

export default LogOutIcon
