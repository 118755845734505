import React, { useState } from "react";
import "./CardBoxNews.css";
import Skeleton from "@mui/material/Skeleton";
const CardBoxNews = ({
  image,
  badgeText,
  title,
  timeDay,
  onImageLoad
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  // FUNCTION WHEN LOAD IMAGE
  const handleImageLoad = () => {
    setImageLoaded(true);
    if (onImageLoad) {
      onImageLoad();
    }
  };

  return (
    <div className="card-box-news-one card-box-style h-100 cursor-pointer-event">
      {/* =============== START MAIN IMAGE CARD ================ */}
      <div className="main-image-header h-100 position-relative">
        {!imageLoaded && (
          <div className="loader text-white">
            {" "}
            <Skeleton   sx={{ bgcolor: 'grey.900' }} variant="rectangular" className="new-skelton" />
          </div>
        )}
        <div className={`image-card-news h-100`}>
          {/* Render the image */}
          <img
            src={image}
            className="w-100 h-100 object-fit-cover"
            alt="imageNews"
            onLoad={handleImageLoad}
          />
        </div>
      </div>
      {/* =============== END MAIN IMAGE CARD ================ */}

      {/* ============= START CONTENT CARD NEWS =========== */}
      <div className="content-card-news">
        <h2 className="badge-head">{badgeText}</h2>
        <h2 className="title">{title}</h2>
      </div>
      {/* ============= END CONTENT CARD NEWS =========== */}
      <p className="time-day">
         <span className="titme-info">{timeDay}</span>
      </p>
    </div>
  );
};

export default CardBoxNews;
