import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import BASE_URL from "config";

const UseFetchTerms = (queryKey) => {
  const getAllTerms = () => axios.get(`${BASE_URL}/terms`);
  return useQuery({
    queryKey,
    queryFn: getAllTerms
  });
};

export default UseFetchTerms;
