import React from "react";
import { Helmet } from "react-helmet-async";

const HelmetInfo = ({ titlePage }) => {
  return (
    <Helmet>
      <title>{titlePage}</title>
      
 
      {/* description */}
      <meta
        name="description"
        content="INJAAZ ACADEMY Discription Content info INJAAZ ACADEMY  Discription Content info INJAAZ ACADEMY Discription Content info"
      />

      {/* keywords */}
      <meta
        name="keywords"
        content="INJAAZ ACADEMY  KeyWords INJAAZ ACADEMY  KeyWords INJAAZ ACADEMY  KeyWords"
      />
      {/*  <!-- #author --> */}
      <meta name="author" content="INJAAZ ACADEMY Website" />
      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content="INJAAZ ACADEMY Link Url" />
      <meta property="og:type" content="INJAAZ ACADEMY website" />
      <meta property="og:title" content="INJAAZ ACADEMY Services" />
      <meta
        property="og:description"
        content="INJAAZ ACADEMY A concise description of your page content"
      />
      <meta property="og:image" content="INJAAZ ACADEMY Image" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:domain"
        content="INJAAZ ACADEMY Domain Link Url"
      />
      <meta property="twitter:url" content="INJAAZ ACADEMY Url" />
      <meta name="twitter:title" content="INJAAZ ACADEMY" />
      <meta
        name="twitter:description"
        content="INJAAZ ACADEMY description of your page content"
      />
      <meta name="twitter:image" content="INJAAZ ACADEMY  Image" />

      {/* LINK URL WEBSITE INJAAZ ACADEMY */}
      <link rel="canonical" href="https://www.example.com/your-page" />
      <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
      <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
    </Helmet>
  );
};

export default HelmetInfo;
