import React from 'react'

const DateIcon = () => {
  return (
    <>
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.66666 4.79297C6.63651 4.79297 6.60523 4.78068 6.57958 4.75504C6.55394 4.7294 6.54166 4.69812 6.54166 4.66797V2.16797C6.54166 2.13782 6.55394 2.10654 6.57958 2.0809C6.60523 2.05525 6.63651 2.04297 6.66666 2.04297C6.6968 2.04297 6.72808 2.05525 6.75373 2.0809C6.77937 2.10654 6.79166 2.13782 6.79166 2.16797V4.66797C6.79166 4.69812 6.77937 4.7294 6.75373 4.75504C6.72808 4.78068 6.6968 4.79297 6.66666 4.79297Z" fill="#B4ABBB" stroke="#B4ABBB"/>
    <path d="M13.3333 4.79297C13.3032 4.79297 13.2719 4.78068 13.2463 4.75504C13.2206 4.7294 13.2083 4.69812 13.2083 4.66797V2.16797C13.2083 2.13782 13.2206 2.10654 13.2463 2.0809C13.2719 2.05525 13.3032 2.04297 13.3333 2.04297C13.3635 2.04297 13.3948 2.05525 13.4204 2.0809C13.4461 2.10654 13.4583 2.13782 13.4583 2.16797V4.66797C13.4583 4.69812 13.4461 4.7294 13.4204 4.75504C13.3948 4.78068 13.3635 4.79297 13.3333 4.79297Z" fill="#B4ABBB" stroke="#B4ABBB"/>
    <path d="M17.0833 8.69922H2.91666C2.57499 8.69922 2.29166 8.41589 2.29166 8.07422C2.29166 7.73255 2.57499 7.44922 2.91666 7.44922H17.0833C17.425 7.44922 17.7083 7.73255 17.7083 8.07422C17.7083 8.41589 17.425 8.69922 17.0833 8.69922Z" fill="#B4ABBB"/>
    <path d="M13.3333 19.4596H6.66667C3.625 19.4596 1.875 17.7096 1.875 14.668V7.58464C1.875 4.54297 3.625 2.79297 6.66667 2.79297H13.3333C16.375 2.79297 18.125 4.54297 18.125 7.58464V14.668C18.125 17.7096 16.375 19.4596 13.3333 19.4596ZM6.66667 4.04297C4.28333 4.04297 3.125 5.2013 3.125 7.58464V14.668C3.125 17.0513 4.28333 18.2096 6.66667 18.2096H13.3333C15.7167 18.2096 16.875 17.0513 16.875 14.668V7.58464C16.875 5.2013 15.7167 4.04297 13.3333 4.04297H6.66667Z" fill="#B4ABBB"/>
    <path d="M7.08333 12.5846C6.975 12.5846 6.86666 12.5597 6.76666 12.518C6.66666 12.4763 6.57501 12.418 6.49167 12.343C6.41667 12.2596 6.35832 12.168 6.31666 12.068C6.27499 11.968 6.25 11.8596 6.25 11.7513C6.25 11.5346 6.34167 11.318 6.49167 11.1597C6.57501 11.0847 6.66666 11.0263 6.76666 10.9846C6.91666 10.918 7.08334 10.9013 7.25001 10.9346C7.30001 10.943 7.35 10.9596 7.4 10.9846C7.45 11.0013 7.5 11.0263 7.55 11.0597C7.59166 11.093 7.63333 11.1263 7.67499 11.1597C7.70833 11.2013 7.74999 11.243 7.77499 11.2846C7.80832 11.3346 7.83334 11.3846 7.85001 11.4346C7.87501 11.4846 7.89168 11.5346 7.90001 11.5846C7.90834 11.643 7.91667 11.693 7.91667 11.7513C7.91667 11.968 7.82499 12.1846 7.67499 12.343C7.51666 12.493 7.3 12.5846 7.08333 12.5846Z" fill="#B4ABBB"/>
    <path d="M9.99999 12.5837C9.78332 12.5837 9.56666 12.492 9.40833 12.342C9.375 12.3004 9.34167 12.2587 9.30833 12.217C9.275 12.167 9.24998 12.1171 9.23331 12.0671C9.20831 12.0171 9.19165 11.9671 9.18331 11.9171C9.17498 11.8587 9.16666 11.8087 9.16666 11.7504C9.16666 11.642 9.19165 11.5337 9.23331 11.4337C9.27498 11.3337 9.33333 11.2421 9.40833 11.1587C9.64166 10.9254 10.0167 10.8504 10.3167 10.9837C10.425 11.0254 10.5083 11.0837 10.5916 11.1587C10.7416 11.3171 10.8333 11.5337 10.8333 11.7504C10.8333 11.8087 10.825 11.8587 10.8167 11.9171C10.8083 11.9671 10.7917 12.0171 10.7667 12.0671C10.75 12.1171 10.725 12.167 10.6916 12.217C10.6583 12.2587 10.625 12.3004 10.5916 12.342C10.5083 12.417 10.425 12.4754 10.3167 12.5171C10.2167 12.5587 10.1083 12.5837 9.99999 12.5837Z" fill="#B4ABBB"/>
    <path d="M7.08333 15.5017C6.975 15.5017 6.86666 15.4767 6.76666 15.435C6.66666 15.3934 6.57501 15.335 6.49167 15.26C6.41667 15.1767 6.35832 15.0934 6.31666 14.985C6.27499 14.885 6.25 14.7767 6.25 14.6684C6.25 14.4517 6.34167 14.235 6.49167 14.0767C6.57501 14.0017 6.66666 13.9433 6.76666 13.9017C7.075 13.7683 7.44166 13.8434 7.67499 14.0767C7.70833 14.1184 7.74999 14.16 7.77499 14.2017C7.80832 14.2517 7.83334 14.3017 7.85001 14.3517C7.87501 14.4017 7.89168 14.4517 7.90001 14.51C7.90834 14.56 7.91667 14.6184 7.91667 14.6684C7.91667 14.885 7.82499 15.1017 7.67499 15.26C7.51666 15.41 7.3 15.5017 7.08333 15.5017Z" fill="#B4ABBB"/>
    </svg>
     
    </>
  )
}

export default DateIcon
